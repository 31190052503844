import React, { useEffect, useState } from "react";
// import "./Style.css";
import axios from "axios";
import { toast } from "react-toastify";
import { APIUrlOne, APIUrlThree, APIUrlToken, GetUserId } from "../../Utils/Utils";
import Layout from "../../Components/Layout/Layout";
import AiLeadsAction from "../../Components/AiLeads/AiLeadsButton/AiLeadsAction";
import Loader from "../../Components/Loader/Loader";
import Organizations from "../../Components/Favorites/FavoritesOrganization/Organizations";
import Prospects from "../../Components/Favorites/FavoritesProspects/Prospects";
import ProspectRequestModal from "../../Components/RequestUpdateModals/ProspectRequestModal";
import LeadsProspectModal from "../../Components/RequestUpdateModals/LeadsProspectModal";

const Favorites = () => {
    const [archiveRecord, setArchiveRecord] = React.useState(false)
    const [trackRecord, setTrackRecord] = React.useState(false)
    const [requestUpdateRecord, setRequestUpdateRecord] = useState(false);
    const [disableRequestUpdate, setDisableRequestUpdate] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [isDecisionMakerExcel, setIsDecisionMakerExcel] = useState(false);
    const [isSalesForceTrigger, setIsSalesForceTrigger] = useState(false);
    const [selectedLeads, setSelectedLeads] = useState([])
    const [selectedProspects, setSelectedProspects] = useState([])
    const [selectedLeadsModal, setSelectedLeadsModal] = useState(false)
    const [selectedProspectsModal, setSelectedProspectsModal] = useState(false)
    const [showSalesForce, setShowSalesForce] = useState(false)

    useEffect(() => {
        if ((selectedLeads?.length + selectedProspects?.length) < 1 && requestUpdateRecord == true) {
            setDisableRequestUpdate(false)
            setRequestUpdateRecord(false)
            toast.error("Please select record to request update")
            return
        } else if ((selectedLeads?.length + selectedProspects?.length) === 1 && requestUpdateRecord == true) {
            if (selectedLeads?.length === 1) {
                setSelectedLeadsModal(true)
            } else {
                setSelectedProspectsModal(true)
            }
            setRequestUpdateRecord(false)
            setDisableRequestUpdate(false)
        } else if ((selectedLeads?.length + selectedProspects?.length) > 1) {
            setDisableRequestUpdate(true)
            setRequestUpdateRecord(false)
        } else {
            setDisableRequestUpdate(false)
        }
    }, [selectedLeads, selectedProspects, requestUpdateRecord])
    return (
        <>
            <Layout>
                {loading ? <Loader /> : null}
                <div className="child-section-of-everypage  ">
                    <div style={{ display: "flex" }}>
                        <div>
                            <AiLeadsAction
                                setIsDecisionMakerExcel={setIsDecisionMakerExcel}
                                setIsSalesForceTrigger={setIsSalesForceTrigger}
                                isSalesForceTrigger={isSalesForceTrigger}
                                archiveRecord={archiveRecord}
                                setArchiveRecord={setArchiveRecord}
                                setTrackRecord={setTrackRecord}
                                disableRequestUpdate={disableRequestUpdate}
                                setRequestUpdateRecord={setRequestUpdateRecord}
                                showSalesForce={showSalesForce}
                            /></div>
                    </div>

                    {/* Favorites Organization table  */}
                    <div style={{ display: "flex", zIndex: "999", padding: "50px 0px 10px 0px" }}> <p style={{ fontSize: "16px", fontWeight: "700" }}>Organizations</p></div>
                    <Organizations
                        setLoading={setLoading}
                        isSalesForceTrigger={isSalesForceTrigger}
                        setIsSalesForceTrigger={setIsSalesForceTrigger}
                        isDecisionMakerExcel={isDecisionMakerExcel}
                        setIsDecisionMakerExcel={setIsDecisionMakerExcel}
                        archiveRecord={archiveRecord}
                        setArchiveRecord={setArchiveRecord}
                        trackRecord={trackRecord} setTrackRecord={setTrackRecord}
                        selectedOuterRows={selectedLeads}
                        setSelectedOuterRows={setSelectedLeads}
                    />
                    {/* Favorites Prospects table  */}
                    <div style={{ display: "flex", zIndex: "999", padding: "20px 0px 10px 0px" }}> <p style={{ fontSize: "16px", fontWeight: "700" }}>Prospects</p></div>
                    <Prospects
                     setIsSalesForceTrigger={setIsSalesForceTrigger}
                     isSalesForceTrigger={isSalesForceTrigger}
                        setLoading={setLoading}
                        isDecisionMakerExcel={isDecisionMakerExcel}
                        setIsDecisionMakerExcel={setIsDecisionMakerExcel}
                        archiveRecord={archiveRecord}
                        setArchiveRecord={setArchiveRecord}
                        trackRecord={trackRecord} setTrackRecord={setTrackRecord}
                        selectedOuterRows={selectedProspects}
                        setSelectedOuterRows={setSelectedProspects}
                        setShowSalesForce={setShowSalesForce}
                    />
                </div>
            </Layout>
            <ProspectRequestModal requestUpdateRecord={selectedProspectsModal} setRequestUpdateRecord={setSelectedProspectsModal} profileData={selectedProspects[0]} peopleId={selectedProspects?.[0]?.leads_id} />

            <LeadsProspectModal requestUpdateRecord={selectedLeadsModal} setRequestUpdateRecord={setSelectedLeadsModal} profileData={selectedLeads[0]} peopleId={selectedLeads?.[0]?.org_id} />
        </>
    );
};
export default Favorites;