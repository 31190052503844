import * as React from "react";
import "./Accordian.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import faq1 from "../../../Assets/faq1.png"
export default function Faqscreen() {
  return (
    <>
      <div>
        <div className="Faqscreen-main-heading">
          <h3>About JOI</h3>
        </div>
        <div>
          <div className="FaqscreenAccordion-outter">
            <Accordion className="defaultExpandedAccordion" defaultExpanded>
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>What is JOI?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <p>
                  The JobsOhio Intelligence tool (JOI) enables the mission of JobsOhio by 
                  leveraging the power of artificial intelligence (AI) to identify organizations 
                  poised for growth and expansion, easily identify decision makers, 
                  highlight simple paths to connect with them, and provide competitive 
                  intelligence on economic development opportunities.
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>Who are the sponsors of JOI?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <p>
                  <b>Executive Sponsors:</b> J.P. Nauseef, CEO & Andrew Deye, Chief Financial Officer and Vice President, Strategy<br></br>
                  <b>Primary Sponsor:</b> Payal Thakur, Senior Director – Enterprise Analytics
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>What changes with the rollout of JOI?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                <p>JOI is being designed to:</p>
                <ul className="ol-faq">
                  <li>Move employees from manual processes to an automated, AI-driven solution</li>
                  <li>Consolidate existing apps, tools & data into one single user interface</li>
                  <li>Leverage best-in-class data to ensure the people & company information we are sourcing has breadth and consistency to generate accurate and timely results</li>
                </ul>
                <p><strong>JOI 2.0</strong> is targeted to be released in November 2024 and April 2025 and will offer new features and enhancements.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>Why JOI and why now?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                <p>JobsOhio exists to empower world-class corporations, entrepreneurs, and talented individuals to build their businesses and careers in Ohio.</p>
                <ul className="ol-faq">
                  <li>CEO, J.P. Nauseef’s vision was to empower JobsOhio employees with intelligence that allows us to stay ahead of the competition in terms of leads and pathways to decision makers. Hence, we built an in-house tool, leveraging AI, to give JobsOhio a strategic advantage.</li>
                  <li>84% of business executives believe they need to use AI to achieve their growth objectives.</li>
                  <li>3 out of 4 C-suite executives believe if they don’t scale artificial intelligence in the next five years, they risk going out of business entirely.</li>
                  <li>AI adoption is growing faster than ever and has an ability to process vast amounts of data, recognize patterns, and make decisions or predictions with speed and accuracy far beyond human capabilities.</li>
                </ul>
                <p><strong>Proprietary only to JobsOhio, JOI</strong> will streamline processes and give us a strategic advantage to ensure we stay ahead of the competition.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>What are the benefits of using JOI?</h4>
              </AccordionSummary>
              <AccordionDetails>
              <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <ul className="ol-faq">
                    <li>Improves the user experience by streamlining apps, tools & data into one simple tool</li>
                    <li>Eliminates manual and repetitive tasks, saving time to focus on more strategic & creative solutions</li>
                    <li>Leverages enriched data and provides daily updates from many data sources being used today</li>
                    <li>Reduces the need to use third-party vendors for lead generation</li>
                    <li>Highlights connection paths fron JobsOhio employees to key decision makers for essential relationship building opportunities</li>
                    <li>Generates leads in targeted industries/sectors</li>
                    <li>Pushes relevant information to Salesforce (JOSF) seamlessly</li>
                    </ul>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>What does success look like if JOI is adopted?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <ul className="ol-faq">
                    <li>Awareness of the what, why, and what’s in it for me from the pilot participants</li>
                    <li>Desire to participate in JOI (requesting access, logging in, pushing to Salesforce, providing feedback)</li>
                    <li>Knowledge of how to use the tool & features (leveraging job aids, videos, etc.)</li>
                    <li>Active use of the tool & features (logins, page visits, active time spent in tool.)</li>
                    <li>Increase in leads to projects to close</li>
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
            <div className="Faqscreen-sub-heading">
              <h3>Technical/Security</h3>
            </div>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>On which platform is JOI stored?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  JOI is proprietary only to JobsOhio and is hosted on the JobsOhio server. It is a web-based tool.
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>What is a data policy and is there one currently?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <p>
                  A data policy describes how a business handles personal data. 
                  It will highlight how the data is secure and the steps taken to keep the information private. 
                  The data policy for JobsOhio will be provided by Legal.
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6-content"
                id="panel6-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>What data sources are used in the pilot version of JOI?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <table>
                    <tbody>
                      <tr>
                        <td className="text-glossary"><strong>INTERNAL APPLICATIONS</strong></td>
                        <td className="text-glossary">
                          <ul className="ol-faq">
                            <li>Pardot</li>
                            <li>Salesforce</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-glossary"><strong>DATA VENDORS</strong></td>
                        <td className="text-glossary">
                          <ul className="ol-faq">
                            <li>Crunchbase</li>
                            <li>Gazelle.AI</li>
                            <li>ZoomInfo</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-glossary"><strong>DATA QUALITY ENRICHMENT</strong></td>
                        <td className="text-glossary">
                          <ul className="ol-faq">
                            <li>Clearbit</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-glossary"><strong>PUBLICLY AVAILABLE DATA</strong></td>
                        <td className="text-glossary">
                          <ul className="ol-faq">
                            <li>Extraction & Annotation via RPA</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>How will JOI’s data be used?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <ul className="ol-faq">
                    <li>Empowering growth through AI-driven, qualified lead generation</li>
                    <li>Extending reach by identifying prospects who are potential decision-makers within those organizations</li>
                    <li>Forging connections by identifying pathways to decision-makers</li>
                  </ul>
                  <img className="faqimg" src={faq1} alt="" />
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>Is this information secure and protected?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <p>
                  Yes, there are multi-factor authentication (MFA) methods to keep data secure and protected via applications, 
                  such as SOPHOS and OKTA.
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>
                  Can JOI access personal information such as email content /
                  private messages?
                </h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <p>
                    JOI does not have access to personal information such as
                    this; as it is only collecting metadata (which includes the
                    connection path, not the content).
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
            <div className="Faqscreen-sub-heading">
              <h3>JOI Capabilities</h3>
            </div>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel8-content"
                id="panel8-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>What is a JOI score?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <p>The JOI-Score is a measure of suitability, ranging from 0 to 100, that provides users with information on whether a company is positioned for growth. This score is determined by triggers identified by the BD NA team. A higher JOI-Score indicates that the company is prepared to expand and fulfills the criteria for growth.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel9-content"
                id="panel9-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>What is a JOI-Strength score?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <p>The JOI-Strength Score is a quantifiable measure developed to assess the strength of connection between two individuals, leveraging a sophisticated AI model. This model intricately analyzes various dimensions of interpersonal connections and provides a strength score between 0-100. The higher the score, the stronger the connection.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel10-content"
                id="panel10-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>What is a New AI Lead Profile and how do I create one?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <p>An AI Lead Profile is a saved search that allows you to quickly find leads using your predetermined filters.</p>
                  <p>To create a new AI Lead Profile:</p>
                  <ul className="ol-faq">
                    <li>View <a href="https://app.box.com/shared/static/ogssxcjea5sl1qviz03p96yie0p6czui.pdf">AI Lead Profile Job Aid</a></li>
                    <li>View <a href="https://app.box.com/s/k9jxhk8as6nx6y87mmwen6ehl3kbcq8e">AI Lead Profile Video</a></li>
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel11-content"
                id="panel11-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>How do I search for an organization and find my path to key decision makers?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <ul className="ol-faq">
                    <li>View <a href="https://app.box.com/shared/static/oev320ly84jifp58chxuc8c9rj80x0wh.pdf">Search an org/Find path Job Aid</a></li>
                    <li>View <a href="https://app.box.com/s/qh9rgby8n5tyb8as4up34di1w0bcn53d">Search an org/Find path Job Video</a></li>
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>How do I push a lead to Salesforce?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <ol className="ol-faq">
                    <li>User selects ALL Prospects for a given Lead by choosing the 'Select All' option in the Prospect header OR chooses individual Prospects by only selecting the checkbox next to the desired Prospect </li>
                    <li>User selects the Action button in the upper right-hand corner, then selects 'Push to Salesforce’</li>
                  </ol>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel7-content"
                id="panel7-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>What is the definition of a connection?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <p>A relationship in which a person at JobsOhio is linked or associated with another person at a potential client company, either directly or indirectly.</p>
                  <p>JOI provides several connection types, including:</p>
                  <ul className="ol-faq">
                    <li>Current Company Connection</li>
                    <li>Education Connection</li>
                    <li>Email Connection</li>
                    <li>Past Experience Connection</li>
                    <li>LinkedIn Connection</li>
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>
                  Will spam/marketing emails and/or your unengaged LinkedIn contacts affect the tool’s outcome?
                </h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <p>
                  No, JOI performs data quality checks to ensure that spam accounts are filtered out. 
                  It will provide results based on weight triggers. If you are not in frequent contact 
                  with a LinkedIn contact or receive multiple emails from a spam account, 
                  nothing should be negatively affected; regardless, spam accounts will be filtered out.
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
            <div className="Faqscreen-sub-heading">
              <h3>Additional Resources</h3>
            </div>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>Where can I find training on how to use JOI?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <ol className="ol-faq">
                    <li>Check out <a href="https://joi.jobsohio.com/joitraning">JOI Training</a> in the left navigation panel for job aids & videos</li>
                    <li><a href="https://app.smartsheet.com/b/home?lgt=wf&lrt=s&EQBCT=ecb4f8bd4aca4eb4943a2e2eaba3cbaf">Click here</a> to schedule a 1:1 training with the Enterprise Data AI Team.</li>
                  </ol>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>Where can I learn more about the terms used in JOI?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line"></div>
                <div className="AccordionDetailsalldata">
                  <p>
                  The JOI application provides a glossary of terms immediately below this FAQ</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>
                  Who do I contact if I have additional questions on JOI?
                </h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-seclast"></div>
                <div className="AccordionDetailsalldata">
                  <p>
                    Please feel free to contact our email:
                    <b>Enterprisedata@jobsohio.com</b>
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="defaultExpandedAccordion">
              <AccordionSummary
                className="AccordionSummary-innerheading-and-icon"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel12-content"
                id="panel12-header"
              >
                <span className="AccordionRedDot"></span>
                <h4>Where do I go if I want to provide feedback on JOI?</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="defaultAccordionline"></div>
                <div className="defaultAccordionRed-Line-last"></div>
                <div className="AccordionDetailsalldata">
                  <p>Please submit your feedback through the <a href="https://app.smartsheet.com/b/form/00c8c0047881489cbe8eedc3114921c1">JOI Insights form</a>.</p>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
}