import * as React from "react";
import "./Style.css";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import StyledMenuInput from "../../StyledMenu/Index";
import video from "../../../Assets/video.svg"
import videodash from "../../../Assets/play-button.png"
import { ARCHIVE_VIDEO, COMPETITIVE_INTEL_VIDEO, CRIE_VIDEO, FAVORITES_VIDEO, PERSONALIZED_DASHBOARD_VIDEO, PUSH_TO_JOSF_VIDEO } from "../../../Utils/Constants";
import TrainingModal from "../../Training/TrainingModal";
export default function CompanyProfile({
  dataForInformation,
  handleToPDF,
  setIsDecisionMakerExcel,
  setIsSalesForceTrigger,
  setArchiveRecord,
  setTrackRecord,
  setRequestUpdateRecord,
  disableRequestUpdate,
  setShareRecord,
  value, showSalesForce

}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [videoModal, setVideoModal] = React.useState(false);
  const [overviewVideoModal, setOverviewVideoModal] = React.useState(false);
  const [videoLink, setVideoLink] = React.useState("")
  const handleCloseOverview = () => {
    setOverviewVideoModal(false)
  }
  const handleVideoModal = (item) => {
    setOverviewVideoModal(true)
    if (item === 1) {
      setVideoLink(COMPETITIVE_INTEL_VIDEO);
    } else if (item === 2) {
      setVideoLink(ARCHIVE_VIDEO);
    } else if (item === 3) {
      setVideoLink(FAVORITES_VIDEO);
    } else if (item === 4) {
      setVideoLink(PUSH_TO_JOSF_VIDEO);
    } else if (item === 5) {
      setVideoLink(CRIE_VIDEO);
    }
  }
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuSalesforce = () => {
    setIsSalesForceTrigger(true);
    setAnchorEl(null);
  };
  const handleMenuDownloadExcel = () => {
    setIsDecisionMakerExcel(true);
    setAnchorEl(null);
  };
  const handleAnchoElement = () => {
    setAnchorEl(null);
  };
  const handleMenuArchiveRecord = () => {
    setArchiveRecord(true);
    setAnchorEl(null);
  }
  const handleMenuTrackRecord = () => {
    setTrackRecord(true);
    setAnchorEl(null);
  }
  const handleMenuShareRecord = () => {
    setShareRecord(true);
    setAnchorEl(null);
  }
  const handleMenuRequestUpdateRecord = () => {
    setRequestUpdateRecord(true);
    setAnchorEl(null);
  }
  const handleToPDFClick = () => {
    handleToPDF()
    setAnchorEl(null)
  }
  return (
    <>
      <div className="Leades-filter-drop-down-button-main-container do_not_print">
        {
          window.location.pathname.includes("companyprofilescreen") ? <h3>Organization Details</h3> : null
        }
        <div className="video-space">
          <div className="video_container" onClick={() => setVideoModal(!videoModal)}>

            <img src={video} alt="video" className="videoIcon" />
            {videoModal && (
              <div className="bell-icon-box-video">

                <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(2)}>
                  <div className="bell-icon-outer-video">
                    {/* <div className="bell-icon-container-inner"> */}
                    <img src={videodash} alt="logo" className="video_img_drop" />
                    {/* </div> */}
                    <div className="bellicon-text">
                      <p className="bellicon-p-search">Archive</p>
                    </div>
                  </div>
                  <div className="bellicon-separator"></div>
                </div>
                <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(1)}>
                  <div className="bell-icon-outer-video">
                    {/* <div className="bell-icon-container-inner"> */}
                    <img src={videodash} alt="logo" className="video_img_drop" />
                    {/* </div> */}
                    <div className="bellicon-text">
                      <p className="bellicon-p-search">Competitive Intel</p>
                    </div>
                  </div>
                  <div className="bellicon-separator"></div>
                </div>
                <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(5)}>
                  <div className="bell-icon-outer-video">
                    {/* <div className="bell-icon-container-inner"> */}
                    <img src={videodash} alt="logo" className="video_img_drop" />
                    {/* </div> */}
                    <div className="bellicon-text">
                      <p className="bellicon-p-search">CRIE</p>
                    </div>
                  </div>
                  <div className="bellicon-separator"></div>
                </div>
                <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(3)}>
                  <div className="bell-icon-outer-video">
                    {/* <div className="bell-icon-container-inner"> */}
                    <img src={videodash} alt="logo" className="video_img_drop" />
                    {/* </div> */}
                    <div className="bellicon-text">
                      <p className="bellicon-p-search">Favorites</p>
                    </div>
                  </div>
                  <div className="bellicon-separator"></div>
                </div>

                <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(4)}>
                  <div className="bell-icon-outer-video">
                    {/* <div className="bell-icon-container-inner"> */}
                    <img src={videodash} alt="logo" className="video_img_drop" />
                    {/* </div> */}
                    <div className="bellicon-text">
                      <p className="bellicon-p-search">Push to JOSF</p>
                    </div>
                  </div>
                  <div className="bellicon-separator"></div>
                </div>

              </div>
            )}
          </div>
          {
            value === 0 ? <div className="Leades-filter-inner-container">
              <Button
                style={{ textTransform: "none" }}
                id="action-button"
                aria-controls={open ? "action-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleMenuClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Action
              </Button>
              <StyledMenuInput
                showSalesForce={showSalesForce}
                handleToPDF={handleToPDFClick}
                anchorEl={anchorEl}
                open={open}
                handleAnchoElement={handleAnchoElement}
                handleMenuSalesforce={handleMenuSalesforce}
                handleMenuDownloadExcel={handleMenuDownloadExcel}
                handleMenuArchiveRecord={handleMenuArchiveRecord}
                handleMenuTrackRecord={handleMenuTrackRecord}
                handleMenuRequestUpdateRecord={handleMenuRequestUpdateRecord}
                disableRequestUpdate={disableRequestUpdate}
                handleMenuShareRecord={handleMenuShareRecord}
              />
            </div> : null
          }
        </div>
      </div >
      <TrainingModal overviewVideoModal={overviewVideoModal} handleCloseOverview={handleCloseOverview} videoLink={videoLink} />
    </>
  );
}