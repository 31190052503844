import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import { APIUrlThree, APIUrlToken } from '../../Utils/Utils';
import "../../Components/Header/Search/Search.css";

export default function PeopleSearch({ setLoading, modalSearchPeopleData, setModalSearchPeopleData, responsePeopleData, showSearchPeopleData, setShowSearchPeopleData, setApiHitPeople, setSelectedItem }) {
    const clearSearchData = () => {
        setShowSearchPeopleData(false);
        setApiHitPeople(true)
        setModalSearchPeopleData("");
    };

    return (
        <div className='ci-search-inner'>
            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                className='full-searchbar-comp ci-searchbar'
            >
                <SearchIcon className='searchicon-in-header' />
                <InputBase
                    value={modalSearchPeopleData}
                    onChange={(e) => { setModalSearchPeopleData(e?.target?.value); setApiHitPeople(true) }}
                    className='search-inner-text'
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search..."
                    inputProps={{ 'aria-label': 'Search' }}
                    autoComplete="off"
                />
                {modalSearchPeopleData && (
                    <div onClick={clearSearchData} className='cross-icon-prospect-search'>
                        <CloseIcon className='showicons-search' />
                    </div>
                )}
                {showSearchPeopleData && (
                    <div className='Autocompletedropdown-track' style={{ width: "400px", maxHeight: "45vh" }}>
                        {responsePeopleData?.length > 0 ? (
                            responsePeopleData?.map((item) => (
                                <div className='outterAutocompletedropdown' key={item.id}>
                                    <div onClick={() => {
                                        setSelectedItem(item);
                                        setApiHitPeople(false);
                                        setShowSearchPeopleData(false);
                                        setModalSearchPeopleData(`${item?.first_name} ${item?.last_name},${item?.primary_organization}`);
                                    }} className='useralldata'>
                                        {item?.first_name} {item?.last_name}, {item?.primary_organization}
                                    </div>
                                    <div className='separatorline'></div>
                                </div>
                            ))
                        ) : (
                            <div className='useralldata'>Not Available</div>
                        )}
                    </div>
                )}
            </Paper>
        </div>
    );
}
