import React, { useEffect, useState } from 'react'
import { Box, Button, InputBase, Modal, Paper } from '@mui/material'
import { APIUrlThree, APIUrlToken } from '../../Utils/Utils';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../Loader/Loader';
import FormControlProfile from '../FormControlProfile/Index';
import { toast } from 'react-toastify';

const ProspectRequestModal = ({ requestUpdateRecord, setRequestUpdateRecord, profileData, peopleId }) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "8px",
    };
    const [loading, setLoading] = useState(false)
    const [ShowAutocompleteprimary, setShowAutocompleteprimary] = useState(false);
    const [industryDropDown, setIndustryDropDown] = useState([])
    const [industryValue, setIndustryValue] = useState("")
    const [ResponseDatanew, setResponseDatanew] = useState(null);
    const [primaryOrganization, setPrimaryOrganization] = useState("")
    const [primaryJobTitle, setPrimaryJobTitle] = useState("")
    const [ResponseJobData, setResponseJobData] = useState(null);
    const [ShowAutocomplete, setShowAutocomplete] = useState(false);
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [linkedin, setLinkedin] = useState()

    const handlesearch = (e) => {
        setLoading(true);
        const data = {
            org_name: primaryOrganization,
        };
        axios
            .post(`${APIUrlThree()}/v1/org_search`, data, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "X-API-Key": APIUrlToken()
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setShowAutocompleteprimary(true);
                    setLoading(false);
                    setResponseDatanew(response.data);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    const jobtitlesearch = (e) => {
        setLoading(true);
        const data = {};
        data.job_title = primaryJobTitle;
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "X-API-Key": APIUrlToken()
            },
            url: `${APIUrlThree()}/v1/job_title_search`,
            data: JSON.stringify(data),
        };
        axios(option)
            .then((response) => {
                setLoading(false);
                setShowAutocomplete(true);
                if (response?.status === 200) {
                    setResponseJobData(response.data);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    React.useEffect(() => {

        let timer;
        if (primaryJobTitle?.length > 2 &&
            ResponseJobData === null
        ) {
            timer = setTimeout(() => {
                jobtitlesearch();
            }, 1000);
        }

        if (primaryJobTitle?.length === 0) {
            setResponseJobData(null)
        }
        return () => clearTimeout(timer);

    }, [primaryJobTitle, ResponseJobData]);

    React.useEffect(() => {
        let timer;
        if (
            primaryOrganization?.length > 2 &&
            ResponseDatanew === null
        ) {
            timer = setTimeout(() => {
                handlesearch();
            }, 1000);
        }
        if (primaryOrganization?.length === 0) {
            setResponseDatanew(null);
        }
        return () => clearTimeout(timer);

    }, [primaryOrganization, ResponseDatanew]);

    const handelselectdata = (item) => {
        setShowAutocompleteprimary(true);
        const people_name = `${item.org_name ? item?.org_name : ""}`;
        setPrimaryOrganization(item)
        setShowAutocompleteprimary(false);
    };

    const handelselectjobtitle = (item) => {
        setShowAutocomplete(true);
        const people_name = `${item.primary_job_title ? item?.primary_job_title : ""
            }`;
        setPrimaryJobTitle(people_name)
        setShowAutocomplete(false);
    };

    const GetIndustryData = () => {
        setLoading(true);
        const option = {
            method: "GET",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "X-API-Key": APIUrlToken()
            },
            url: `${APIUrlThree()}/v1/people_filters`,
        };
        axios(option)
            .then((e) => {
                setLoading(false);
                const data = JSON.parse(e?.request?.response);

                setIndustryDropDown(data?.data?.categories);

            })
            .catch(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        GetIndustryData()
    }, [])


    const SendtRequestUpdate = () => {
        setLoading(true);
        const data = [
            {
                "id": 0,
                "firstname": firstName || "",
                "lastname": lastName || "",
                "people_id": peopleId,
                "email": email || "",
                "linkedIn": linkedin || "",
                "industry": industryValue || "",
                "phone": phone || "",
                "organization_name": primaryOrganization?.org_name || "",
                "org_id": primaryOrganization?.id || "",
                "jobtitle": primaryJobTitle || ""
            }
        ]

        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "X-API-Key": APIUrlToken()
            },
            url: `${APIUrlThree()}/v1/save_request_update_people`,
            data: JSON.stringify(data),
        };
        axios(option)
            .then((response) => {
                setLoading(false);
                setRequestUpdateRecord(false)
                toast.success("Record update Requested Successfully")
            })
            .catch((error) => {
                setLoading(false);
            });

    }
    return (
        <>
            {loading ? <Loader /> : null}
            <Modal
                open={requestUpdateRecord}
                onClose={() => { setRequestUpdateRecord(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="vrufyerv">
                    <Box sx={style} style={{ overflowY: "auto", height: "90vh" }}>
                        <div className="de_pad">
                            <h3>Record Update Request</h3>
                            <p className='de_head'>Simply provide the information you want  updated on the record </p>

                            {/* First Name Field */}
                            <div className="de_input_cont">
                                <label htmlFor="" className="label-floating">
                                    First Name<span className="dt-en-red">({profileData?.first_name})</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder='First Name'
                                    value={firstName}
                                    onChange={(e) => setFirstName(e?.target?.value)}
                                />
                            </div>

                            {/* Last Name Field */}
                            <div className="de_input_cont">
                                <label htmlFor="" className="label-floating">
                                    Last Name<span className="dt-en-red">({profileData?.last_name})</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder='Last Name'
                                    value={lastName}
                                    onChange={(e) => setLastName(e?.target?.value)}
                                />
                            </div>
                            {/* Organization Field */}
                            <div className="label-form-prospect-div-head">
                                <label htmlFor="" className="label-floating">
                                    Organization<span className="dt-en-red">({profileData?.primary_organization})</span>
                                </label>
                                <Paper className='full-searchbar-ai-profile'
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                                >
                                    <InputBase
                                        value={primaryOrganization?.org_name}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            setPrimaryOrganization(inputValue);
                                        }}
                                        className='search-inner-textai-profile'
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Search"
                                        inputProps={{ 'aria-label': 'Search google maps' }}
                                        autocomplete="off"
                                    />
                                    <div onClick={() => { setPrimaryOrganization(""); setShowAutocompleteprimary(false) }} className="cross-icon-prospect">
                                        {primaryOrganization?.length ? (
                                            <CloseIcon className='showicons' />
                                        ) : null}
                                    </div>
                                    <div className="PrimaryOrganizationoutter">
                                        {ShowAutocompleteprimary ? (
                                            <div className='PrimaryOrganization-data'>
                                                <div className={ResponseDatanew === "" || ResponseDatanew?.length > 0 ? '-aiprofile ' : "sethjgcghjiohgh"}>
                                                    {ResponseDatanew && ResponseDatanew.length > 0 ? (
                                                        ResponseDatanew?.map((item) => {
                                                            return (
                                                                <>
                                                                    <div className='innerAutocomplete' onClick={() => handelselectdata(item)}>
                                                                        {item?.org_name}
                                                                    </div>
                                                                    <div className='separatorline'></div>
                                                                </>
                                                            )
                                                        })
                                                    ) : (
                                                        <div className={ResponseDatanew?.length === 0 ? 'nodatafound' : "degtyrrf"}>
                                                            {primaryOrganization?.length || ResponseDatanew?.length === 0 ? "Not available" : ""}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </Paper>
                            </div>
                            {/* Job Title Field */}
                            <div className="label-form-prospect-div-head">
                                <label htmlFor="" className="label-floating">
                                    Job Title<span className="dt-en-red">({profileData?.primary_job_title})</span>
                                </label>
                                <Paper className='full-searchbar-ai-profile'
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                                >
                                    <InputBase
                                        value={primaryJobTitle}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            setPrimaryJobTitle(inputValue);
                                        }}
                                        className='search-inner-textai-profile'
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Search"
                                        inputProps={{ 'aria-label': 'Search google maps' }}
                                        autocomplete="off"
                                    />
                                    <div onClick={() => { setPrimaryJobTitle(""); setShowAutocomplete(false) }} className="cross-icon-prospect">
                                        {primaryJobTitle?.length ? (
                                            <CloseIcon className='showicons' />
                                        ) : null}            </div>
                                    {ShowAutocomplete && (
                                        <div className='setpositionaitable'>
                                            <div className={ResponseJobData === "" || ResponseJobData?.length > 0 ? 'Autocompletedropdowna-aiprofile ' : "sethjgcghjiohgh"}>
                                                {ResponseJobData && ResponseJobData.length > 0 ? (
                                                    ResponseJobData?.map((item) => {
                                                        return (
                                                            <>
                                                                <div className='innerAutocomplete' key={item.id} onClick={() => handelselectjobtitle(item)}>
                                                                    {item?.primary_job_title}
                                                                </div>
                                                                <div className='separatorline'></div>
                                                            </>
                                                        )
                                                    })
                                                ) : (
                                                    <div className={ResponseJobData?.length === 0 ? 'nodatafound' : "degtyrrf"}>
                                                        {primaryJobTitle?.length || ResponseJobData?.length === 0 ? "Not available" : ""}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Paper>
                            </div>
                            {/* Industry/sector */}
                            <div className="de_input_cont">
                                <label htmlFor="" className="label-floating">
                                    Industry/Sector<span className="dt-en-red">({profileData?.categories})</span>
                                </label>
                                <FormControlProfile
                                    value={industryValue}
                                    handleChange={(e) => setIndustryValue(e?.target?.value)}
                                    dataList={industryDropDown}
                                />
                            </div>
                            {/* LinkedIn URL Field */}
                            <div className="de_input_cont">
                                <label htmlFor="" className="label-floating">
                                    LinkedIn URL<span className="dt-en-red">({profileData?.linkedin})</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder='LinkedIn URL'
                                    value={linkedin}
                                    onChange={(e) => setLinkedin(e?.target?.value)}
                                />
                            </div>
                            {/* Email Field */}
                            <div className="de_input_cont">
                                <label htmlFor="" className="label-floating">
                                    Email<span className="dt-en-red">({profileData?.contact_email || profileData?.email})</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder='Email'
                                    value={email}
                                    onChange={(e) => setEmail(e?.target?.value)}
                                />
                            </div>
                            {/* Phone Field */}
                            <div className="de_input_cont">
                                <label htmlFor="" className="label-floating">
                                    Phone<span className="dt-en-red">({profileData?.phone_number || profileData?.phone_no})</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder='Phone'
                                    value={phone}
                                    onChange={(e) => setPhone(e?.target?.value)}
                                />
                            </div>

                            <div className="de_hr">
                                <div className='de_btn_cont'>
                                    <Button className='de_cancel' onClick={() => setRequestUpdateRecord(false)}>Cancel</Button>
                                    <Button className='de_next' onClick={() => SendtRequestUpdate()} >Submit</Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </Modal>
        </>
    )
}

export default ProspectRequestModal
