import React, { useEffect, useState } from "react";
import { authClient } from "../../../Utils/Common";
import { useNavigate } from "react-router-dom";
import { APIUrlOne, APIUrlThree, APIUrlToken, SetOktaAuthData, SetUserId } from "../../../Utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Login from "./Login";
import { LOGIN } from "../../../Utils/Constants";
import ModalImage from "../../../Assets/contactUs-modal.png";
import LabelInput from "../../LabelInputFields/Index";
import { FormControl, MenuItem, Select, Stack } from "@mui/material";
import { Textarea } from "@mui/joy";
import SaveAltTwoToneIcon from "@mui/icons-material/SaveAltTwoTone";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const LoginVerify = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [retrieveSearchData, setRetrieveSearchData] = useState({
    code: "",
    state: "",
  });
  const [contactDetails, setContactDetails] = React.useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    joDepartments: "",
    department: "",
    requestType: "",
    message: "",
  });
  const [Departments, setDepartments] = React.useState([]);
  const [Request, setRequest] = React.useState([]);
  const [openModal, setopenModal] = React.useState(false);
  const [ContactUsModal, setContactUsModal] = React.useState(false);
  // const handleOpen = () => setopenModal(true);
  const handleClose = () => {
    setopenModal(false);
    setContactUsModal(true);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    setRetrieveSearchData({
      code: code,
      state: state,
    });
  }, []);

  useEffect(() => {
    if (retrieveSearchData?.code && retrieveSearchData?.state) {
      login();
    }
  }, [retrieveSearchData]);

  const login = async () => {
    setLoading(true);
    let res = await authClient?.session?.get();
    let email = res?.login;
    let user = await res?.user();
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key":APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/get_user_id?email=${email}`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);        
        if (e?.status === 200) {
          if (e?.data?.user_id === "User not found") {
            toast.error("user does not exists, contact site administrator");
          } else {
            SetUserId("userId", Number(e?.data?.user_id));
            const result = {
              user: {
                profile: {
                  username: email,
                  firstName: user.profile.firstName,
                  lastName: user.profile.lastName,
                },

              },
            };
            SetOktaAuthData("auth", result);
            navigate("/dashboard");
            toast.success("User login successfully");
          }
        }
      })
      .catch(() => {
        setLoading(false);
        if (ContactUsModal === true) {
          setopenModal(false);
        } else {
          setopenModal(true);
        }
      });
  };

  const getDepartments = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key":APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/get_jo_departments`,
    };
    axios(option)
      .then((e) => {
        setDepartments(e?.data?.data);
      })
      .catch(() => { });
  };
  React.useEffect(() => {
    getDepartments();
  }, []);
  const GetDataRequest = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key":APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/request_type`,
    };
    axios(option)
      .then((e) => {
        setRequest(e?.data?.data);
      })
      .catch(() => { });
  };
  React.useEffect(() => {
    GetDataRequest();
  }, []);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  function isValidEmail(emailAddress) {
    return emailRegex.test(emailAddress);
  }
  const validateFields = () => {
    if (
      !contactDetails?.firstName ||
      contactDetails?.firstName?.trim() === ""
    ) {
      toast.error("Please Enter First Name");
      return false;
    }
    if (!contactDetails?.lastName || contactDetails?.lastName?.trim() === "") {
      toast.error("Please Enter Last Name");
      return false;
    }
    if (
      !contactDetails?.emailAddress ||
      contactDetails?.emailAddress?.trim() === ""
    ) {
      toast.error("Please Enter Email Address");
      return false;
    }
    if (!isValidEmail(contactDetails?.emailAddress)) {
      toast.error("Please Enter Valid Email");
      return false;
    }
    if (
      !isValidEmail(contactDetails?.emailAddress) ||
      contactDetails?.emailAddress?.trim() === ""
    ) {
      toast.error("Please Enter Email Address");
      return false;
    }
    if (!contactDetails?.joDepartments) {
      toast.error("Please Select JO Deparment");
      return false;
    }
    if (!contactDetails?.requestType) {
      toast.error("Please Select Request Type");
      return false;
    }
    return true;
  };
  const SubmitContact = (e) => {
    if (!validateFields()) return;
    setLoading(true);
    const data = {};
    data.first_name = contactDetails?.firstName;
    data.last_name = contactDetails?.lastName;
    data.email = contactDetails?.emailAddress;
    data.jo_department = contactDetails?.joDepartments;
    data.department = contactDetails?.department;
    data.request_type = contactDetails?.requestType;
    data.message = contactDetails?.message;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key":APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/save_jo_request`,
      data: JSON.stringify(data),
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        setContactDetails({
          firstName: "",
          lastName: "",
          emailAddress: "",
          joDepartments: "",
          department: "",
          requestType: "",
          message: "",
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <>
      {loading ? <Loader /> : null}

      <div>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="LoginVerify-modal-main"
        >
          <Box sx={style}>
            <div className="Modal-image-container">
              <img className="Modal-image" src={ModalImage} alt="" />
            </div>
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal 
          </Typography> */}
            <div className="Modal-containt-container">
              <Typography
                className="Massage-for-theuser"
                id="modal-modal-description"
                sx={{ mt: 2 }}
              >
                The user has not been activated from the backend, please contact
                the admin
              </Typography>
              <div className="Modal-button-container">
                <Button onClick={handleClose} className="Back-to-login-page">
                  Contact Us
                </Button>
              </div>
            </div>
          </Box>
        </Modal>

        {/* modal for Contact us data */}

        <Modal
          open={ContactUsModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="contact-us-modal"
        >
          <Box sx={style}>
            <p className="Contact-heading-on-modal">Contact Us</p>
            <p className="Contact-us-modal-inner-heading">You are welcome to contact the JOI AI Team</p>
            <div className="">
              <div className="Contact-us-modal-flex-container">
                <div className="Modal-contact-inputs">
                  <label htmlFor="">Firts Name</label>
                  <span className="mandatoryfields">*</span>
                  <LabelInput
                    onChange={(e) => {
                      const inputvalue = e?.target?.value;
                      setContactDetails({
                        ...contactDetails,
                        firstName: inputvalue,
                      });
                    }}
                    value={contactDetails?.firstName}
                  />
                </div>
                <div className="Modal-contact-inputs">
                  <label htmlFor="">Last Name</label>
                  <span className="mandatoryfields">*</span>
                  <LabelInput
                    onChange={(e) => {
                      const inputvalue = e?.target?.value;
                      setContactDetails({
                        ...contactDetails,
                        lastName: inputvalue,
                      });
                    }}
                    value={contactDetails?.lastName}
                  />
                </div>
                <div className="Modal-contact-inputs">
                  <label htmlFor="">Email Address  <span className="mandatoryfields">*</span></label>
                  <LabelInput
                    onChange={(e) => {
                      const inputvalue = e?.target?.value;
                      setContactDetails({
                        ...contactDetails,
                        emailAddress: inputvalue,
                      });
                    }}
                    value={contactDetails?.emailAddress}
                  />
                </div>
              </div>
              <div className="Contact-us-flex-for-drop-downs">
                <div className="Modal-contactus-Drop-downs">
                  <label htmlFor="">JO Department</label>
                  <span className="mandatoryfields">*</span>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      className="ContactUsScreen-dropdown"
                      onChange={(e) => {
                        const inputvalue = e?.target?.value;
                        setContactDetails({
                          ...contactDetails,
                          joDepartments: inputvalue,
                        });
                      }}
                      value={contactDetails?.joDepartments}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {Departments?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="Modal-contactus-Drop-downs">
                  <label htmlFor="">Request Type/Subject<span className="mandatoryfields">*</span></label>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      className="ContactUsScreen-dropdown"
                      onChange={(e) => {
                        const inputvalue = e?.target?.value;
                        setContactDetails({
                          ...contactDetails,
                          requestType: inputvalue,
                        });
                      }}
                      value={contactDetails?.requestType}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {Request?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="Contact-us-container-fortextarea">
                <label htmlFor="">Question or Message</label>
                <Textarea
                  className="ContactUsScreen-lables-text-field-area"
                  value={contactDetails?.message}
                  aria-label="minimum height"
                  minRows={3}
                  onChange={(e) => {
                    const inputvalue = e?.target?.value;
                    setContactDetails({
                      ...contactDetails,
                      message: inputvalue,
                    });
                  }}
                />
              </div>
            </div>
            <Stack spacing={2} direction="row">
              <Button
                className="ContactUs-modal-submit-buttton"
                variant="contained"
                onClick={SubmitContact}
              >
                <SaveAltTwoToneIcon />
                SUBMIT
              </Button>
            </Stack>
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default LoginVerify;
