import React, { useState } from 'react'
import Layout from '../../Components/Layout/Layout'
import BusinessRetention from '../../Components/BusinessRE/BusinessRetention'
import AiLeadsAction from '../../Components/AiLeads/AiLeadsButton/AiLeadsAction'
import BusinessExpansion from '../../Components/BusinessRE/BusinessExpansion'
import { Button, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
const BusinessRE = () => {
    const [isDecisionMakerExcel, setIsDecisionMakerExcel] = useState(false);
    const [isSalesForceTrigger, setIsSalesForceTrigger] = useState(false);
    const [unArchivedRecord, setUnArchivedRecord] = useState(false)
const navigate=useNavigate()
    return (
      <Layout>
        <div>
      <div className="ComingSoon-outerr-container">
        <div className="ComingSoon-inner">
          <div className="ComingSoon-inner-heading">
            <h3>Coming soon</h3>
            <p className="ComingSoon-scheduled">In JOI version scheduled for April 2025</p>
            {/* <p className="ComingSoonleftside"> (Between July 2024 and June 2025)</p> */}
         
                {/* <Stack className="BacktoDashbaordbuttonstack" spacing={2} direction="row">
                  <Button className="BacktoDashbaordbutton" variant="outlined" onClick={() => navigate('/dashboard')}>Back to Dashboard</Button>
                </Stack> */}
            
          </div>
        </div>
      </div>
    </div>
    </Layout>
        // <Layout>
        //     <div className="child-section-of-everypage  ">
        //         <AiLeadsAction
        //             setIsDecisionMakerExcel={setIsDecisionMakerExcel}
        //             setIsSalesForceTrigger={setIsSalesForceTrigger}
        //             isSalesForceTrigger={isSalesForceTrigger} />
        //         <div style={{ marginTop: "54px" }}>
        //             <BusinessRetention
        //                 isSalesForceTrigger={isSalesForceTrigger}
        //                 isDecisionMakerExcel={isDecisionMakerExcel}
        //                 setIsDecisionMakerExcel={setIsDecisionMakerExcel}
        //                 unArchivedRecord={unArchivedRecord}
        //                 setUnArchivedRecord={setUnArchivedRecord} />
        //         </div>
        //         <div>
        //             <h3 style={{ textTransform: "capitalize", color: "#101820", margin: "20px 0px 20px 0px" }}>Business Expansion</h3>
        //             <BusinessExpansion
        //                 isSalesForceTrigger={isSalesForceTrigger}
        //                 isDecisionMakerExcel={isDecisionMakerExcel}
        //                 setIsDecisionMakerExcel={setIsDecisionMakerExcel}
        //                 unArchivedRecord={unArchivedRecord}
        //                 setUnArchivedRecord={setUnArchivedRecord} />
        //         </div>
        //     </div>
        // </Layout>
    )
}
export default BusinessRE