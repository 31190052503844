import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import { APIUrlThree, APIUrlToken } from '../../Utils/Utils';
import "../../Components/Header/Search/Search.css";

export default function OrganizationSearch({ setLoading, modalSearchOrgData, setModalSearchOrgData, responseOrgData, showSearchOrgData, setShowSearchOrgData, setApiHitOrg, setSelectedItem }) {
    const clearSearchData = () => {
        setModalSearchOrgData("");
        setShowSearchOrgData(false);
        setApiHitOrg(true)
    };

    return (
        <div className='ci-search-inner'>
            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                className='full-searchbar-comp ci-searchbar'
            >
                <SearchIcon className='searchicon-in-header' />
                <InputBase
                    value={modalSearchOrgData}
                    onChange={(e) => { setModalSearchOrgData(e.target.value); setApiHitOrg(true) }}
                    className='search-inner-text'
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search..."
                    inputProps={{ 'aria-label': 'Search' }}
                    autoComplete="off"
                />
                {modalSearchOrgData && (
                    <div onClick={clearSearchData} className='cross-icon-prospect-search'>
                        <CloseIcon className='showicons-search' />
                    </div>
                )}
                {showSearchOrgData && (
                    <div className='Autocompletedropdown-track' style={{ width: "400px", maxHeight: "45vh" }}>
                        {responseOrgData?.length > 0 ? (
                            responseOrgData?.map((item) => (
                                <div className='outterAutocompletedropdown' key={item.id}>
                                    <div onClick={() => { setSelectedItem(item); setApiHitOrg(false); setShowSearchOrgData(false); setModalSearchOrgData(item?.org_name) }} className='useralldata'>
                                        {item?.org_name} 
                                    </div>
                                    <div className='separatorline'></div>
                                </div>
                            ))
                        ) : (
                            <div className='useralldata'>Not Available</div>
                        )}
                    </div>
                )}
            </Paper>
        </div>
    );
}
