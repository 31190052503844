import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import "./Style.css";
import AiLeadsTable from "../../Components/AiLeads/Table/Table";
import LeadsFilter from "../../Components/AiLeads/Filter/LeadsFilter";
import AiLeadsAction from "../../Components/AiLeads/AiLeadsButton/AiLeadsAction";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { APIUrlOne, APIUrlThree, APIUrlToken, GetUserId } from "../../Utils/Utils";
const AILeads = () => { 
  const [tableCommingData, setTableCommingData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState([]);
  const [istableDataFilter, setIstableDataFilter] = React.useState(false);
  const [currentLeadsLength, setCurrentLeadsLength] = React.useState('');
  const [isDecisionMakerExcel, setIsDecisionMakerExcel] = useState(false);
  const [isSalesForceTrigger, setIsSalesForceTrigger] = useState(false);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [showData, setShowData] = React.useState([]);
  const [showlast, setShowlast] = React.useState([]);
  const [jScoredata, setJscore] = React.useState([]);
  const [selectedCountries, setSelectedCountries] = React.useState([]);
  const [statsCount, setStatsCount] = useState(0);
  const [leadsProfileData, setLeadsProfileData] = useState([]);
  const [archiveRecord, setArchiveRecord] = useState(false)
  const [trackRecord, setTrackRecord] = useState(false)
  const [requestUpdateRecord, setRequestUpdateRecord] = useState(false);
  const [disableRequestUpdate, setDisableRequestUpdate] = useState(false);
  const [skip, setSkip] = React.useState(0);
  const [profileRecords, setProfileRecords] = React.useState(false);
  const [selectedProfile, setSelectedProfile] = React.useState();
  const [showSalesForce,setShowSalesForce]=useState("false")
  const loadMore = () => {
    // setLoading(true);
    setSkip(page => page + 1);
    setTimeout(() => {
      // setLoading(false);
    }, 1000);
  };
  const perPage = 50;
  const userId = GetUserId();
  const validateFilters = () => {
    if (!selectedCountries?.length && !selectedData?.length && !showlast?.length && !jScoredata?.length) {
      toast.error("Please Select Filters");
      return false;
    }
    return true;
  }
  const duplicateApply = (e) => {
    if (e != "reset") {
      if (!validateFilters()) return;
    }
    setSelectedProfile([])
    setProfileRecords(false)
    setLoading(true);
    const data = {};
    data.categories = selectedData;
    data.revenue_range = showlast;
    data.j_score = jScoredata?.[0];
    data.country = selectedCountries;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/apply_org_filter?limit=${perPage}&skip=${(page - 1) * 50}`,
      data: JSON.stringify(data),
    };
    axios(option)
      .then((e) => {
        setTimeout(() => setLoading(false), 100)
        setTotalPages(10)
        const comingData = e?.data?.data;
        const statsCount = e?.data?.count;
        setStatsCount(statsCount);
        setPage(1);
        if (comingData.length === 0 || comingData.length % 50 !== 0) {
          setHasMore(false);
        } else {
          setTimeout(() => {
            setHasMore(true);
          }, 1000);
        }
        if (page > 1) {
          setTableCommingData(comingData);
        }
        else {
          setTableCommingData(comingData);
        }
        if (comingData.length === 0) {
          setHasMore(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const handleApply = (e) => {
    // if (!validateFilters()) return;
    // if (statsCount <= tableCommingData.length) return;
    // if (loading) return;
    setHasMore(false);
    setLoading(true);
    const data = {};
    data.categories = selectedData;
    data.revenue_range = showlast;
    data.j_score = jScoredata?.[0];
    data.country = selectedCountries;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/apply_org_filter?limit=${perPage}&skip=${(page - 1) * 50}`,
      data: JSON.stringify(data),
    };
    axios(option)
      .then((e) => {
        setTimeout(() => setLoading(false), 100)
        const comingData = e?.data?.data;
        const statsCount = e?.data?.count;
        setStatsCount(statsCount);
        if (comingData.length % 50 !== 0 || comingData.length === 0) {
          setHasMore(false);
        } else {
          setTimeout(() => { setHasMore(true); }, 1000)
        }
        if (page > 1) {
          setTableCommingData(prevData => [...prevData, ...comingData]);
        }
        else {
          setTableCommingData(comingData);
        }
        if (comingData.length === 0) {
          setHasMore(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (page > 0) {
  //       if (tableCommingData?.length && profileRecords) {
  //         applyFilterProfile();
  //       }else if(tableCommingData?.length){
  //         handleApply();

  //       }
  //     }
  //   }, 150)
  // }, [page])
  const LeadsProfile = () => {
    setLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/get_filter_org?user_id=${userId}`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        if (e?.status === 200) {
          setLeadsProfileData(e?.data?.data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }
  useEffect(() => {
    LeadsProfile()
  }, [])
  const handleProfileFilterData=(item)=>{
    setSelectedProfile(item)
    setProfileRecords(true)
    setPage(1);
  }  
  const applyFilterProfile = async () => {
    setLoading(true)
    const formatedDateFrom = moment(selectedProfile?.fundingDate).format("YYYY-MM-DD");
    const formatedDateTo = moment(selectedProfile?.fundingDateAt).format("YYYY-MM-DD");
    const minFundingRounds = parseInt(selectedProfile?.minFundingRounds);
    const maxFundingRounds = parseInt(selectedProfile?.maxFundingRounds);
    const TotalFundingRounds = [minFundingRounds, maxFundingRounds]; const data = {};
    data.categories = selectedProfile?.categories ? [selectedProfile?.categories] : null;
    if (Array.isArray(data.categories) && data.categories.length === 0) {
      data.categories = null;
    }
    data.user_id = selectedProfile?.user_id;
    data.revenue_range = selectedProfile?.revenue_range ? JSON.parse(selectedProfile?.revenue_range) : null;
    data.num_employees = selectedProfile?.num_employees ? JSON.parse(selectedProfile?.num_employees) : null;
    data.state = selectedProfile?.state ? selectedProfile?.state : null;
    data.country = selectedProfile?.country ? [selectedProfile?.country] : null;
    data.ipo_status = selectedProfile?.ipoStatus ? selectedProfile?.ipoStatus : null;
    data.last_equity_funding_type = selectedProfile?.lastFundingType ? selectedProfile?.lastFundingType : null;
    // data.last_funding_at_from = formatedDateFrom === 'Invalid date' ? null : formatedDateFrom;
    // data.last_funding_at_to = formatedDateTo === 'Invalid date' ? null : formatedDateTo;
    data.num_funding_rounds = (minFundingRounds || maxFundingRounds) ? TotalFundingRounds : null;
    data.last_funding_total = selectedProfile?.fundingTotal ? selectedProfile?.fundingTotal : null;
    data.j_score = selectedProfile?.Jscore ? selectedProfile?.Jscore : null;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/apply_org_filter?limit=50&skip=${(page - 1) * 50}`,
      data: JSON.stringify(data),
    };
    axios(option)
      .then((e) => {
        setTimeout(() => setLoading(false), 100)
        if(e?.data?.count<50){
          setTotalPages(1)
        }else{
          setTotalPages(10)
        }
        const comingData = e?.data?.data;
        const statsCount = e?.data?.count;
        setStatsCount(statsCount);
        if (comingData.length === 0 || comingData.length % 50 !== 0) {
          setProfileRecords(false)
          setHasMore(false);
        } else {
          setTimeout(() => {
            setProfileRecords(true)
            setHasMore(true);
          }, 1000);
        }
     
        if (page > 1) {
          setTableCommingData(prevdata => [...prevdata, ...comingData,])
        } else {
          setTableCommingData(comingData);
        }
        
        if (comingData.length === 0) {
          setHasMore(false);
          setProfileRecords(false);
        }
        setLoading(false)
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <>
      <Layout>
        {loading ? <Loader /> : null}
        <div className="child-section-of-everypage  ">
          {/* <InfiniteScroll
            dataLength={tableCommingData.length}
            next={() => {
              if (hasMore) {
                setHasMore(false);
                setPage(page + 1)
              }
            }}
            hasMore={hasMore}
          /> */}
          <AiLeadsAction
            currentLeadsLength={currentLeadsLength}
            setIsDecisionMakerExcel={setIsDecisionMakerExcel}
            setIsSalesForceTrigger={setIsSalesForceTrigger}
            isSalesForceTrigger={isSalesForceTrigger}
            statsCount={statsCount}
            setArchiveRecord={setArchiveRecord}
            setTrackRecord={setTrackRecord}
            setRequestUpdateRecord={setRequestUpdateRecord}
            disableRequestUpdate={disableRequestUpdate}
            showSalesForce={showSalesForce}
          />
          <LeadsFilter
            applyFilterProfile={handleProfileFilterData}
            selectedCountries={selectedCountries} setSelectedCountries={setSelectedCountries}
            showlast={showlast}
            setShowlast={setShowlast}
            setJscore={setJscore}
            jScoredata={jScoredata}
            selectedData={selectedData}
            showData={showData}
            setShowData={setShowData}
            setSelectedData={setSelectedData}
            handleApply={(e) => { duplicateApply(e) }}
            tableCommingData={tableCommingData}
            setTableCommingData={setTableCommingData}
            istableDataFilter={istableDataFilter}
            setIstableDataFilter={setIstableDataFilter}
            setStatsCount={setStatsCount}
            setPage={setPage}
            leadsProfileData={leadsProfileData}
            setSelectedProfile={setSelectedProfile}
            setProfileRecords={setProfileRecords}
          />
          <AiLeadsTable
            handleApply={handleApply}
            tableCommingData={tableCommingData}
            setTableCommingData={setTableCommingData}
            istableDataFilter={istableDataFilter}
            setCurrentLeadsLength={setCurrentLeadsLength}
            setIstableDataFilter={setIstableDataFilter}
            isSalesForceTrigger={isSalesForceTrigger}
            isDecisionMakerExcel={isDecisionMakerExcel}
            setIsDecisionMakerExcel={setIsDecisionMakerExcel}
            setPage={setPage}
            page={page}
            archiveRecord={archiveRecord} setArchiveRecord={setArchiveRecord}
            trackRecord={trackRecord} setTrackRecord={setTrackRecord}
            requestUpdateRecord={requestUpdateRecord}
            setRequestUpdateRecord={setRequestUpdateRecord}
            setDisableRequestUpdate={setDisableRequestUpdate}
            profileRecords={profileRecords} setProfileRecords={setProfileRecords}
            applyFilterProfile={applyFilterProfile}
            selectedProfile={selectedProfile}
            setShowSalesForce={setShowSalesForce}
            totalPagesout={totalPages}
          />
        </div>
      </Layout>
    </>
  );
};
export default AILeads;