import React, { useEffect, useState } from 'react'
import Layout from '../../Components/Layout/Layout'
import './Profile.css'
import ProspectDetails from '../../Components/ProspectProfile/ProspectDetails'
import AiLeadsAction from '../../Components/AiLeads/AiLeadsButton/AiLeadsAction'
import ProspectOrganization from '../../Components/ProspectProfile/ProspectOrganization'
import ProspectExperience from '../../Components/ProspectProfile/ProspectExperience'
import ProspectEducation from '../../Components/ProspectProfile/ProspectEducation'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { APIUrlThree, APIUrlToken, APIUrlTwo, GetUserId } from '../../Utils/Utils'
import axios from 'axios'
import Loader from '../../Components/Loader/Loader'
import ProspectAiPath from '../../Components/ProspectProfile/ProspectAiPath'
import ProspectNewsFeed from '../../Components/ProspectProfile/ProspectNewsFeed'
import ProspectRequestModal from '../../Components/RequestUpdateModals/ProspectRequestModal'
import MessageModal from '../../Components/MessageModal/MessageModal'
import { toast } from 'react-toastify'
const Profile = () => {
    const navigate = useNavigate()
    const { id } = useParams();
    let peopleId = id
    const [loading, setLoading] = useState(false)
    const [isDecisionMakerExcel, setIsDecisionMakerExcel] = useState(false);
    const [isSalesForceTrigger, setIsSalesForceTrigger] = useState(false);
    const [archiveRecord, setArchiveRecord] = useState(false)
    const [trackRecord, setTrackRecord] = useState(false)
    const [requestUpdateRecord, setRequestUpdateRecord] = useState(false)
    const [profileData, setProfileData] = useState([])
    const [strengthData, setStrengthData] = useState([])
    const [eduDetails, setEduDetails] = useState(false);
    const [expDetails, setExpDetails] = useState(false);
    const [shareRecord, setShareRecord] = useState(false)
    const [messageModal, setMessageModal] = useState(false)
    const [organizationData, setOrganizationData] = useState([])
    // const fetchdata = () => {
    //     setLoading(true);
    //     const option = {
    //         method: "POST",
    //         headers: {
    //             "content-type": "plain/text",
    //             "X-API-Key": APIUrlToken()
    //         },
    //         url: `${APIUrlThree()}/v1/get_prospect_detail?people_id=${Number(peopleId)}`,
    //     };
    //     axios(option)
    //         .then((response) => {
    //             if(!response?.data?.result?.data?.[0]?.id){
    //                 setLoading(false);
    //                 setMessageModal(true)
    //             }else{
    //                 setProfileData(response?.data?.result?.data?.[0])
    //                 setEduDetails(response?.data?.result?.edu_details)
    //                 setExpDetails(response?.data?.result?.exp_details)
    //                 setLoading(false);
    //             }
    //         }
    //         )
    //         .catch(() => {
    //             setLoading(false);
    //         });
    // }
    const fetchdata = async () => {
        setLoading(true);
    
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-API-Key": APIUrlToken(),
            },
            url: `${APIUrlThree()}/v1/get_prospect_detail?people_id=${Number(peopleId)}`,
        };
    
        try {
            const response = await axios(options);
    
            const result = response?.data?.result;
    
            if (!result || !result.prospect_data?.[0]?.person_id) {
                setMessageModal(true);
            } else {
                getStrength(result)
               
            }
        } catch (error) {
            console.error('API Error:', error);
        } finally {
            setLoading(false);
        }
    };
    const getStrength = async (result) => {
        const tuples = [{
        //   items: [1, Number(peopleId)],
          items: [Number(GetUserId()), Number(peopleId)]
        }]
        const data = { tuples };
        const options = {
          method: "POST",
          headers: {
            "access-control-allow-origin": "*",
            "content-type": "application/json",
            "X-API-Key": APIUrlToken()
          },
          data: data,
          url: `${APIUrlTwo()}/v1/conn_strength`,
        };
        try {
          const response = await axios(options);
          const strengthData = response?.data[0]?.strength
          setStrengthData(strengthData)
          
          setProfileData(result.prospect_data[0]);
          setEduDetails(result.edu_details || []);
          setExpDetails(result.exp_details || []);
          setOrganizationData(result.org_data[0]);
        } catch (error) {
          // setLoading(false);
          toast.error(error?.response?.data?.message);
        } finally {
          // setLoading(false);
        }
      }
    
    useEffect(() => {
        fetchdata()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [peopleId])
    const handleProspectsPage = () => {
        navigate("/aidecisionmakers")
    }
    return (
        <Layout>
            {loading ? <Loader /> : null}
            <div className="child-section-of-everypage  ">
                <AiLeadsAction
                    setIsDecisionMakerExcel={setIsDecisionMakerExcel}
                    setIsSalesForceTrigger={setIsSalesForceTrigger}
                    setShareRecord={setShareRecord}
                    isSalesForceTrigger={isSalesForceTrigger} setRequestUpdateRecord={setRequestUpdateRecord}
                    archiveRecord={archiveRecord} setArchiveRecord={setArchiveRecord} setTrackRecord={setTrackRecord} />
                <ProspectDetails strengthData={strengthData} setIsSalesForceTrigger={setIsSalesForceTrigger}
                    isSalesForceTrigger={isSalesForceTrigger}
                    shareRecord={shareRecord} setShareRecord={setShareRecord}
                    profileData={profileData} archiveRecord={archiveRecord} setArchiveRecord={setArchiveRecord} peopleId={peopleId} setTrackRecord={setTrackRecord} trackRecord={trackRecord} setLoading={setLoading} />
                <div style={{ display: "flex", width: "100%", gap: '20px', marginBottom: "22px" }}>
                    <ProspectExperience profileData={profileData} expDetails={expDetails} />
                    <ProspectEducation profileData={profileData} eduDetails={eduDetails} />
                </div>
                <ProspectOrganization  setLoading={setLoading} organizationData={organizationData} setOrganizationData={setOrganizationData} />
                <div className='newsfeed-plus-aipath'>
                    <ProspectNewsFeed profileData={profileData} />
                    <ProspectAiPath profileData={profileData} /> </div>
            </div>
            <ProspectRequestModal requestUpdateRecord={requestUpdateRecord} setRequestUpdateRecord={setRequestUpdateRecord} profileData={profileData} peopleId={peopleId} />
            <MessageModal open={messageModal} setOpen={setMessageModal} message={"No prospect details found. Please check on AI prospects page to find relevant information."} handleSubmit={handleProspectsPage} showBtn={false} />
        </Layout>
    )
}
export default Profile;
