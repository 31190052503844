import React, { useEffect, useState } from 'react';
import "../../Pages/DataEnrich/DataEnrich.css";
import downloadcv from "../../Assets/downloadcv.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import stargrey from "../../Assets/Stargrey.png";
import starRed from "../../Assets/starRed.svg"
import enrichdelete from "../../Assets/enrichdelete.png";
import enrichedit from "../../Assets/enrichedit.png";
import { APIUrlThree, APIUrlToken, GetUserId } from '../../Utils/Utils';
import axios from 'axios';
import Loader from '../Loader/Loader';
import * as XLSX from "xlsx";
import { toast } from 'react-toastify';
import MessageModal from '../MessageModal/MessageModal';
const DataEnrichTable = ({ enrichData, setLoading, getEnrichList }) => {
    const [deleteTrack, setDeleteTrack] = useState()
    const exportToExcel = (data, filename) => {
        const filteredData = data?.map(({ filename, enrichData, ...rest }) => rest);
        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `${filename}.xlsx`);
    };

    const handleRetrieveData = async (userFilename, category, download) => {
        setLoading(true);
        const option = {
            method: "GET",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "X-API-Key": APIUrlToken(),
            },
            url: `${APIUrlThree()}/retrieve-enriched-data-${category === "prospects" ? "prospects" : "organization"
                }/${userFilename}`,
        };

        try {
            const response = await axios(option);
            setLoading(false);

            if (response?.status === 200) {
                if (download) {
                    exportToExcel(response?.data?.data, "enriched_data");
                }
                return response?.data?.data;
            }
        } catch (error) {
            console.error('Error retrieving data:', error);
            setLoading(false);
        }
        return null;
    };

    const handleFavorite = async (item) => {
        setLoading(true);
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "X-API-Key": APIUrlToken()
            },
            url: `${APIUrlThree()}/v1/${item?.category === "prospects" ? "favorite_enriched_prospects" : "favorite_enriched_organizations"}?user_id=${Number(GetUserId())}&filename=${item.filename}`,
        };

        try {
            const response = await axios(option);
            toast.success("Enriched data added to favorites")
            getEnrichList()
            setLoading(false)
        } catch (error) {
            console.error("Error saving favorite prospect:", error);
        }
    };
    const handleDeleteDataEnrich = async () => {
        setLoading(true);
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "X-API-Key": APIUrlToken()
            },
            url: `${APIUrlThree()}/v1/delete_data_enrichment?enrichment_id=${deleteTrack?.id}`,
        };

        try {
            const response = await axios(option);
            setDeleteTrack()
            getEnrichList()
            setLoading(false)
        } catch (error) {
            console.error("Error saving favorite prospect:", error);
        }
    };

    return (
        <div className="table-enrich">

            <p>Data Enriched List</p>
            <TableContainer component={Paper} className="ai-leads-table-main">
                <Table aria-label="collapsible table" className="ai-leads-table">
                    <TableHead>
                        <TableRow className="table-row-ai-leads">
                            <TableCell className="score-row-table">Name</TableCell>
                            <TableCell className="score-row-table">Upload Date</TableCell>
                            <TableCell className="score-row-table">Status</TableCell>
                            <TableCell className="score-row-table">Add To Favorites</TableCell>
                            <TableCell className="score-row-table"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {enrichData.length > 0 ? (
                            enrichData?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell className="checkbox-row-table-inner">
                                        <div className="enrich-table-content">
                                            <p>{item.user_filename}</p>
                                        </div>
                                    </TableCell>
                                    <TableCell className="checkbox-row-table-inner">
                                        <div className="enrich-table-content">
                                            <p>  {new Date(item.requested_timestamp).toLocaleDateString('en-US', {
                                                month: 'long', day: 'numeric', year: 'numeric'
                                            })}</p>
                                        </div>
                                    </TableCell>

                                    <TableCell className="checkbox-row-table-inner">
                                        <div className="enrich-table-content">
                                            <p>{item.status}</p>
                                        </div>
                                    </TableCell>
                                    {
                                        item?.status === "completed" ?
                                            <TableCell>
                                                {
                                                    item?.favorite ?
                                                        <div className="kjhgf">
                                                            <img src={starRed} alt="Add to Favorites" />
                                                        </div> :
                                                        <div className="kjhgf">
                                                            <img src={stargrey} alt="Add to Favorites" onClick={() => { handleFavorite(item) }} />
                                                        </div>
                                                }
                                            </TableCell> : <TableCell> <div className="kjhgf">-</div></TableCell>
                                    }
                                    <TableCell align="right" className="three-dot-cell">
                                        {
                                            item?.status === "completed" ?
                                                <span className="threeDotIcon-table-profile" onClick={() => setDeleteTrack(item)}>
                                                    <img src={enrichdelete} alt="Delete" />
                                                </span>
                                                : null
                                        }
                                        <span className="threeDotIcon-table-profile" onClick={() => handleRetrieveData(item?.filename, item?.category, true)}>
                                            <a href={item.blob_url} download>
                                                <img src={downloadcv} alt="Download" />
                                            </a>
                                        </span>
                                        {/* <span className="threeDotIcon-table-profile">
                                        <img src={enrichedit} alt="Edit" />
                                    </span> */}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <p style={{ display: "flex", justifyContent: "center" }}>No Data Available</p>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <MessageModal
                open={deleteTrack?.id}
                setOpen={setDeleteTrack}
                message={`Are you sure that you want to delete this record?`}
                handleSubmit={handleDeleteDataEnrich}
            />
        </div>
    );
};
export default DataEnrichTable;