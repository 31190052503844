export const LOGIN = "/";
export const DASHBOARD = "/dashboard";
export const TWITTER_FEED = '/twitterfeed';
export const AI_DECISION_MAKER = '/aidecisionmakers';
export const AI_LEADS = "/aileads";
export const AI_PATH = '/aipath';
export const AI_PROFILE = '/aiprofile';
export const AI_PROFILE_FORM_TABLE = '/aiprofileformtable';
export const AI_PROSPECT_PROFILE = '/aiprospectprofile';
export const COMPANY_PROFILE_SCREEN = '/companyprofilescreen/:id/:tab'
export const CONTACT_US = '/contactus'
export const FAQ_SCREEN = '/faqscreen'
export const JOI_TRAINING = '/joitraning'
export const COMING_SOON = '/comingSoon'
export const LOGIN_CALLBACK = '/login/callback'
export const PDF_SAMPLE = '/pdfSample'
export const DATA_ENRICH='/dataenrich'
export const TRACKING='/tracking'
export const BRE='/BusinessRE'
export const LEGISLATIVE_MAP='/legislativemap'
export const ARCHIVED='/archived'
export const FAVORITES='/favorites'
export const PROFILE='/Profile/:id'
export const ERROR_SCREEN='/ErrorScreen'
export const MAINTENANCE='/Maintainance'

//videoLinks
export const COMPETITIVE_INTEL_VIDEO="https://player.vimeo.com/video/1029364639?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
export const PERSONALIZED_DASHBOARD_VIDEO="https://player.vimeo.com/video/1029387379?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
export const ARCHIVE_VIDEO="https://player.vimeo.com/video/1027029378?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
export const AI_PROFILE_VIDEO="https://player.vimeo.com/video/1029332944?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
export const FAVORITES_VIDEO="https://player.vimeo.com/video/1027017531?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
export const PUSH_TO_JOSF_VIDEO="https://player.vimeo.com/video/1029398107?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
export const AI_PATH_VIDEO="https://player.vimeo.com/video/1029369634?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
export const DATA_ENRICH_VIDEO="https://player.vimeo.com/video/1029385613?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
export const PROSPECT_DETAILS_VIDEO="https://player.vimeo.com/video/1029335844?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
export const HELP_CENTER_VIDEO="https://player.vimeo.com/video/1027440107?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
export const CRIE_VIDEO="https://player.vimeo.com/video/1029385419?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
export const UNIVERSAL_SEARCH_VIDEO="https://player.vimeo.com/video/1027415496?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"

export const VIDEO_LINKS = {    
    COMPETITIVE_INTEL_VIDEO: "https://player.vimeo.com/video/1029364639?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    PERSONALIZED_DASHBOARD_VIDEO: "https://player.vimeo.com/video/1029387379?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    ARCHIVE_VIDEO: "https://player.vimeo.com/video/1027029378?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    AI_PROFILE_VIDEO: "https://player.vimeo.com/video/1029332944?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    FAVORITES_VIDEO: "https://player.vimeo.com/video/1027017531?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    PUSH_TO_JOSF_VIDEO: "https://player.vimeo.com/video/1029398107?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    AI_PATH_VIDEO: "https://player.vimeo.com/video/1029369634?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    DATA_ENRICH_VIDEO: "https://player.vimeo.com/video/1029385613?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    PROSPECT_DETAILS_VIDEO: "https://player.vimeo.com/video/1029335844?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    HELP_CENTER_VIDEO: "https://player.vimeo.com/video/1027440107?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    CRIE_VIDEO: "https://player.vimeo.com/video/1029385419?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    UNIVERSAL_SEARCH_VIDEO: "https://player.vimeo.com/video/1027415496?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
};
// jobAidLink
export const AI_PATH_JOB_AID="https://app.box.com/shared/static/3wgkdx52ic4b8ah8m7o2ej5ggv9nuz61.pdf"
export const AI_PROFILE_JOB_AID="https://app.box.com/shared/static/5139nu2kpapsuewmabyn7m9c7d3mp5oa.pdf"
export const ARCHIVING_JOB_AID="https://app.box.com/shared/static/ffd32l5b8gyncmtyrnxkixwywn5k9trc.pdf"
export const COMPETITIVE_INTEL_JOB_AID="https://app.box.com/shared/static/plmdfewkm6o4gb647tgu0lectv4138vg.pdf"
export const CRIE_JOB_AID="https://app.box.com/shared/static/r7s4ydmyb1wcvh6g5pak669sy585thru.pdf"
export const DATA_ENRICH_JOB_AID="https://app.box.com/shared/static/9wng7dzzljh0c0unublyr5xxtqu6gke2.pdf"
export const FAVORITES_JOB_AID="https://app.box.com/shared/static/e6x6yct9h91g8umg44k4wbqxvfbuxlq5.pdf"
export const HELP_CENTER_JOB_AID="https://app.box.com/shared/static/0ahbq6step6pg7t2rohp965u2ydzog24.pdf"
export const PERSONALIZES_DASHBOARD_JOB_AID="https://app.box.com/shared/static/duajyy0ydjwnpslh5f5l6ic3jdluvynp.pdf"
export const PROSPECT_DETAILS_JOB_AID="https://app.box.com/shared/static/5c6jnywe0adlrll5o1lsq9k2wk6gwsgg.pdf"
export const PUSH_TO_SALESFORCE_JOB_AID="https://app.box.com/shared/static/uofj9sh2wvq6dn110moycy9l66qcqyew.pdf"
export const UNIVERSAL_SEARCH_JOB_AID="https://app.box.com/shared/static/64bs13ty0z5hqd11lwrsyiv9uxt41x4g.pdf"
