import React from 'react'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteRow from "../../../Assets/Delete.svg";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import downloadRow from "../../../Assets/downloadcv.png";
import moment from 'moment';
const SavedPath = ({ savedPaths,downloadPath,deleteSavedAiPath,editAiPath,viewAiPath }) => {
  return (
    <div>
      <h3 style={{ margin: "10px 0px 6px" }}>Saved Paths</h3>
      <TableContainer component={Paper} className="leads-profile-table-main">
        {
          savedPaths?.length ? 
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="leads-profilethead">
            <TableRow>
              <TableCell className="cell-profile-name">Path Name</TableCell>
              <TableCell align="left" className="cell-created-on">
                Created On
              </TableCell>
              <TableCell align="left" className="cell-status"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="leads-profile-tablebody">
            {
              savedPaths?.map((item, index) => (
                <TableRow
                  className="table-row-leadsprofile"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    <p>{item?.path_name}</p>
                  </TableCell>
                  <TableCell
                    align="left"
                  >
                    <p>{moment(item?.created_dated).format("LL")}</p>
                  </TableCell>
                  <TableCell className="three-dot-cell" style={{ justifyContent: "flex-end" }}>
                    <span className="threeDotIcon-table-profile" onClick={()=>viewAiPath(item)} >
                     <VisibilityIcon/>
                    </span>
                    <span className="threeDotIcon-table-profile" onClick={()=>downloadPath(item)} >
                      <img src={downloadRow} alt="" />
                    </span>
                    <span className="threeDotIcon-table-profile" onClick={()=>editAiPath(item)}  >
                      <EditOutlinedIcon />
                    </span>
                    <span className="threeDotIcon-table-profile"onClick={()=>deleteSavedAiPath(item)}>
                      <img src={DeleteRow} alt="" />
                    </span>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table> : <p style={{padding:"40px",justifyContent:"center",display:"flex"}}>No records found.</p>
        }
      </TableContainer>
    </div>
  )
}
export default SavedPath
