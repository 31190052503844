import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import ProspectTracking from "../../Components/Tracking/ProspectTracking";
import OrganizationTracking from "../../Components/Tracking/OrganizationTracking";
import "./Tracking.css";
import { InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import reload from "../../Assets/reloardform.svg";
import { APIUrlThree, APIUrlToken, GetUserId } from "../../Utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import CloseIcon from "@mui/icons-material/Close";
import MessageModal from "../../Components/MessageModal/MessageModal";
import TrainingModal from "../../Components/Training/TrainingModal";
import video from "../../../src/Assets/video.svg"
import videodash from "../../../src/Assets/play-button.png"
import { COMPETITIVE_INTEL_VIDEO, PERSONALIZED_DASHBOARD_VIDEO } from "../../Utils/Constants";
const Tracking = () => {
  const [prospectData, setProspectData] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);
  const [headerSearchData, setheaderSearchData] = useState("");
  const [showSearchdata, setshowSearchdata] = React.useState(false);
  const [responseData, setResponseData] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [deleteTrack, setDeleteTrack] = useState();
  const [selectedValue, setSelectedValue] = useState({});
  const [videoModal, setVideoModal] = React.useState(false);
  const [overviewVideoModal, setOverviewVideoModal] = React.useState(false);
  const [videoLink, setVideoLink] = React.useState("")
  const handleCloseOverview = () => {
    setOverviewVideoModal(false)
  }
  const handleVideoModal = (item) => {
    setOverviewVideoModal(true)
    if (item == 1) {
      setVideoLink(COMPETITIVE_INTEL_VIDEO)
    }
  }
  const handleTrackingdata = () => {
    setLoading(true);
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      url: `${APIUrlThree()}/v1/get_tracking?user_id=${Number(GetUserId())}`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        const data = e?.data?.data;
        setProspectData(data?.filter((i) => i?.person_id !== null));
        setOrganizationData(data?.filter((i) => i?.person_id === null));
      })
      .catch((err) => { });
  };
  const handleRefreshTrackingdata = (item) => {
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      url: `${APIUrlThree()}/v1/refresh_tracking?request_id=${item?.request_id}`,
    };
    axios(option)
      .then((e) => {
        toast.success(`${item?.person_id == null ? "Organization " : "Prospect "}refreshed.`);
        handleTrackingdata();
      })
      .catch((err) => { });
  };
  useEffect(() => {
    handleTrackingdata();
  }, []);
  const emtySearchdata = () => {
    setheaderSearchData("");
  };
  React.useEffect(() => {
    const handleClick = () => {
      setshowSearchdata(false);
      setheaderSearchData("");
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  const handlesearch = () => {
    setLoading(true);
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      url: `${APIUrlThree()}/v1/common_search?query=${headerSearchData}&page=1&limit=100`,
    };
    axios(option)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setshowSearchdata(true);
          const orgdata = response.data.organizations?.map((i) => ({
            ...i,
            type: "org",
          }));
          const peopleData = response.data.people?.map((i) => ({
            ...i,
            type: "people",
          }));
          const allData = [{ id: null, name: "Prospects" }, ...peopleData, { id: null, name: "Organizations" }, ...orgdata]
          setResponseData(allData);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };
  React.useEffect(() => {
    let timer;
    if (headerSearchData?.length > 2) {
      timer = setTimeout(() => {
        handlesearch();
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [headerSearchData]);
  const handleSaveTrack = (item) => {
    setSelectedValue(item);
    setMessageModal(true);
  };
  const handleTrackRecords = () => {
    if (selectedValue?.type === "org") {
      handleSaveOrganizationTrack(selectedValue);
    } else {
      handleSavePeopleTrack(selectedValue);
    }
  };
  const handleSaveOrganizationTrack = (item) => {
    setLoading(true);
    const data = [item?.org_id];
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      data: data,
      url: `${APIUrlThree()}/v1/save_tracking_organization?user_id=${Number(
        GetUserId()
      )}`,
    };
    axios(option)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setshowSearchdata(false);
          toast.success("Tracking Added");
          handleTrackingdata();
          setResponseData([]);
          setMessageModal(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };
  const handleSavePeopleTrack = (item) => {
    setLoading(true);
    const data = [item?.id];
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      data: data,
      url: `${APIUrlThree()}/v1/save_tracking_prospect?user_id=${Number(
        GetUserId()
      )}`,
    };
    axios(option)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setshowSearchdata(false);
          toast.success("Tracking Added");
          handleTrackingdata();
          setResponseData([]);
          setMessageModal(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };
  const handleDeleteTracking = () => {
    setLoading(true);
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      url: `${APIUrlThree()}/v1/delete_tracking?request_id=${deleteTrack?.request_id}`,
    };
    axios(option)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          toast.success("Tracking Record Deleted Successfully");
          setDeleteTrack()
          handleTrackingdata();
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };
  return (
    <Layout>
      {loading ? <Loader /> : null}
      <div className="table-tracking">
        <div className="video-space">
          <h1>Search for Prospects or Organizations to Track</h1>
          <div className="video_container" onClick={() => setVideoModal(!videoModal)}>

            <img src={video} alt="video" className="videoIcon" />
            {videoModal && (
              <div className="bell-icon-box-video">

                <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(1)}>
                  <div className="bell-icon-outer-video">
                    {/* <div className="bell-icon-container-inner"> */}
                    <img src={videodash} alt="logo" className="video_img_drop" />
                    {/* </div> */}
                    <div className="bellicon-text">
                      <p className="bellicon-p-search">Competitive Intel</p>
                    </div>
                  </div>
                  <div className="bellicon-separator"></div>
                </div>

              </div>
            )}
          </div>
        </div>
        <p>
          Track a prospect or organization to monitor news and show connection
          paths
        </p>
        <p className="tracking_label">Search by Organization or Prospect Name</p>
        <Paper
          className="track-full-searchbar"
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
          }}
        >
          <SearchIcon className="searchicon-in-header" />
          <InputBase
            value={headerSearchData}
            onChange={(e) => {
              const inputValue = e.target.value;
              setheaderSearchData(inputValue);
            }}
            className="search-inner-text"
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search by Organization or Prospect Name"
            inputProps={{ "aria-label": "Search google maps" }}
            autocomplete="off"
          />
          <div onClick={emtySearchdata} className="cross-icon-prospect-search">
            {headerSearchData && <CloseIcon className="showicons-search" />}
          </div>
          {showSearchdata && (
            <div className="Autocompletedropdown-track">
              {responseData?.length > 0 ? (
                responseData?.map((item) => (
                  <>
                    {
                      item?.id ? <div className="outterAutocompletedropdown" key={item.id}>
                        <div
                          onClick={() => handleSaveTrack(item)}
                          className="useralldata"
                        >
                          {[
                            item?.name,
                            item?.city,
                            item?.country,
                            item?.website_url,
                            item?.organization,
                          ]
                            .filter(Boolean)
                            .join(", ")}
                        </div>
                        <div className="separatorline"></div>
                      </div> : <div className='outterAutocompletedropdown2' key={item.id}>
                        <div className='useralldata'>
                          {item?.name}
                        </div>
                        <div className='separatorline'></div>
                      </div>
                    }
                  </>
                ))
              ) : (
                <div className="useralldata">Not Available</div>
              )}
            </div>
          )}
        </Paper>
        <p className="track_inner_p">
          Tracking will automatically expire after 60 days. You can renew tracking for an additional 60 days by clicking the
          <span>
            <img src={reload} alt="reload" />
          </span>
          renew button in your list below. The 60-day period will restart from the day you click the renew button.
        </p>
        {/* <p className="track_inner_p">
          Tracking will automatically expire after 60 days. You can renew
          tracking for an additional 60 days by clicking
          <span>
            <img src={reload} alt="reload" />
          </span>
          button in your list below.
        </p> */}
      </div>
      <MessageModal
        open={messageModal}
        setOpen={setMessageModal}
        message={`Are you sure that you want to add this ${selectedValue?.type === "org" ? "organization" : "prospect"
          } to tracking?`}
        handleSubmit={handleTrackRecords}
      />
      <MessageModal
        open={deleteTrack?.request_id}
        setOpen={setDeleteTrack}
        message={`Are you sure that you want to stop tracking this record?`}
        handleSubmit={handleDeleteTracking}
      />
      <ProspectTracking
        prospectData={prospectData}
        handleRefreshTrackingdata={handleRefreshTrackingdata}
        handleDeleteTracking={setDeleteTrack}
      />
      <OrganizationTracking
        organizationData={organizationData}
        handleRefreshTrackingdata={handleRefreshTrackingdata}
        handleDeleteTracking={setDeleteTrack}
        setLoading={setLoading}
      />
      <TrainingModal overviewVideoModal={overviewVideoModal} handleCloseOverview={handleCloseOverview} videoLink={videoLink} />

    </Layout>
  );
};
export default Tracking;