import * as React from "react";
import graphup from "../../Assets/Graphup.svg";
import GraphDown from "../../Assets/GraphDown.svg";
import "../../Components/InfoDashboard/InfoDashboard.css";
import axios from "axios";
import moment from "moment";
import { APIUrlOne, APIUrlThree, APIUrlToken, GetOktaAuthData, setStatsData } from "../../Utils/Utils";
import video from "../../../src/Assets/video.svg"
import videodash from "../../../src/Assets/play-button.png"
import TrainingModal from "../Training/TrainingModal";
import { COMPETITIVE_INTEL_VIDEO, PERSONALIZED_DASHBOARD_VIDEO } from "../../Utils/Constants";

export default function AiLeadsTable() {
  const [cardsData, setCardsData] = React.useState();
  const userData = GetOktaAuthData();
  const userProfileData = userData?.user?.profile;
  const [videoModal, setVideoModal] = React.useState(false);
  const [overviewVideoModal, setOverviewVideoModal] = React.useState(false);
  const [videoLink, setVideoLink] = React.useState("")
  const handleCloseOverview = () => {
    setOverviewVideoModal(false)
  }
  const handleVideoModal = (item) => {
    setOverviewVideoModal(true)
    if (item == 1) {
      setVideoLink(COMPETITIVE_INTEL_VIDEO)
    } else if (item == 2) {
      setVideoLink(PERSONALIZED_DASHBOARD_VIDEO)
    }
  }
  const dashboardCards = () => {
    const today = new Date();
    const formattedDate = moment(today).format("YYYY-MM-DD");
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/get_stats?date=${formattedDate}`,
    };
    axios(option)
      .then((e) => {
        const data = e?.data?.data[0];
        setCardsData(data);
        if (data) {
          setStatsData('statsData', data)
        }

      })
      .catch(() => {
      });
  };
  React.useEffect(() => {
    dashboardCards();
  }, []);
  return (
    <React.Fragment>
      <div className="first-box">
        <div className="inner-main-container-first-box">
          <div className="agent-name">
            <div className="video-space">
              <p className="hi-dash-name">Hi, {`${userProfileData?.firstName} ${userProfileData?.lastName}`}</p>
              <div className="video_container" onClick={() => setVideoModal(!videoModal)}>

                <img src={video} alt="video" className="videoIcon" />
                {videoModal && (
                  <div className="bell-icon-box-video">

                    <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(1)}>
                      <div className="bell-icon-outer-video">
                        {/* <div className="bell-icon-container-inner"> */}
                        <img src={videodash} alt="logo" className="video_img_drop" />
                        {/* </div> */}
                        <div className="bellicon-text">
                          <p className="bellicon-p-search">Competitive Intel</p>
                        </div>
                      </div>
                      <div className="bellicon-separator"></div>
                    </div>
                    <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(2)}>
                      <div className="bell-icon-outer-video">
                        {/* <div className="bell-icon-container-inner"> */}
                        <img src={videodash} alt="logo" className="video_img_drop" />
                        {/* </div> */}
                        <div className="bellicon-text">
                          <p className="bellicon-p-search">Personalized Dashboard</p>
                        </div>
                      </div>
                      <div className="bellicon-separator"></div>
                    </div>

                  </div>
                )}
              </div>
            </div>

            <p className="past-information">Here are your 30 day insights</p>
          </div>

          <div className="outer-cards-container">
            <React.Fragment>
              <div className="inner-cards-container">
                <div className="card-one-Total-AI-leads">
                  <p className="card-heading-main">Total Organizations</p>
                  <p className="number-in-card">{cardsData?.org_count ? cardsData?.org_count?.toLocaleString() : '-'}</p>
                  {
                    cardsData?.org_count_delta > 0 ?
                      <div className="graphup-and-line">

                        <img src={graphup} alt="" />
                        <p className="graph-ratio"> {cardsData?.org_count_delta}</p>
                        <p className="with-graph-ratio-and-info">
                          from last month
                        </p>
                      </div> :
                      cardsData?.org_count_delta < 0 ?
                        <div className="graphup-and-line">
                          <img src={GraphDown} alt="" />
                          <p className="graph-ratio-down">{cardsData?.org_count_delta}</p>
                          <p className="with-graph-ratio-and-info">
                            from last month
                          </p>
                        </div> :
                        cardsData?.org_count_delta === 0 ?
                          <div className="graphup-and-line">
                            <p className="with-graph-ratio-and-info">
                              Data not available
                            </p>
                          </div> : null
                  }

                </div>
                <div className="card-one-Total-AI-leads">
                  <p className="card-heading-main">Total People</p>
                  <p className="number-in-card">{cardsData?.people_count ? cardsData?.people_count?.toLocaleString() : '-'}</p>
                  {
                    cardsData?.people_count_delta > 0 ?
                      <div className="graphup-and-line">

                        <img src={graphup} alt="" />
                        <p className="graph-ratio"> {cardsData?.people_count_delta}</p>
                        <p className="with-graph-ratio-and-info">
                          from last month
                        </p>
                      </div> :
                      cardsData?.people_count_delta < 0 ?
                        <div className="graphup-and-line">
                          <img src={GraphDown} alt="" />
                          <p className="graph-ratio-down">{cardsData?.people_count_delta}</p>
                          <p className="with-graph-ratio-and-info">
                            from last month
                          </p>
                        </div> :
                        cardsData?.people_count_delta === 0 ?
                          <div className="graphup-and-line">
                            <p className="with-graph-ratio-and-info">
                              Data not available
                            </p>
                          </div> : null
                  }
                </div>
                <div className="card-one-Total-AI-leads">
                  <p className="card-heading-main">AI Leads</p>
                  <p className="number-in-card">{cardsData?.generated_leads ? cardsData?.generated_leads?.toLocaleString() : '-'}</p>
                  {
                    cardsData?.generated_leads_delta > 0 ?
                      <div className="graphup-and-line">

                        <img src={graphup} alt="" />
                        <p className="graph-ratio"> {cardsData?.generated_leads_delta}</p>
                        <p className="with-graph-ratio-and-info">
                          from last month
                        </p>
                      </div> :
                      cardsData?.generated_leads_delta < 0 ?
                        <div className="graphup-and-line">
                          <img src={GraphDown} alt="" />
                          <p className="graph-ratio-down">{cardsData?.generated_leads_delta}</p>
                          <p className="with-graph-ratio-and-info">
                            from last month
                          </p>
                        </div> :
                        cardsData?.generated_leads_delta === 0 ?
                          <div className="graphup-and-line">
                            <p className="with-graph-ratio-and-info">
                              Data not available
                            </p>
                          </div> : null
                  }
                </div>
                <div className="card-one-Total-AI-leads">
                  <p className="card-heading-main">AI Prospects</p>
                  <p className="number-in-card">{cardsData?.decisionmaker_count ? cardsData?.decisionmaker_count?.toLocaleString() : '-'}</p>
                  {
                    cardsData?.decisionmaker_count_delta > 0 ?
                      <div className="graphup-and-line">

                        <img src={graphup} alt="" />
                        <p className="graph-ratio"> {cardsData?.decisionmaker_count_delta}</p>
                        <p className="with-graph-ratio-and-info">
                          from last month
                        </p>
                      </div> :
                      cardsData?.decisionmaker_count_delta < 0 ?
                        <div className="graphup-and-line">
                          <img src={GraphDown} alt="" />
                          <p className="graph-ratio-down">{cardsData?.decisionmaker_count_delta}</p>
                          <p className="with-graph-ratio-and-info">
                            from last month
                          </p>
                        </div> :
                        cardsData?.decisionmaker_count_delta === 0 ?
                          <div className="graphup-and-line">
                            <p className="with-graph-ratio-and-info">
                              Data not available
                            </p>
                          </div> : null
                  }
                </div>
                <div className="card-five-Total-AI-leads">
                  <p className="card-heading-main">
                    Suspects pushed to Salesforce
                  </p>
                  <p className="number-in-card-last">
                    {cardsData?.suspect_to_sf !== undefined && cardsData?.suspect_to_sf !== null ? (cardsData?.suspect_to_sf > 0 ? cardsData?.suspect_to_sf.toLocaleString() : cardsData?.suspect_to_sf) : "-"}
                  </p>
                  {
                    cardsData?.suspect_to_sf_delta > 0 ?
                      <div className="graphup-and-line">

                        <img src={graphup} alt="" />
                        <p className="graph-ratio"> {cardsData?.suspect_to_sf_delta}</p>
                        <p className="with-graph-ratio-and-info">
                          from last month
                        </p>
                      </div> :
                      cardsData?.suspect_to_sf_delta < 0 ?
                        <div className="graphup-and-line">
                          <img src={GraphDown} alt="" />
                          <p className="graph-ratio-down">{cardsData?.suspect_to_sf_delta}</p>
                          <p className="with-graph-ratio-and-info">
                            from last month
                          </p>
                        </div> :
                        cardsData?.suspect_to_sf_delta === 0 ?
                          <div className="graphup-and-line">
                            <p className="with-graph-ratio-and-info">
                              Data not available
                            </p>
                          </div> : null
                  }
                </div>
              </div>
            </React.Fragment>
          </div>
        </div>
      </div >
      <TrainingModal overviewVideoModal={overviewVideoModal} handleCloseOverview={handleCloseOverview} videoLink={videoLink} />
    </React.Fragment >

  );
}