import React, { useEffect, useRef, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Modal,
  Box,
  Button,
  Typography,
  Stack,
  Paper,
  InputBase,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../CompetitiveIntelligence/CompetitiveIntelligence.css";
import HeaderSearchUniversal from "../UniversalSearch/Index";
import { APIUrlThree, APIUrlToken, GetUserId } from "../../Utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import halflines from "../../Assets/halflines.svg";
import search from "../../Assets/search.svg";
import Loader from "../Loader/Loader";
import print from "../../Assets/print.svg";
import download from "../../Assets/downloadcv.png";
import { useReactToPrint } from "react-to-print";
import html2pdf from "html2pdf.js";
import { ClearIcon } from "@mui/x-date-pickers";
import LabelInput from "../LabelInputFields/Index";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import PeopleSearch from "../UniversalSearch/PeopleSearch";
import OrganizationSearch from "../UniversalSearch/OrganizationSearch";

const CompetitiveIntelligence = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [cities, setCities] = useState();
  const [value, setValue] = useState(1);
  const boxOneRef = useRef();
  const boxTwoRef = useRef();
  const boxThreeRef = useRef();
  const [loading, setLoading] = React.useState(false);
  const [feedBoxOne, setFeedBoxOne] = React.useState([]);

  const [feedBoxTwo, setFeedBoxTwo] = React.useState([]);
  const [feedBoxThree, setFeedBoxThree] = React.useState([]);
  const [openSearchOne, setOpenSearchOne] = useState(false);
  const [openSearchTwo, setOpenSearchTwo] = useState(false);
  const [openSearchThree, setOpenSearchThree] = useState(false);
  const [openTagModal, setOpenTagModal] = useState();
  const [dateOrderOne, setDateOrderOne] = React.useState("asc");
  const [dateOrderTwo, setDateOrderTwo] = React.useState("asc");
  const [dateOrderThree, setDateOrderThree] = React.useState("asc");
  const [saveSearchOne, setSaveSearchOne] = useState([""]);
  const [saveSearchTwo, setSaveSearchTwo] = useState([""]);
  const [saveSearchThree, setSaveSearchThree] = useState([""]);
  const [tagName, setTagName] = useState("");
  const [common, setCommon] = useState(false);
  const [newsTags, setNewsTags] = useState();
  const [modalSearchPeopleData, setModalSearchPeopleData] = React.useState("");
  const [modalSearchOrgData, setModalSearchOrgData] = React.useState("");
  const [showSearchPeopleData, setShowSearchPeopleData] = React.useState(false);
  const [showSearchOrgData, setShowSearchOrgData] = React.useState(false);
  const [responsePeopleData, setResponsePeopleData] = React.useState([]);
  const [responseOrgData, setResponseOrgData] = React.useState([]);
  const [apiHitPeople, setApiHitPeople] = React.useState(true);
  const [apiHitOrg, setApiHitOrg] = React.useState(true);
  const [selectedItem, setSelectedItem] = React.useState();
  const [skipOne, setSkipOne] = useState(0);
  const [skipTwo, setSkipTwo] = useState(0);
  const [skipThree, setSkipThree] = useState(0);
  const [loadOne, setLoadOne] = useState(false);
  const [loadTwo, setLoadTwo] = useState(false);
  const [loadThree, setLoadThree] = useState(false);
  const [searchOneApi, setSearchOneApi] = useState(false);
  const [searchTwoApi, setSearchTwoApi] = useState(false);
  const [searchThreeApi, setSearchThreeApi] = useState(false);
  const [event, setEvent] = useState([]);

  const [expandedSummaryOne, setExpandedSummaryOne] = useState({});

  const toggleSummaryOne = (index) => {
    setExpandedSummaryOne((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const [expandedSummaryTwo, setExpandedSummaryTwo] = useState({});

  const toggleSummaryTwo = (index) => {
    setExpandedSummaryTwo((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const [expandedSummaryThree, setExpandedSummaryThree] = useState({});

  const toggleSummaryThree = (index) => {
    setExpandedSummaryThree((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: "8px",
  };

  const [selectedLocation, setSelectedLocation] = React.useState(null);
  const [selectedLocationEvent, setSelectedLocationEvent] = React.useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchTextEvent, setSearchTextEvent] = useState("");
  const filteredLocations = cities?.filter((location) =>
    location.toLowerCase().includes(searchText.toLowerCase())
  );

  const filteredEvent = newsTags?.filter((location) =>
    location.toLowerCase().includes(searchTextEvent.toLowerCase())
  );
  const handleEventClick = (location) => {
    setEvent((prevEvents) => {
      if (prevEvents.includes(location)) {
        return prevEvents.filter((item) => item !== location);
      } else {
        return [...prevEvents, location];
      }
    });
  };



  const handleOpen = (i) => {
    setValue(i);
    setModalOpen(true);
    setCommon(true);
  };
  const handleClose = () => {
    setModalOpen(false);
    setCommon(false);
    setValueOrganization(false);
    setSelectedItem();
    setSelectedLocation();
    setSelectedLocationEvent();
    setEvent("")
    setValueNews(false);
    setValueEvent(false);
    setValuePeople(false);
    setModalSearchOrgData("");
    setModalSearchPeopleData("");
    setSearchText("");
    setSearchTextEvent("");
  };
  const [valueNews, setValueNews] = useState(false);
  const [valueEvent, setValueEvent] = useState(false);

  const handleOpenNews = (i) => {
    setValueNews(true);
    setCommon(false);
  };

  const handleOpenEvent = (i) => {
    setValueEvent(true);
    setCommon(false);
  };

  const handleCloseNews = () => {
    handleClose();
  };
  const handleCloseEvent = () => {
    setEvent("")
    handleClose();
  };
  const handleBackNews = () => {
    setValueNews(false);
    setCommon(true);
    setSelectedItem();
    setSelectedLocation();
    setModalSearchOrgData("");
    setModalSearchPeopleData("");
    setSearchText("");
  };
  const handleBackEvent = () => {
    setValueEvent(false);
    setCommon(true);
    setSelectedItem();
    setSelectedLocationEvent();
    setModalSearchOrgData("");
    setModalSearchPeopleData("");
    setSearchTextEvent("");
    setEvent("")
  };

  const [valueOrganization, setValueOrganization] = useState(false);
  const handleOpenOrganization = () => {
    setValueOrganization(true);
    setCommon(false);
  };
  const handleCloseOrg = () => {
    handleClose();
  };
  const handleBackorg = () => {
    setValueOrganization(false);
    setCommon(true);
    setSelectedItem();
    setSelectedLocation();
    setSelectedLocationEvent();
    setModalSearchOrgData("");
    setModalSearchPeopleData("");
    setSearchText("");
    setSearchTextEvent("");
    setEvent("");
  };

  const [valuePeople, setValuePeople] = useState(false);
  const handleOpenPeople = () => {
    setValuePeople(true);
    setCommon(false);
  };
  const handleBackPeople = () => {
    setValuePeople(false);
    setCommon(true);
    setSelectedItem();
    setSelectedLocation();
    setSelectedLocationEvent();
    setModalSearchOrgData("");
    setModalSearchPeopleData("");
    setSearchText("");
    setSearchTextEvent("");
  };
  const handleCancelPeople = () => {
    handleClose();
  };
  const handleSearchOrg = React.useCallback(() => {
    if (!modalSearchOrgData || modalSearchOrgData?.length <= 2) return;
    setLoading(true);

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      url: `${APIUrlThree()}/v1/org_search`,
      data: JSON.stringify({
        org_name: modalSearchOrgData,
        page: 1,
        limit: 100,
      }),
    };

    axios(options)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          const cleanedData = response.data.map((org) => ({
            ...org,
            org_name: org.org_name, //.replace(/\s*\(http.*\)$/, ""),
          }));

          setShowSearchOrgData(true);
          setResponseOrgData(cleanedData);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message || "An error occurred.");
      });
  }, [modalSearchOrgData, setLoading]);


  React.useEffect(() => {
    if (apiHitOrg === true) {
      const timer = setTimeout(() => {
        handleSearchOrg();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [modalSearchOrgData]);

  const handleSearchPeople = React.useCallback(() => {
    if (!modalSearchPeopleData || modalSearchPeopleData?.length <= 2) return;
    setLoading(true);

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      url: `${APIUrlThree()}/v1/people_search`,
      data: JSON.stringify({
        people_name: modalSearchPeopleData,
        page: 1,
        limit: 100,
      }),
    };

    axios(options)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setShowSearchPeopleData(true);
          const data = response.data.data.map((item) => ({
            ...item,
            first_name: item?.first_name
              ? item.first_name
                .split(" ")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ")
              : "",
            last_name: item?.last_name
              ? item.last_name
                .split(" ")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ")
              : "",
            primary_organization: item?.primary_organization
              ? item.primary_organization
                .split(" ")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ")
              : "",
          }));
          setResponsePeopleData(data);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message || "An error occurred.");
      });
  }, [modalSearchPeopleData, setLoading]);

  React.useEffect(() => {
    if (apiHitPeople === true) {
      const timer = setTimeout(() => {
        handleSearchPeople();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [modalSearchPeopleData]);

  const getNews = (item, type) => {
    const skipVal =
      item?.content_box_id === 1
        ? skipOne
        : item?.content_box_id === 2
          ? skipTwo
          : skipThree;
    const sorrt =
      item?.content_box_id === 1
        ? dateOrderOne
        : item?.content_box_id === 2
          ? dateOrderTwo
          : dateOrderThree;
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      // url: `${APIUrlThree()}/v1/get_news?people_id=1756`,
      url: `${APIUrlThree()}/v1/get_news?sort_order=${sorrt}${item?.org_id !== null ? "&org_id=" : ""}${item?.org_id !== null ? item.org_id : ''}${item?.people_id !== 0 ? "&people_id=" : ""}${item?.people_id !== 0 ? item.people_id : ''}${item?.location?.length ? "&location=" : ""}${item?.location?.length ? item.location : ''}${item?.tags?.length ? "&tags=" : ""}${item?.tags?.length ? item?.tags : ""}&limit=10&skip=${type === "date" ? 0 : skipVal}`,
    };
    axios(options)
      .then((e) => {
        if (item?.content_box_id === 1) {
          if (e?.data?.length < 10) {
            setLoadOne(false);
          } else {
            setLoadOne(true);
          }
          const newData = feedBoxOne?.data || [];
          if (type == "tag" || type == "date") {
            setFeedBoxOne({
              data: [...e?.data],
              boxData: item,
            });
          } else {
            setFeedBoxOne({
              data: [...newData, ...e?.data],
              boxData: item,
            });
          }
        } else if (item?.content_box_id === 2) {
          if (e?.data?.length < 10) {
            setLoadTwo(false);
          } else {
            setLoadTwo(true);
          }
          const newData = feedBoxTwo?.data || [];
          if (type == "tag" || type == "date") {
            setFeedBoxTwo({
              data: [...e?.data],
              boxData: item,
            });
          } else {
            setFeedBoxTwo({
              data: [...newData, ...e?.data],
              boxData: item,
            });
          }
        } else if (item?.content_box_id === 3) {
          if (e?.data?.length < 10) {
            setLoadThree(false);
          } else {
            setLoadThree(true);
          }
          const newData = feedBoxThree?.data || [];
          if (type == "tag" || type == "date") {
            setFeedBoxThree({
              data: [...e?.data],
              boxData: item,
            });
          } else {
            setFeedBoxThree({
              data: [...newData, ...e?.data],
              boxData: item,
            });
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  useEffect(() => {
    if (skipOne > 0 && !searchOneApi) {
      getNews(feedBoxOne?.boxData);
    } else if (skipOne > 0 && searchOneApi) {
      handleSearchSave(1);
    }
  }, [skipOne]);
  useEffect(() => {
    if (dateOrderOne && feedBoxOne?.boxData?.id && !searchOneApi) {
      setSkipOne(0)
      getNews(feedBoxOne?.boxData, "date");
    }
  }, [dateOrderOne]);

  useEffect(() => {
    if (skipTwo > 0 && !searchTwoApi) {
      getNews(feedBoxTwo?.boxData);
    } else if (skipTwo > 0 && searchTwoApi) {
      handleSearchSave(2);
    }
  }, [skipTwo]);
  useEffect(() => {
    if (dateOrderTwo && feedBoxTwo?.boxData?.id && !searchTwoApi) {
      setSkipTwo(0)
      getNews(feedBoxTwo?.boxData, "date");
    }
  }, [dateOrderTwo]);
  useEffect(() => {
    if (skipThree > 0 && !searchThreeApi) {
      getNews(feedBoxThree?.boxData);
    } else if (skipThree > 0 && searchThreeApi) {
      handleSearchSave(3);
    }
  }, [skipThree]);
  useEffect(() => {
    if (dateOrderThree && feedBoxThree?.boxData?.id && !searchThreeApi) {
      setSkipThree(0)
      getNews(feedBoxThree?.boxData, "date");
    }
  }, [dateOrderThree]);

  const validate = () => {
    if (!modalSearchOrgData?.length && valueOrganization) {
      toast.error("Please add Organization");
      return false;
    }

    if (!modalSearchPeopleData?.length && valuePeople) {
      toast.error("Please add People");
      return false;
    }
    if (!event?.length && valueEvent) {
      toast.error("Please Select News/Events/Legislation");
      return false;
    }
    if (!searchText?.length && valueNews) {
      toast.error("Please Select Competitor State ");
      return false;
    }
    return true;
  };

  const saveContentFeed = () => {
    if (!validate()) return;
    const data = {
      id: 0,
      user_id: GetUserId(),
      people_id: selectedItem?.person_id ? selectedItem?.person_id : 0,
      org_id: selectedItem?.org_name?.length > 1 ? selectedItem?.id : null,
      context_box: value,
      location: selectedLocation ? selectedLocation : "",
      // tags: selectedLocationEvent ? selectedLocationEvent : "",
      tags: Array.isArray(event) ? event.join(",") : event,

    };

    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      data: data,
      url: `${APIUrlThree()}/v1/save_feed_box`,
    };

    axios(options)
      .then(() => {
        handleClose();
        getContentFeed(value, "save");
        setEvent("")
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message || "An error occurred.");
      });
  };
  const getContentFeed = (value, type) => {
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      url: `${APIUrlThree()}/v1/get_feed_box?user_id=${GetUserId()}`,
    };
    axios(options)
      .then((e) => {
        let data = e?.data;
        if (data?.length < 1) {
          setLoading(false);
        }
        if (value && type === "save") {
          const check = data?.find((item) => item?.content_box_id === value);
          if (check?.id) {
            getNews(check, "tag");
          }
        } else if (value && type === "del") {
          if (value === 1) {
            setFeedBoxOne({ data: [], boxData: {} });
          } else if (value === 2) {
            setFeedBoxTwo({ data: [], boxData: {} });
          } else if (value === 3) {
            setFeedBoxThree({ data: [], boxData: {} });
          }
          setLoading(false);
        } else {
          data?.forEach((item) => {
            getNews(item);
          });
        }
      })
      .catch((error) => {
        // toast.error(error?.response?.data?.message || "An error occurred.");
      });
  };
  useEffect(() => {
    getContentFeed();
  }, []);
  const handleRemoveFeed = (item) => {
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      url: `${APIUrlThree()}/v1/delete_feed_box?box_id=${item?.id}`,
    };
    axios(options)
      .then((e) => {
        getContentFeed(item?.content_box_id, "del");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message || "An error occurred.");
      });
  };

  const HandlePrintOne = useReactToPrint({
    content: () => boxOneRef.current,
  });
  const HandlePrintTwo = useReactToPrint({
    content: () => boxTwoRef.current,
  });
  const HandlePrintThree = useReactToPrint({
    content: () => boxThreeRef.current,
  });
  const handleToPDF = (item) => {
    const content =
      item === 1
        ? boxOneRef.current
        : item === 2
          ? boxTwoRef.current
          : boxThreeRef.current;
    const options = {
      filename: "newsFeed.pdf",
      margin: 10,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        format: "letter",
        orientation: "portrait",
      },
    };
    html2pdf().set(options).from(content).save();
  };

  const handleSaveTags = () => {
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      data: [tagName],
      url: `${APIUrlThree()}/v1/add_tags?news_id=${openTagModal?.articleData?._id
        }`,
    };
    axios(options)
      .then((e) => {
        setOpenTagModal();
        getContentFeed(openTagModal?.data, "save");
        setTagName("");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message || "An error occurred.");
      });
  };

  const handleChangeFeedBoxOne = (event) => {
    setDateOrderOne(event.target.value);
    // if (event.target.value === 10) {
    //     const sortedData = [...feedBoxOne.data].sort((a, b) => new Date(a.article.date) - new Date(b.article.date));
    //     setFeedBoxOne({ ...feedBoxOne, data: sortedData });
    // } else if (event.target.value === 20) {
    //     const sortedData = [...feedBoxOne.data].sort((a, b) => new Date(b.article.date) - new Date(a.article.date));
    //     setFeedBoxOne({ ...feedBoxOne, data: sortedData });
    // }
  };
  const handleChangeFeedBoxTwo = (event) => {
    setDateOrderTwo(event.target.value);
    // if (event.target.value === 30) {
    //     const sortedData = [...feedBoxTwo.data].sort((a, b) => new Date(a.article.date) - new Date(b.article.date));
    //     setFeedBoxTwo({ ...feedBoxTwo, data: sortedData });
    // } else if (event.target.value === 40) {
    //     const sortedData = [...feedBoxTwo.data].sort((a, b) => new Date(b.article.date) - new Date(a.article.date));
    //     setFeedBoxTwo({ ...feedBoxTwo, data: sortedData });
    // }
  };
  const handleChangeFeedBoxThree = (event) => {
    setDateOrderThree(event.target.value);
    // if (event.target.value === 50) {
    //     const sortedData = [...feedBoxThree.data].sort((a, b) => new Date(a.article.date) - new Date(b.article.date));
    //     setFeedBoxThree({ ...feedBoxThree, data: sortedData });
    // } else if (event.target.value === 60) {
    //     const sortedData = [...feedBoxThree.data].sort((a, b) => new Date(b.article.date) - new Date(a.article.date));
    //     setFeedBoxThree({ ...feedBoxThree, data: sortedData });
    // }
  };
  const initialData = (item) => {
    const data = item?.split(" ");
    return `${data?.[0]?.charAt(0).toUpperCase() || ""}${data?.[1]?.charAt(0).toUpperCase() || ""
      }`;
  };

  const handleSearchSave = (e, type) => {
    const data =
      e == 1 ? [saveSearchOne] : e == 2 ? [saveSearchTwo] : [saveSearchThree];
    const selected =
      e == 1
        ? feedBoxOne?.boxData
        : e == 2
          ? feedBoxTwo?.boxData
          : feedBoxThree?.boxData;
    const skipVal = e === 1 ? skipOne : e === 2 ? skipTwo : skipThree;
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
        "Content-Type": "application/json",
      },
      data: data,
      url: `${APIUrlThree()}/v1/search_news?limit=10${selected?.org_id !== null ? "&org_id=" : ""}${selected?.org_id !== null ? selected.org_id : ''}${selected?.people_id !== 0 ? "&people_id=" : ""}${selected?.people_id !== 0 ? selected.people_id : ''}${selected?.location?.length ? "&location=" : ""}${selected?.location?.length ? selected.location : ''}${selected?.tags?.length ? "&tags=" : ""}${selected?.tags?.length ? selected?.tags : ""}&skip=${type === "first" ? 0 : skipVal}&search_term=${data?.[0]}`,
    };

    axios(options)
      .then((response) => {

        if (e == 1) {
          if (response?.data?.length < 10) {
            setLoadOne(false);
          } else {
            setLoadOne(true)
          }
          setSearchOneApi(true);
          const newData = feedBoxOne?.data || [];
          if (type == "first") {
            setFeedBoxOne({
              data: [...response.data],
              boxData: feedBoxOne?.boxData,
            });
          } else {
            setFeedBoxOne({
              data: [...newData, ...response.data],
              boxData: feedBoxOne?.boxData,
            });
          }

        } else if (e == 2) {
          if (response?.data?.length < 10) {
            setLoadTwo(false);
          } else {
            setLoadTwo(true)
          }
          setSearchTwoApi(true);
          const newData = feedBoxTwo?.data || [];
          if (type == "first") {
            setFeedBoxTwo({
              data: [...response.data],
              boxData: feedBoxTwo?.boxData,
            });
          } else {
            setFeedBoxTwo({
              data: [...newData, ...response.data],
              boxData: feedBoxTwo?.boxData,
            });
          }

        } else {
          if (response?.data?.length < 10) {
            setLoadThree(false);
          } else {
            setLoadThree(true)
          }
          setSearchThreeApi(true);
          const newData = feedBoxTwo?.data || [];
          if (type == "first") {
            setFeedBoxThree({
              data: [...response.data],
              boxData: feedBoxThree?.boxData,
            });
          } else {
            setFeedBoxThree({
              data: [...newData, ...response.data],
              boxData: feedBoxThree?.boxData,
            });
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  useEffect(() => {
    if (saveSearchOne?.length > 2) {
      const timer = setTimeout(() => {
        handleSearchSave(1, "first");
      }, 1000);
      return () => clearTimeout(timer);

    }
  }, [saveSearchOne]);
  useEffect(() => {
    if (saveSearchTwo?.length > 2) {
      const timer = setTimeout(() => {
        handleSearchSave(2, "first");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [saveSearchTwo]);
  useEffect(() => {
    if (saveSearchThree?.length > 2) {
      const timer = setTimeout(() => {
        handleSearchSave(2, "first");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [saveSearchThree]);

  const getCities = () => {
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": APIUrlToken(),
      },
      data: {},
      url: `${APIUrlThree()}/v1/get_cities`,
    };

    axios(options)
      .then((response) => {
        let dtaa = response.data.data.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        setCities(dtaa || []);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching cities:", error);
        toast.error(
          error?.response?.data?.detail ||
          error?.response?.data?.message ||
          "An error occurred."
        );
      });
  };

  const getNewsTags = () => {
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": APIUrlToken(),
      },
      data: {},
      url: `${APIUrlThree()}/v1/get_news_tags`,
    };

    axios(options)
      .then((response) => {
        let dtaa = response.data.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        setNewsTags(dtaa || []);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching cities:", error);
        toast.error(
          error?.response?.data?.detail ||
          error?.response?.data?.message ||
          "An error occurred."
        );
      });
  };

  React.useEffect(() => {
    getCities();
    getNewsTags();
  }, []);

  const handleLocationClick = (location) => {
    setSelectedLocation(location);
    setSearchText(location);
  };

  // const handleEventClick = (location) => {
  //   setSelectedLocationEvent(location);
  //   setSearchTextEvent(location);
  // };

  const truncateText = (text) => {
    return text?.length > 13 ? text?.slice(0, 13) + '...' : text;
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <div>
        <p className="CI-hd">Competitive Intelligence</p>
        <p className="CI-content">
          Track Organizations, Prospects or News & Events in key competitor states to stay informed. This data is meticulously analyzed by our sophisticated algorithms, providing crucial insights and information specifically relevant to JobsOhio’s operations and objectives.
        </p>
        <p className="CI-adding-content">
          Select ‘+’ below to begin adding content
        </p>
      </div>
      <div className="add-contents-cards row">
        {feedBoxOne?.boxData?.id ? (
          <div className="CI-cards-content col">
            <div className="adding-content">
              <div className="two-head-comp">
                <div className="result-tags-icon-comp">
                  <div className="checkbox-result-tag-comp">
                    <input type="checkbox" />
                    <p className="result-comp">
                      {feedBoxOne?.data?.length} Results
                    </p>
                    {/* <p className='ai-tags-comp'>AI Tags</p> */}
                  </div>

                  <div><Tooltip
                    title={
                      feedBoxOne?.boxData?.org_id
                        ? feedBoxOne.boxData.org_name
                        : feedBoxOne?.boxData?.first_name && feedBoxOne?.boxData?.last_name
                          ? `${feedBoxOne.boxData.first_name} ${feedBoxOne.boxData.last_name}`
                          : feedBoxOne?.boxData?.location ? feedBoxOne?.boxData?.location : feedBoxOne?.boxData?.tags
                    }
                    arrow
                    placement="top"
                  >
                    <p className="card-heading-top">
                      {feedBoxOne?.boxData?.org_id
                        ? truncateText(feedBoxOne.boxData.org_name)
                        : feedBoxOne?.boxData?.first_name && feedBoxOne?.boxData?.last_name
                          ? truncateText(`${feedBoxOne.boxData.first_name} ${feedBoxOne.boxData.last_name}`)
                          : feedBoxOne?.boxData?.location ? truncateText(feedBoxOne?.boxData?.location) :
                            truncateText(feedBoxOne?.boxData?.tags)}
                    </p>
                  </Tooltip></div>


                  <div className="copy-threedot-comp">
                    <img src={print} alt="" onClick={HandlePrintOne} />
                    <img src={download} alt="" onClick={() => handleToPDF(1)} />
                    <CloseIcon
                      className="threedot-icon-comp"
                      onClick={() => handleRemoveFeed(feedBoxOne?.boxData)}
                    />
                  </div>
                </div>
                <div className="result-date-icon-comp">
                  <div className="checkbox-result-tag-comp">
                    <img src={halflines} alt="" />
                    <p className="sorted-by-comp">Sorted by:</p>
                    <FormControl
                      sx={{ m: 1, minWidth: 120 }}
                      className="date-comp-dropdown"
                    >
                      <Select
                        className="date-comp-select"
                        value={dateOrderOne}
                        onChange={handleChangeFeedBoxOne}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="" className="date-comp-menuitem">
                          <em className="date-comp-em">Date</em>
                        </MenuItem>
                        <MenuItem value="asc" className="date-comp-menuitem">
                          <em className="date-comp-em">Ascending</em>
                        </MenuItem>
                        <MenuItem value="desc" className="date-comp-menuitem">
                          <em className="date-comp-em">Descending</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="search_news_container">
                    <div
                      className={
                        openSearchOne
                          ? "wrap-search-news active"
                          : "wrap-search-news"
                      }
                    >
                      <input
                        type="text"
                        className={
                          openSearchOne
                            ? "input_search_news active"
                            : "input_search_news"
                        }
                        placeholder="Search..."
                        value={saveSearchOne}
                        onChange={(e) => setSaveSearchOne(e?.target?.value)}
                        autoFocus
                      />
                      {openSearchOne ? (
                        <CloseIcon
                          className="threedot-icon-comp"
                          onClick={() => {
                            setOpenSearchOne(false);
                            setSearchOneApi(false);
                            setSaveSearchOne("");
                            getNews(feedBoxOne?.boxData, "date");
                          }}
                        />
                      ) : (
                        <img
                          src={search}
                          alt=""
                          onClick={() => {
                            setOpenSearchOne(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div ref={boxOneRef}>
                {feedBoxOne.data?.length ? (
                  <>
                    {feedBoxOne.data.map((articleData, index) => (
                      <div
                        key={index}
                        className="rest-cont"
                        style={{ pageBreakInside: "avoid" }}
                      >
                        <div className="joi-circle-text-comp">
                          <div className="jo-icon-comp">
                            <p>
                              {/* {feedBoxOne?.boxData?.org_id
                                ? initialData(feedBoxOne?.boxData?.org_name)
                                : initialData(
                                    feedBoxOne?.boxData?.first_name +
                                      " " +
                                      feedBoxOne?.boxData?.last_name
                                  )} */}
                              {feedBoxOne?.boxData?.org_id
                                ? initialData(feedBoxOne.boxData.org_name)
                                : feedBoxOne?.boxData?.first_name ||
                                  feedBoxOne?.boxData?.last_name
                                  ? initialData(
                                    `${feedBoxOne.boxData.first_name || ""} ${feedBoxOne.boxData.last_name || ""
                                      }`.trim()
                                  )
                                  : feedBoxOne?.boxData?.location ? initialData(feedBoxOne?.boxData?.location) :
                                    initialData(feedBoxOne?.boxData?.tags)}

                            </p>
                          </div>
                          <div className="updated-cont">
                            <div >
                              <p className="jobsohio-name-comp">
                                {articleData?.article?.title && (articleData?.article?.title.length > 25 ? `${articleData?.article?.title.slice(0, 25)}...` : articleData?.article?.title)}

                                {/* {feedBoxOne?.boxData?.org_id
                                  ? feedBoxOne?.boxData?.org_name
                                  : feedBoxOne?.boxData?.first_name +
                                    " " +
                                    feedBoxOne?.boxData?.last_name} */}
                                {/* {feedBoxOne?.boxData?.org_id
                                  ? feedBoxOne.boxData.org_name
                                  : feedBoxOne?.boxData?.first_name &&
                                    feedBoxOne?.boxData?.last_name
                                    ? `${feedBoxOne.boxData.first_name} ${feedBoxOne.boxData.last_name}`
                                    : feedBoxOne?.boxData?.location} */}
                              </p>
                            </div>
                            <div className="both-news-time-comp">
                              <p className="news-comp">
                                <a
                                  href={articleData?.article?.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {
                                    articleData?.article?.url
                                      ? (articleData.article.url.split('https://')[1]?.length > 15
                                        ? articleData.article.url.split('https://')[1].slice(0, 15) + '...'
                                        : articleData.article.url.split('https://')[1])
                                      : ''
                                  }

                                </a>
                                |
                              </p>                              <p className="today-comp">
                                {new Date(
                                  articleData?.article?.date
                                ).toLocaleDateString("en-US", {
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                })}
                                {/* {articleData?.article?.date} */}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="content-pera-comp">
                          <div className="content-pera-comp">
                            <p className="bold-content-pera-comp">
                              {
                                expandedSummaryOne[index] ? (
                                  <>
                                    {articleData.summary}
                                    {articleData.summary.length > 170 && (
                                      <span
                                        onClick={() => toggleSummaryOne(index)}
                                        className="see-more-btn"
                                        style={{
                                          color: "#DA291C",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          paddingLeft:"5px"
                                        }}
                                      >
                                        Show Less
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {articleData.summary.length > 170
                                      ? `${articleData.summary.slice(0, articleData.summary.lastIndexOf(' ', 170))}`
                                      : articleData.summary}
                                    {articleData.summary.length > 170 && (
                                      <span
                                        onClick={() => toggleSummaryOne(index)}
                                        className="see-more-btn"
                                        style={{
                                          color: "#DA291C",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          paddingLeft:"5px"
                                        }}
                                      >
                                        See More
                                      </span>
                                    )}
                                  </>
                                )
                              
                            }
                            </p>
                          </div>
                          <div className="Intersect-jb-nf-comp">
                            {articleData?.tags?.map((tag, idx) => (
                              <p key={idx}>{tag}</p>
                            ))}
                            {/* <p
                              className="save-button-dash"
                              onClick={() =>
                                setOpenTagModal({
                                  articleData: articleData,
                                  data: feedBoxOne?.boxData?.content_box_id,
                                })
                              }
                            >
                              Add tag
                            </p> */}
                          </div>
                        </div>
                        <hr className="hr-tag-ci" />
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-center-result">No results found</p>
                )}
              </div>
            </div>
            {loadOne ? (
              <div className="dash-cancel-save-btns">
                <Button
                  className="de_next"
                  onClick={() => setSkipOne((skip) => skip + 10)}
                >
                  Load More
                </Button>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="CI-cards col" onClick={() => handleOpen(1)}>
            <AddCircleIcon
              style={{ color: "#DA291C", width: "33.73px", height: "33.73px" }}
            />
            <p className="CI-card-content">Add Content</p>
          </div>
        )}

        {feedBoxTwo?.boxData?.id ? (
          <div className="CI-cards-content col">
            <div className="adding-content">
              <div className="two-head-comp">
                <div className="result-tags-icon-comp">
                  <div className="checkbox-result-tag-comp">
                    <input type="checkbox" />
                    <p className="result-comp">
                      {feedBoxTwo?.data?.length} Results
                    </p>
                    {/* <p className='ai-tags-comp'>AI Tags</p> */}
                  </div>
                  <div><Tooltip
                    title={
                      feedBoxTwo?.boxData?.org_id
                        ? feedBoxTwo.boxData.org_name
                        : feedBoxTwo?.boxData?.first_name && feedBoxTwo?.boxData?.last_name
                          ? `${feedBoxTwo.boxData.first_name} ${feedBoxTwo.boxData.last_name}`
                          : feedBoxTwo?.boxData?.location ? feedBoxTwo?.boxData?.location : feedBoxTwo?.boxData?.tags
                    }
                    arrow
                    placement="top"
                  >
                    <p className="card-heading-top">
                      {feedBoxTwo?.boxData?.org_id
                        ? truncateText(feedBoxTwo.boxData.org_name)
                        : feedBoxTwo?.boxData?.first_name && feedBoxTwo?.boxData?.last_name
                          ? truncateText(`${feedBoxTwo.boxData.first_name} ${feedBoxTwo.boxData.last_name}`)
                          : feedBoxTwo?.boxData?.location ? truncateText(feedBoxTwo?.boxData?.location) :
                            truncateText(feedBoxTwo?.boxData?.tags)}
                    </p>
                  </Tooltip></div>
                  <div className="copy-threedot-comp">
                    <img src={print} alt="" onClick={HandlePrintTwo} />
                    <img src={download} alt="" onClick={() => handleToPDF(2)} />
                    <CloseIcon
                      className="threedot-icon-comp"
                      onClick={() => handleRemoveFeed(feedBoxTwo?.boxData)}
                    />
                  </div>
                </div>
                <div className="result-date-icon-comp">
                  <div className="checkbox-result-tag-comp">
                    <img src={halflines} alt="" />
                    <p className="sorted-by-comp">Sorted by:</p>
                    <FormControl
                      sx={{ m: 1, minWidth: 120 }}
                      className="date-comp-dropdown"
                    >
                      <Select
                        className="date-comp-select"
                        value={dateOrderTwo}
                        onChange={handleChangeFeedBoxTwo}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="" className="date-comp-menuitem">
                          <em className="date-comp-em">Date</em>
                        </MenuItem>
                        <MenuItem value="asc" className="date-comp-menuitem">
                          <em className="date-comp-em">Ascending</em>
                        </MenuItem>
                        <MenuItem value="desc" className="date-comp-menuitem">
                          <em className="date-comp-em">Descending</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="search_news_container">
                    <div
                      className={
                        openSearchTwo
                          ? "wrap-search-news active"
                          : "wrap-search-news"
                      }
                    >
                      <input
                        type="text"
                        className={
                          openSearchTwo
                            ? "input_search_news active"
                            : "input_search_news"
                        }
                        placeholder="Search..."
                        value={saveSearchTwo}
                        onChange={(e) => setSaveSearchTwo(e?.target?.value)}
                        autoFocus
                      />
                      {openSearchTwo ? (
                        <CloseIcon
                          className="threedot-icon-comp"
                          onClick={() => {
                            setOpenSearchTwo(false);
                            setSearchTwoApi(false);
                            setSaveSearchTwo("");
                            getNews(feedBoxTwo?.boxData, "date");
                          }}
                        />
                      ) : (
                        <img
                          src={search}
                          alt=""
                          onClick={() => {
                            setOpenSearchTwo(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div ref={boxTwoRef}>
                {feedBoxTwo?.data?.length ? (
                  <>
                    {feedBoxTwo.data.map((articleData, index) => (
                      <div
                        key={index}
                        className="rest-cont"
                        style={{ pageBreakInside: "avoid" }}
                      >
                        <div className="joi-circle-text-comp">
                          <div className="jo-icon-comp">
                            <p>
                              {/* {feedBoxTwo?.boxData?.org_id
                                ? initialData(feedBoxTwo?.boxData?.org_name)
                                : initialData(
                                    feedBoxTwo?.boxData?.first_name +
                                      " " +
                                      feedBoxTwo?.boxData?.last_name
                                  )} */}
                              {feedBoxTwo?.boxData?.org_id
                                ? initialData(feedBoxTwo.boxData.org_name)
                                : feedBoxTwo?.boxData?.first_name ||
                                  feedBoxTwo?.boxData?.last_name
                                  ? initialData(
                                    `${feedBoxTwo.boxData.first_name || ""} ${feedBoxTwo.boxData.last_name || ""
                                      }`.trim()
                                  )
                                  : feedBoxTwo?.boxData?.location ? initialData(feedBoxTwo?.boxData?.location) :
                                    initialData(feedBoxTwo?.boxData?.tags)}
                            </p>
                          </div>
                          <div className="updated-cont">
                            <div>
                              <p className="jobsohio-name-comp">
                                {articleData?.article?.title && (articleData?.article?.title.length > 25 ? `${articleData?.article?.title.slice(0, 25)}...` : articleData?.article?.title)}
                                {/* 
                               {articleData?.article?.article &&
                              (articleData.article.article.length > 70
                                ? `${articleData.article.article.slice(0, 70)}...`
                                : articleData.article.article)} */}



                                {/* {feedBoxTwo?.boxData?.org_id
                                  ? feedBoxTwo.boxData.org_name
                                  : feedBoxTwo?.boxData?.first_name &&
                                    feedBoxTwo?.boxData?.last_name
                                    ? `${feedBoxTwo.boxData.first_name} ${feedBoxTwo.boxData.last_name}`
                                    : feedBoxTwo?.boxData?.location} */}
                              </p>
                            </div>
                            <div className="both-news-time-comp">
                              <p className="news-comp">
                                <a
                                  href={articleData?.article?.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {
                                    articleData?.article?.url
                                      ? (articleData.article.url.split('https://')[1]?.length > 15
                                        ? articleData.article.url.split('https://')[1].slice(0, 15) + '...'
                                        : articleData.article.url.split('https://')[1])
                                      : ''
                                  }
                                </a>
                                |
                              </p>
                              <p className="today-comp">
                                {new Date(
                                  articleData?.article?.date
                                ).toLocaleDateString("en-US", {
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                })}

                                {/* {articleData?.article?.date} */}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="content-pera-comp">
                        <p className="bold-content-pera-comp">
                              {
                                expandedSummaryTwo[index] ? (
                                  <>
                                    {articleData.summary}
                                    {articleData.summary.length > 170 && (
                                      <span
                                        onClick={() => toggleSummaryTwo(index)}
                                        className="see-more-btn"
                                        style={{
                                          color: "#DA291C",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          paddingLeft:"5px"
                                        }}
                                      >
                                        Show Less
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {articleData.summary.length > 170
                                      ? `${articleData.summary.slice(0, articleData.summary.lastIndexOf(' ', 170))}`
                                      : articleData.summary}
                                    {articleData.summary.length > 170 && (
                                      <span
                                        onClick={() => toggleSummaryTwo(index)}
                                        className="see-more-btn"
                                        style={{
                                          color: "#DA291C",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          paddingLeft:"5px"
                                        }}
                                      >
                                        See More
                                      </span>
                                    )}
                                  </>
                                )
                              
                            }
                            </p>
                          {/* <p className="norm-content-pera-comp">
                            {articleData?.article?.text}
                            <a
                              href={articleData?.article?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "#DA291C",
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                            >
                              See More...
                            </a>
                          </p> */}
                          <div className="Intersect-jb-nf-comp">
                            {articleData?.tags?.map((tag, idx) => (
                              <p key={idx}>{tag}</p>
                            ))}
                            {/* <p
                              className="save-button-dash"
                              onClick={() =>
                                setOpenTagModal({
                                  articleData: articleData,
                                  data: feedBoxTwo?.boxData?.content_box_id,
                                })
                              }
                            >
                              Add tag
                            </p> */}
                          </div>
                        </div>
                        <hr className="hr-tag-ci" />
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-center-result">No results found</p>
                )}
              </div>
            </div>
            {loadTwo ? (
              <div className="dash-cancel-save-btns">
                <Button
                  className="de_next"
                  onClick={() => setSkipTwo((skip) => skip + 10)}
                >
                  Load More
                </Button>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="CI-cards col" onClick={() => handleOpen(2)}>
            <AddCircleIcon
              style={{ color: "#DA291C", width: "33.73px", height: "33.73px" }}
            />
            <p className="CI-card-content">Add Content</p>
          </div>
        )}
        {feedBoxThree?.boxData?.id ? (
          <div className="CI-cards-content col">
            <div className="adding-content">
              <div className="two-head-comp">
                <div className="result-tags-icon-comp">
                  <div className="checkbox-result-tag-comp">
                    <input type="checkbox" />
                    <p className="result-comp">
                      {feedBoxThree?.data?.length} Results
                    </p>
                    {/* <p className='ai-tags-comp'>AI Tags</p> */}
                  </div>
                  <div><Tooltip
                    title={
                      feedBoxThree?.boxData?.org_id
                        ? feedBoxThree.boxData.org_name
                        : feedBoxThree?.boxData?.first_name && feedBoxThree?.boxData?.last_name
                          ? `${feedBoxThree.boxData.first_name} ${feedBoxThree.boxData.last_name}`
                          : feedBoxThree?.boxData?.location ? feedBoxThree?.boxData?.location : feedBoxThree?.boxData?.tags

                    }
                    arrow
                    placement="top"
                  >
                    <p className="card-heading-top">
                      {feedBoxThree?.boxData?.org_id
                        ? truncateText(feedBoxThree.boxData.org_name)
                        : feedBoxThree?.boxData?.first_name && feedBoxThree?.boxData?.last_name
                          ? truncateText(`${feedBoxThree.boxData.first_name} ${feedBoxThree.boxData.last_name}`)
                          : feedBoxThree?.boxData?.location ? truncateText(feedBoxThree?.boxData?.location) : truncateText(feedBoxThree?.boxData?.tags)}
                    </p>
                  </Tooltip></div>
                  <div className="copy-threedot-comp">
                    <img src={print} alt="" onClick={HandlePrintThree} />
                    <img src={download} alt="" onClick={() => handleToPDF(3)} />
                    <CloseIcon
                      className="threedot-icon-comp"
                      onClick={() => handleRemoveFeed(feedBoxThree?.boxData)}
                    />
                  </div>
                </div>
                <div className="result-date-icon-comp">
                  <div className="checkbox-result-tag-comp">
                    <img src={halflines} alt="" />
                    <p className="sorted-by-comp">Sorted by:</p>
                    <FormControl
                      sx={{ m: 1, minWidth: 120 }}
                      className="date-comp-dropdown"
                    >
                      <Select
                        className="date-comp-select"
                        value={dateOrderThree}
                        onChange={handleChangeFeedBoxThree}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="" className="date-comp-menuitem">
                          <em className="date-comp-em">Date</em>
                        </MenuItem>
                        <MenuItem value="asc" className="date-comp-menuitem">
                          <em className="date-comp-em">Ascending</em>
                        </MenuItem>
                        <MenuItem value="desc" className="date-comp-menuitem">
                          <em className="date-comp-em">Descending</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="search_news_container">
                    <div
                      className={
                        openSearchThree
                          ? "wrap-search-news active"
                          : "wrap-search-news"
                      }
                    >
                      <input
                        type="text"
                        className={
                          openSearchThree
                            ? "input_search_news active"
                            : "input_search_news"
                        }
                        placeholder="Search..."
                        value={saveSearchThree}
                        onChange={(e) => setSaveSearchThree(e?.target?.value)}
                        autoFocus
                      />
                      {openSearchThree ? (
                        <CloseIcon
                          className="threedot-icon-comp"
                          onClick={() => {
                            setOpenSearchThree(false);
                            setSearchThreeApi(false);
                            setSaveSearchThree("");
                            getNews(feedBoxThree?.boxData, "date");
                          }}
                        />
                      ) : (
                        <img
                          src={search}
                          alt=""
                          onClick={() => {
                            setOpenSearchThree(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div ref={boxThreeRef}>
                {feedBoxThree?.data?.length ? (
                  <>
                    {feedBoxThree.data.map((articleData, index) => (
                      <div
                        key={index}
                        className="rest-cont"
                        style={{ pageBreakInside: "avoid" }}
                      >
                        <div className="joi-circle-text-comp">
                          {/* <div className='jo-icon-comp'><p>{feedBoxThree?.boxData?.org_id ? initialData(feedBoxThree?.boxData?.org_name) : initialData(feedBoxThree?.boxData?.first_name + " " + feedBoxThree?.boxData?.last_name) :feedBoxThree?.boxData?.location }</p></div> */}
                          <div className="jo-icon-comp">
                            <p>
                              {feedBoxThree?.boxData?.org_id
                                ? initialData(feedBoxThree.boxData.org_name)
                                : feedBoxThree?.boxData?.first_name ||
                                  feedBoxThree?.boxData?.last_name
                                  ? initialData(
                                    `${feedBoxThree.boxData.first_name || ""} ${feedBoxThree.boxData.last_name || ""
                                      }`.trim()
                                  )
                                  : feedBoxThree?.boxData?.location ? initialData(feedBoxThree?.boxData?.location) :
                                    initialData(feedBoxThree?.boxData?.tags)}
                            </p>
                          </div>

                          <div className="updated-cont">
                            {/* <div><p className='jobsohio-name-comp'>{feedBoxThree?.boxData?.org_id ? feedBoxThree?.boxData?.org_name : feedBoxThree?.boxData?.first_name + " " + feedBoxThree?.boxData?.last_name : feedBoxThree?.boxData?.location}</p></div> */}
                            <div>
                              <p className="jobsohio-name-comp">
                                {articleData?.article?.title && (articleData?.article?.title.length > 25 ? `${articleData?.article?.title.slice(0, 25)}...` : articleData?.article?.title)}

                                {/* {feedBoxThree?.boxData?.org_id
                                  ? feedBoxThree.boxData.org_name
                                  : feedBoxThree?.boxData?.first_name &&
                                    feedBoxThree?.boxData?.last_name
                                    ? `${feedBoxThree.boxData.first_name} ${feedBoxThree.boxData.last_name}`
                                    : feedBoxThree?.boxData?.location} */}
                              </p>
                            </div>

                            <div className="both-news-time-comp">
                              <p className="news-comp">
                                <a
                                  href={articleData?.article?.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {
                                    articleData?.article?.url
                                      ? (articleData.article.url.split('https://')[1]?.length > 15
                                        ? articleData.article.url.split('https://')[1].slice(0, 15) + '...'
                                        : articleData.article.url.split('https://')[1])
                                      : ''
                                  }
                                </a>
                                |
                              </p>
                              <p className="today-comp">
                                {new Date(
                                  articleData?.article?.date
                                ).toLocaleDateString("en-US", {
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                })}
                                {/* {articleData?.article?.date} */}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="content-pera-comp">
                        <p className="bold-content-pera-comp">
                              {
                                expandedSummaryThree[index] ? (
                                  <>
                                    {articleData.summary}
                                    {articleData.summary.length > 170 && (
                                      <span
                                        onClick={() => toggleSummaryThree(index)}
                                        className="see-more-btn"
                                        style={{
                                          color: "#DA291C",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          paddingLeft:"5px"
                                        }}
                                      >
                                        Show Less
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {articleData.summary.length > 170
                                      ? `${articleData.summary.slice(0, articleData.summary.lastIndexOf(' ', 170))}`
                                      : articleData.summary}
                                    {articleData.summary.length > 170 && (
                                      <span
                                        onClick={() => toggleSummaryThree(index)}
                                        className="see-more-btn"
                                        style={{
                                          color: "#DA291C",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          paddingLeft:"5px"
                                        }}
                                      >
                                        See More
                                      </span>
                                    )}
                                  </>
                                )
                              
                            }
                            </p>
                          {/* <p className="norm-content-pera-comp">
                            {articleData?.article?.text}
                            <a
                              href={articleData?.article?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "#DA291C",
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                            >
                              See More...
                            </a>
                          </p> */}
                          <div className="Intersect-jb-nf-comp">
                            {articleData?.tags?.map((tag, idx) => (
                              <p key={idx}>{tag}</p>
                            ))}
                            {/* <p
                              className="save-button-dash"
                              onClick={() =>
                                setOpenTagModal({
                                  articleData: articleData,
                                  data: feedBoxThree?.boxData?.content_box_id,
                                })
                              }
                            >
                              Add tag
                            </p> */}
                          </div>
                        </div>
                        <hr className="hr-tag-ci" />
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-center-result">No results found</p>
                )}
              </div>
            </div>
            {loadThree ? (
              <div className="dash-cancel-save-btns">
                <Button
                  className="de_next"
                  onClick={() => setSkipThree((skip) => skip + 10)}
                >
                  Load More
                </Button>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="CI-cards col" onClick={() => handleOpen(3)}>
            <AddCircleIcon
              style={{ color: "#DA291C", width: "33.73px", height: "33.73px" }}
            />
            <p className="CI-card-content">Add Content</p>
          </div>
        )}
      </div>
      {/* Common Modal  */}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="vrufyerv">
          <Box sx={style}>
            <div className="de_overflow_cont">
              <div className="dash-model-head">
                <h3>Select content for stream</h3>
                <CloseIcon
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <hr />
              <div className="ci-outer">
                <div className="ci-tabs">
                  {
                    feedBoxOne?.boxData?.id ?
                      <div>Content One</div> :
                      <div
                        className={value === 1 ? "ci-selected" : ""}
                        onClick={() => {
                          setValue(1);
                          setCommon(true);
                          setValueOrganization(false);
                          setValuePeople(false);
                          setValueNews(false);
                          setValueEvent(false);
                          setSelectedItem();
                          setSelectedLocation();
                          setModalSearchOrgData("");
                          setModalSearchPeopleData("");
                          setSearchText("");
                          setSearchTextEvent("");
                          setEvent("")

                        }}
                      >
                        Content One
                      </div>
                  }
                  {
                    feedBoxTwo?.boxData?.id ? <div>Content Two</div> :
                      <div
                        className={value === 2 ? "ci-selected" : ""}
                        onClick={() => {
                          setValue(2);
                          setCommon(true);
                          setValueOrganization(false);
                          setValuePeople(false);
                          setValueNews(false);
                          setValueEvent(false);
                          setSelectedItem();
                          setSelectedLocation();
                          setModalSearchOrgData("");
                          setModalSearchPeopleData("");
                          setSearchText("");
                          setSearchTextEvent("");
                          setEvent("")

                        }}
                      >
                        Content Two
                      </div>
                  }

                  {
                    feedBoxThree?.boxData?.id ? <div>Content Three</div> :
                      <div
                        className={value === 3 ? "ci-selected" : ""}
                        onClick={() => {
                          setValue(3);
                          setCommon(true);
                          setValueOrganization(false);
                          setValuePeople(false);
                          setValueNews(false);
                          setValueEvent(false);
                          setSelectedItem();
                          setSelectedLocation();
                          setModalSearchOrgData("");
                          setModalSearchPeopleData("");
                          setSearchText("");
                          setSearchTextEvent("");
                          setEvent("")
                        }}
                      >
                        Content Three
                      </div>
                  }

                </div>
                {common ? (
                  <div className="ci-search">
                    <p
                      className="organization-search-ci"
                      onClick={handleOpenOrganization}
                    >
                      Organization
                    </p>
                    <p className="people-search-ci" onClick={handleOpenPeople}>
                      Prospects
                    </p>
                    <p
                      className="news-events-search-ci"
                      onClick={handleOpenEvent}
                    >
                      News/Events

                    </p>
                    <p
                      className="news-events-search-ci"
                      onClick={handleOpenNews}
                    >
                      Competitor States
                    </p>
                    <div className="dash-cancel-save-btns">
                      <Button className="de_cancel" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button
                        className="de_next"
                      // onClick={saveContentFeed}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                ) : null}
                {valueOrganization ? (
                  <div className="ci-search">
                    <p className="organization-search-ci">
                      Search By Organization
                    </p>

                    <OrganizationSearch
                      setLoading={setLoading}
                      modalSearchOrgData={modalSearchOrgData}
                      setModalSearchOrgData={setModalSearchOrgData}
                      responseOrgData={responseOrgData}
                      showSearchOrgData={showSearchOrgData}
                      setShowSearchOrgData={setShowSearchOrgData}
                      setApiHitOrg={setApiHitOrg}
                      setSelectedItem={setSelectedItem}
                    />
                    <div className="dash-cancel-save-btns">
                      <Button className="de_cancel" onClick={handleBackorg}>
                        Back
                      </Button>
                      <Button className="de_cancel" onClick={handleCloseOrg}>
                        Cancel
                      </Button>
                      <Button className="de_next" onClick={saveContentFeed}>
                        Save
                      </Button>
                    </div>
                  </div>
                ) : null}
                {valuePeople ? (
                  <div className="ci-search">
                    <p className="organization-search-ci">Search By Prospects</p>
                    <PeopleSearch
                      setLoading={setLoading}
                      modalSearchPeopleData={modalSearchPeopleData}
                      setModalSearchPeopleData={setModalSearchPeopleData}
                      responsePeopleData={responsePeopleData}
                      showSearchPeopleData={showSearchPeopleData}
                      setShowSearchPeopleData={setShowSearchPeopleData}
                      setApiHitPeople={setApiHitPeople}
                      setSelectedItem={setSelectedItem}
                    />
                    <div className="dash-cancel-save-btns">
                      <Button className="de_cancel" onClick={handleBackPeople}>
                        Back
                      </Button>
                      <Button
                        className="de_cancel"
                        onClick={handleCancelPeople}
                      >
                        Cancel
                      </Button>
                      <Button className="de_next" onClick={saveContentFeed}>
                        Save
                      </Button>
                    </div>
                  </div>
                ) : null}

                {valueEvent ? (
                  <div className="ci-search-news-events">
                    <div className="news-event-search-modal">
                      <Paper
                        component="form"
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          width: 400,
                        }}
                        className="full-searchbar-comp ci-searchbar"
                      >
                        <SearchIcon className="searchicon-in-header" />
                        <InputBase
                          className="search-inner-text"
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Search News/Events…"
                          inputProps={{ "aria-label": "Search" }}
                          autoComplete="off"
                          value={searchTextEvent}
                          onChange={(e) => setSearchTextEvent(e?.target?.value)}
                        />
                        {searchTextEvent && (
                          <CloseIcon
                            className="showicons-search"
                            onClick={() => setSearchTextEvent("")}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </Paper>
                    </div>

                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      filteredEvent?.map((location, index) => (
                        <div key={index} className="news-event-modal-item">
                          <input
                            className="custom-checkbox"
                            type="checkbox"
                            checked={event.includes(location)}
                            onChange={() => handleEventClick(location)}
                          />
                          <p
                            className=""
                            onClick={() => handleEventClick(location)}
                            style={{
                              cursor: "pointer",
                              color: event.includes(location) ? "red" : "inherit",
                            }}
                          >
                            {location}
                          </p>
                        </div>
                      ))
                    )}
                    <div className="dash-cancel-save-btns">
                      <Button className="de_cancel" onClick={handleBackEvent}>
                        Back
                      </Button>
                      <Button className="de_cancel" onClick={handleCloseEvent}>
                        Cancel
                      </Button>
                      <Button className="de_next" onClick={saveContentFeed}>
                        Save
                      </Button>
                    </div>
                  </div>
                ) : null}

                {valueNews ? (
                  <div className="ci-search-news-events">
                    <div className="news-event-search-modal">
                      <Paper
                        component="form"
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          width: 400,
                        }}
                        className="full-searchbar-comp ci-searchbar"
                      >
                        <SearchIcon className="searchicon-in-header" />
                        <InputBase
                          className="search-inner-text"
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Search competitor States…"
                          inputProps={{ "aria-label": "Search" }}
                          autoComplete="off"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                        {searchText && (
                          <CloseIcon
                            className="showicons-search"
                            onClick={() => setSearchText("")}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </Paper>
                    </div>

                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      filteredLocations?.map((location, index) => (
                        <p
                          key={index}
                          className="news-event-modal-text"
                          onClick={() => handleLocationClick(location)}
                          style={{
                            color:
                              selectedLocation === location ? "red" : "inherit",
                            cursor: "pointer",
                          }}
                        >
                          {location}
                        </p>
                      ))
                    )}
                    <div className="dash-cancel-save-btns">
                      <Button className="de_cancel" onClick={handleBackNews}>
                        Back
                      </Button>
                      <Button className="de_cancel" onClick={handleCloseNews}>
                        Cancel
                      </Button>
                      <Button className="de_next" onClick={saveContentFeed}>
                        Save
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Box>
        </div>
      </Modal>

      {/* Modal for Add Tags */}
      <Modal
        open={openTagModal?.articleData?._id}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="vrufyerv">
          <Box sx={style2}>
            <div className="modal-head-heading-crossicon">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="enetr-name-heading-modal"
              >
                Add New Tag
              </Typography>
              <ClearIcon
                style={{ cursor: "pointer" }}
                onClick={() => setOpenTagModal()}
              />
            </div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <label htmlFor="" className="search-name-label-modal">
                Tag name
              </label>
              <LabelInput
                value={tagName}
                onChange={(e) => setTagName(e?.target?.value)}
              />
            </Typography>
            <Stack>
              <div className="button-cancel-save-modal">
                <Button
                  variant="type"
                  className="cancel-button-modal"
                  onClick={() => {
                    setOpenTagModal();
                    setTagName("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="type"
                  className="save-button-modal"
                  onClick={() => handleSaveTags()}
                >
                  Save
                </Button>
              </div>
            </Stack>
          </Box>
        </div>
      </Modal>
    </>
  );
};
export default CompetitiveIntelligence;
