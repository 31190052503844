import React from "react";
import "./Style.css";
import Layout from "../../Components/Layout/Layout";
import TabsAiProfile from "../../Components/AiProfile/TabsAiProfile/Index";
import video from "../../../src/Assets/video.svg"
import videodash from "../../../src/Assets/play-button.png"
import TrainingModal from "../../../src/Components/Training/TrainingModal";
import { AI_PROFILE_VIDEO } from "../../Utils/Constants";
const AiProfile = () => {
  const [refState, setRefState] = React.useState(false)
  const [refStatenew, setRefStatenew] = React.useState(false);
  const [videoModal, setVideoModal] = React.useState(false);
  const [overviewVideoModal, setOverviewVideoModal] = React.useState(false);
  const [videoLink, setVideoLink] = React.useState("")
  const handleCloseOverview = () => {
    setOverviewVideoModal(false)
  }
  const handleVideoModal = (item) => {
    setOverviewVideoModal(true)
    if (item == 1) {
      setVideoLink(AI_PROFILE_VIDEO)
    }
  }
  return (
    <>
      <Layout className={"paddingSet"} setRefState={setRefState} refStatenew={refStatenew} setRefStatenew={setRefStatenew} refState={refState}>
        <div style={{position:"relative"}}>
        <div className="video-space video_profile">

          <div className="video_container" onClick={() => setVideoModal(!videoModal)}>

            <img src={video} alt="video" className="videoIcon" />
            {videoModal && (
              <div className="bell-icon-box-video">
                <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(1)}>
                  <div className="bell-icon-outer-video">
                    <img src={videodash} alt="logo" className="video_img_drop" />
                    <div className="bellicon-text">
                      <p className="bellicon-p-search">AI Profile</p>
                    </div>
                  </div>
                  <div className="bellicon-separator"></div>
                </div>
              </div>
            )}

            <TrainingModal overviewVideoModal={overviewVideoModal} handleCloseOverview={handleCloseOverview} videoLink={videoLink} />

          </div>
          </div>
          <TabsAiProfile setRefStatenew={setRefStatenew} setRefState={setRefState} />
        </div>
        <div className="childed-section-of-everypage set-tabs-ai-section">
        </div>
      </Layout>
    </>
  );
};
export default AiProfile;