import { Box, Button, Modal } from '@mui/material'
import React from 'react'

const MessageModal = ({ open, setOpen, message, handleSubmit, length, showBtn }) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "8px",
    };
    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="vrufyerv">
                <Box sx={style} style={{ overflowY: "auto" }}>
                    <div className="de_pad">
                        <h3>{message}</h3>
                        {
                            length ?
                                <p>{length} record selected</p> : null
                        }
                        <div className="de_hr">
                            <div className='de_btn_cont'>
                                {
                                    showBtn === false ? null :
                                        <Button className='de_cancel' onClick={() => { setOpen(false) }} >No</Button>
                                }
                                <Button className='de_next' onClick={handleSubmit} >{showBtn === false ? "Go Back" : "Yes"}</Button>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>
        </Modal>
    )
}

export default MessageModal
