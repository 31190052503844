import React, { useEffect, useState } from 'react'
import { Box, Button, InputBase, Modal, Paper } from '@mui/material'
import { APIUrlThree, APIUrlToken } from '../../Utils/Utils';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../Loader/Loader';
import FormControlProfile from '../FormControlProfile/Index';
import { toast } from 'react-toastify';

const LeadsProspectModal = ({ requestUpdateRecord, setRequestUpdateRecord, profileData, peopleId }) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "8px",
    };

    const [loading, setLoading] = useState(false)
    const [organizationName, setOrganizationName] = useState("");
    const [dropDownData, setDropDownData] = useState([])
    const [industryValue, setIndustryValue] = useState("")
    const [website, setWebsite] = useState()
    const [linkedin, setLinkedin] = useState()
    const [streetAddress, setStreetAddress] = useState()
    const [city, setCity] = useState()
    const [state, setState] = useState()
    const [numberOfEmployees, setNumberOfEmployees] = useState()
    const [revenue, setRevenue] = useState()

    const GetIndustryData = () => {
        setLoading(true);
        const option = {
            method: "GET",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "X-API-Key": APIUrlToken()
            },
            url: `${APIUrlThree()}/v1/org_filters`,
        };
        axios(option)
            .then((e) => {
                setLoading(false);
                const data = JSON.parse(e?.request?.response);

                setDropDownData(data?.data);

            })
            .catch(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        GetIndustryData()
    }, [])
    const SendtRequestUpdate = () => {
        setLoading(true);
        const data = [
            {
                "id": 0,
                "org_id": peopleId,
                "organization_name": organizationName,
                "website": website,
                "linkedin_Url": linkedin,
                "street_address": streetAddress,
                "city": city,
                "state": state,
                "industry": industryValue,
                "number_of_Employees": numberOfEmployees,
                "revenue": revenue,
                "zipCode":""
            }
        ]

        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "X-API-Key": APIUrlToken()
            },
            url: `${APIUrlThree()}/v1/save_request_update_org`,
            data: JSON.stringify(data),
        };
        axios(option)
            .then((response) => {
                setLoading(false);
                setRequestUpdateRecord(false)
                toast.success("Record update Requested Successfully")
            })
            .catch((error) => {
                setLoading(false);
            });

    }
    return (
        <>
            {loading ? <Loader /> : null}
            <Modal
                open={requestUpdateRecord}
                onClose={() => { setRequestUpdateRecord(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="vrufyerv">
                    <Box sx={style} style={{ overflowY: "auto", height: "90vh" }}>
                        <div className="de_pad">
                            <h3>Record Update Request</h3>
                            <p className='de_head'>Simply provide the information you want  updated on the record </p>

                            {/* Organization Name Field */}
                            <div className="de_input_cont">
                                <label htmlFor="" className="label-floating">
                                    Organization Name<span className="dt-en-red">({profileData?.name})</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder='Organization Name'
                                    value={organizationName}
                                    onChange={(e) => setOrganizationName(e?.target?.value)}
                                />
                            </div>
                            {/* Industry/sector */}
                            <div className="de_input_cont">
                                <label htmlFor="" className="label-floating">
                                    Industry/Sector<span className="dt-en-red">({profileData?.categories})</span>
                                </label>
                                <FormControlProfile
                                    value={industryValue}
                                    handleChange={(e) => setIndustryValue(e?.target?.value)}
                                    dataList={dropDownData?.categories}
                                />
                            </div>

                            {/* Website Field */}
                            <div className="de_input_cont">
                                <label htmlFor="" className="label-floating">
                                    Website<span className="dt-en-red">({profileData?.website_url})</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder='Website'
                                    value={website}
                                    onChange={(e) => setWebsite(e?.target?.value)}
                                />
                            </div>

                            {/* LinkedIn URL Field */}
                            <div className="de_input_cont">
                                <label htmlFor="" className="label-floating">
                                    LinkedIn URL<span className="dt-en-red">({profileData?.linkedin})</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder='LinkedIn URL'
                                    value={linkedin}
                                    onChange={(e) => setLinkedin(e?.target?.value)}
                                />
                            </div>

                            {/* Street Address Field */}
                            <div className="de_input_cont">
                                <label htmlFor="" className="label-floating">
                                    Street Address<span className="dt-en-red">({profileData?.street})</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder='Street Address'
                                    value={streetAddress}
                                    onChange={(e) => setStreetAddress(e?.target?.value)}
                                />
                            </div>

                            {/* city Field */}
                            <div className="de_input_cont">
                                <label htmlFor="" className="label-floating">
                                    City<span className="dt-en-red">({profileData?.location_identifiers?.split(",")?.[0]})</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder='City'
                                    value={city}
                                    onChange={(e) => setCity(e?.target?.value)}
                                />
                            </div>

                            {/* state Field */}
                            <div className="de_input_cont">
                                <label htmlFor="" className="label-floating">
                                    State<span className="dt-en-red">({profileData?.location_identifiers?.split(",")?.[1]})</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder='State'
                                    value={state}
                                    onChange={(e) => setState(e?.target?.value)}
                                />
                            </div>
                            {/* num of employees Field */}
                            <div className="de_input_cont">
                                <label htmlFor="" className="label-floating">
                                    Number of Employees<span className="dt-en-red">({profileData?.num_employees})</span>
                                </label>
                                <FormControlProfile
                                    value={numberOfEmployees}
                                    handleChange={(e) => setNumberOfEmployees(e?.target?.value)}
                                    dataList={dropDownData?.num_employees}
                                />
                            </div>
                            {/* Revenue Range Field */}
                            <div className="de_input_cont">
                                <label htmlFor="" className="label-floating">
                                    Revenue Range<span className="dt-en-red">({profileData?.revenue_range})</span>
                                </label>
                                <FormControlProfile
                                    value={revenue}
                                    handleChange={(e) => setRevenue(e?.target?.value)}
                                    dataList={dropDownData?.revenue_range}
                                />
                            </div>

                            <div className="de_hr">
                                <div className='de_btn_cont'>
                                    <Button className='de_cancel' onClick={() => setRequestUpdateRecord(false)}>Cancel</Button>
                                    <Button className='de_next' onClick={() => SendtRequestUpdate()} >Submit</Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </Modal>
        </>
    )
}

export default LeadsProspectModal
