import React, { useEffect, useRef, useState } from 'react';
import { Modal, Box, Button, Typography, Stack, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import "../ComingSoon/ComingSoon.css";
import { APIUrlThree, APIUrlToken, GetUserId } from "../../Utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import halflines from "../../Assets/halflines.svg";
import search from "../../Assets/search.svg";
import Loader from '../Loader/Loader';
import { ClearIcon } from '@mui/x-date-pickers';
import LabelInput from "../LabelInputFields/Index";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useLocation, useParams } from 'react-router-dom';
import download from "../../Assets/downloadcv.png";
import html2pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";
import print from "../../Assets/print.svg";
const ComingSoon = ({ dataForInformation }) => {
  const boxOneRef = useRef();
  const [loading, setLoading] = React.useState(false);
  const [feedBoxOne, setFeedBoxOne] = React.useState([]);

  const [openSearchOne, setOpenSearchOne] = useState(false);
  const [openTagModal, setOpenTagModal] = useState();
  const [dateOrderOne, setDateOrderOne] = React.useState('asc');
  const [saveSearchOne, setSaveSearchOne] = useState([""]);
  const [tagName, setTagName] = useState("");
  const [newsId, setNewsId] = useState("");
  const [expandedSummaryOne, setExpandedSummaryOne] = useState({});
  const location = useLocation();
  const isComponyScreen = location?.state?.isComponyScreen;
  const toggleSummaryOne = (index) => {
    setExpandedSummaryOne((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: "8px",
  };
  const [skipOne, setSkipOne] = useState(0)
  const [loadOne, setLoadOne] = useState(true)
  const [searchOneApi, setSearchOneApi] = useState(false)
  const { id, tag } = useParams()
  const orgId = id
  const handleTrackingdata = () => {
    setLoading(true);
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      url: `${APIUrlThree()}/v1/get_tracking?user_id=${Number(GetUserId())}`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        const data = e?.data?.data;

        const filteredData = data?.filter((i) => i?.person_id === null)?.filter(item => item?.org_id.toString() == orgId.toString())

        if (filteredData?.length) {
          setNewsId(filteredData?.length)
          getNews()
        } else {
          setNewsId()
          setLoading(false);
        }
      })
      .catch((err) => { });
  };
  useEffect(() => {
    handleTrackingdata();
  }, [isComponyScreen,dataForInformation]);
  const getNews = (type) => {
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      // url: `http://20.57.8.57:8000/v1/get_news?sort_order=asc&location=California&limit=10&skip=0`,
      url: `${APIUrlThree()}/v1/get_news?sort_order=${dateOrderOne}&people_id=${orgId}&limit=10&skip=${type == "date" ? 0 : skipOne}`,
    };
    axios(options)
      .then((e) => {
        if (e?.data?.length < 10) {
          setLoadOne(false)
        } else {
          setLoadOne(true)
        }
        if (type == "date") {
          setFeedBoxOne(e?.data)
        } else {
          setFeedBoxOne((prev) => [...prev, ...e.data])
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  useEffect(() => {
    if (skipOne > 0 && !searchOneApi) {
      getNews()
    } else if (skipOne > 0 && searchOneApi) {
      handleSearchSave()
    }
  }, [skipOne])
  useEffect(() => {
    if (dateOrderOne && !searchOneApi) {
      setSkipOne(0)
      getNews('date')
    }
  }, [dateOrderOne, searchOneApi])
  const handleSaveTags = () => {
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      data: [tagName],
      url: `${APIUrlThree()}/v1/add_tags?news_id=${openTagModal?.articleData?._id}`,
    };
    axios(options)
      .then((e) => {
        setOpenTagModal()
        setTagName("")
        getNews("tag")
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message || "An error occurred.");
      });
  }
  const handleChangeFeedBoxOne = (event) => {
    setDateOrderOne(event.target.value);
  };
  const handleSearchSave = (e) => {
    const data = [saveSearchOne];
    const skipVal = skipOne;
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
        "Content-Type": "application/json",
      },
      data: data,
      url: `${APIUrlThree()}/v1/search_news?&org_id=${orgId}&limit=10&skip=${e == 1 ? 0 : skipVal}&search_term=${saveSearchOne}`,
    };
    axios(options)
      .then((response) => {
        if (response?.data?.length < 10) {
          setLoadOne(false)
        } else {
          setLoadOne(true)
        }
        if (e == 1) {
          setSearchOneApi(true)
          setFeedBoxOne(response.data)
        } else {
          setFeedBoxOne((prev) => [...prev, ...response.data])
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message || "An error occurred.");
      });
  };
  useEffect(() => {
    if (saveSearchOne?.length > 3) {
      handleSearchSave(1)
    }
  }, [saveSearchOne])
  const HandlePrint = useReactToPrint({
    content: () => boxOneRef.current,
  });
  const handleToPDF = (item) => {
    const content = boxOneRef.current
    const options = {
      filename: "newsFeed.pdf",
      margin: 10,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        format: "letter",
        orientation: "portrait",
      },
    };
    html2pdf().set(options).from(content).save();
  };
  const truncateText = (text) => {
    return text?.length > 13 ? text?.slice(0, 13) + '...' : text;
  };
  const initialData = (item) => {
    const data = item?.split(" ")
    return `${data?.[0]?.charAt(0).toUpperCase() || ""}${data?.[1]?.charAt(0).toUpperCase() || ""}`
  }
  return (
    <>
      {loading ? <Loader /> : null}
      <div className='add-contents-cards'>
        {
          newsId ? <> {
            feedBoxOne ?
              <div className='CI-cards-content'>
                <div className='adding-content'>
                  <div className='two-head-comp'>
                    <div className="result-tags-icon-comp">
                      <div className="checkbox-result-tag-comp">
                        <input type="checkbox" />
                        <p className="result-comp">
                          {feedBoxOne?.length} Results
                        </p>
                        {/* <p className='ai-tags-comp'>AI Tags</p> */}
                      </div>
                      <div><Tooltip
                        title={
                          feedBoxOne?.boxData?.org_id
                            ? feedBoxOne.boxData.org_name
                            : feedBoxOne?.boxData?.first_name && feedBoxOne?.boxData?.last_name
                              ? `${feedBoxOne.boxData.first_name} ${feedBoxOne.boxData.last_name}`
                              : feedBoxOne?.boxData?.location ? feedBoxOne?.boxData?.location : feedBoxOne?.boxData?.tags
                        }
                        arrow
                        placement="top"
                      >
                        <p className="card-heading-top">
                          {feedBoxOne?.boxData?.org_id
                            ? truncateText(feedBoxOne.boxData.org_name)
                            : feedBoxOne?.boxData?.first_name && feedBoxOne?.boxData?.last_name
                              ? truncateText(`${feedBoxOne.boxData.first_name} ${feedBoxOne.boxData.last_name}`)
                              : feedBoxOne?.boxData?.location ? truncateText(feedBoxOne?.boxData?.location) :
                                truncateText(feedBoxOne?.boxData?.tags)}
                        </p>
                      </Tooltip></div>
                      <div className="copy-threedot-comp">
                        <img src={print} alt="" onClick={HandlePrint} />
                        <img src={download} alt="" onClick={() => handleToPDF(1)} />
                        {/* <CloseIcon
                          className="threedot-icon-comp"
                          onClick={() => handleRemoveFeed(feedBoxOne?.boxData)}
                        /> */}
                      </div>
                    </div>
                    <div className='result-date-icon-comp'>
                      <div className='checkbox-result-tag-comp'>
                        <img src={halflines} alt="" />
                        <p className='sorted-by-comp'>Sorted by:</p>
                        <FormControl sx={{ m: 1, minWidth: 120 }} className='date-comp-dropdown'>
                          <Select className='date-comp-select'
                            value={dateOrderOne}
                            onChange={handleChangeFeedBoxOne}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            <MenuItem value="" className='date-comp-menuitem'>
                              <em className='date-comp-em'>Date</em>
                            </MenuItem>
                            <MenuItem value="asc" className='date-comp-em'>Ascending </MenuItem>
                            <MenuItem value="desc" className='date-comp-em'>Descending </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="search_news_container">
                        <div
                          className={
                            openSearchOne
                              ? "wrap-search-news active"
                              : "wrap-search-news"
                          }
                        >
                          <input
                            type="text"
                            className={
                              openSearchOne
                                ? "input_search_news active"
                                : "input_search_news"
                            }
                            placeholder="Search..."
                            value={saveSearchOne}
                            onChange={(e) => setSaveSearchOne(e?.target?.value)}
                            autoFocus
                          />
                          {openSearchOne ? (
                            <CloseIcon
                              className="threedot-icon-comp"
                              onClick={() => {
                                setOpenSearchOne(false);
                                setSearchOneApi(false);
                                setSaveSearchOne("");
                                getNews(feedBoxOne?.boxData, "date");
                              }}
                            />
                          ) : (
                            <img
                              src={search}
                              alt=""
                              onClick={() => {
                                setOpenSearchOne(true);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div ref={boxOneRef} >
                    {feedBoxOne?.length ? <>
                      {feedBoxOne?.map((articleData, index) => (
                        <div key={index} className='rest-cont' style={{ pageBreakInside: "avoid" }}>
                          <div className='joi-circle-text-comp'>
                            <div className='jo-icon-comp'><p>{dataForInformation?.name ? initialData(dataForInformation?.name) : dataForInformation?.name}</p></div>
                            <div className='date-name-csoon'>
                              <div><p className='jobsohio-name-comp'>{articleData?.article?.title?.length > 100
                                ? articleData?.article?.title.slice(0, articleData?.article?.title.slice(0, 100).lastIndexOf(" ")) + "..."
                                : articleData?.article?.title}
                              </p></div>
                              <div className="both-news-time-comp">
                                <p className="news-comp">
                                  <a
                                    href={articleData?.article?.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {
                                      articleData?.article?.url
                                        ? (articleData.article.url.split('https://')[1]?.length > 50
                                          ? articleData.article.url.split('https://')[1].slice(0, 50) + '...'
                                          : articleData.article.url.split('https://')[1])
                                        : ''
                                    }
                                  </a>
                                  |
                                </p>                              <p className="today-comp">
                                  {new Date(
                                    articleData?.article?.date
                                  ).toLocaleDateString("en-US", {
                                    month: "long",
                                    day: "numeric",
                                    year: "numeric",
                                  })}
                                  {/* {articleData?.article?.date} */}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='content-pera-comp'>
                          <p className="bold-content-pera-comp">
                              {
                                expandedSummaryOne[index] ? (
                                  <>
                                    {articleData.summary}
                                    {articleData.summary.length > 400 && (
                                      <span
                                        onClick={() => toggleSummaryOne(index)}
                                        className="see-more-btn"
                                        style={{
                                          color: "#DA291C",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          paddingLeft:"5px"
                                        }}
                                      >
                                        Show Less
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {articleData.summary.length > 400
                                      ? `${articleData.summary.slice(0, articleData.summary.lastIndexOf(' ', 400))}`
                                      : articleData.summary}
                                    {articleData.summary.length > 400 && (
                                      <span
                                        onClick={() => toggleSummaryOne(index)}
                                        className="see-more-btn"
                                        style={{
                                          color: "#DA291C",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          paddingLeft:"5px"
                                        }}
                                      >
                                        See More
                                      </span>
                                    )}
                                  </>
                                )
                              
                            }
                            </p>
                            <div className="Intersect-jb-nf-comp">
                              {articleData?.tags?.map((tag, idx) => (
                                <p key={idx}>{tag}</p>
                              ))}
                              {/* <p className="save-button-dash" onClick={() => setOpenTagModal({ articleData: articleData, data: feedBoxOne?.boxData?.content_box_id })}>Add tag</p> */}
                            </div>
                          </div>
                          <hr className='hr-tag-ci' />
                        </div>
                      ))}
                    </> : <p className='text-center-result'>No results found</p>}
                  </div>
                </div>
                {
                  loadOne ?
                    <div className='dash-cancel-save-btns'>
                      <Button className='de_next' onClick={() => setSkipOne(skip => skip + 10)}>Load More</Button>
                    </div> : null
                }
              </div> : <div className='CI-cards-content'><div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}>Data Not Available </div></div>
          }</> : <div className='prospect-aipath' style={{ width: "100%" }}><p className="" style={{ display: "flex", justifyContent: "center", width: "100%", padding: "20px" }}>To see information in this feed, make sure to track the Organization. You can manage tracking in the Competitive Intel section. </p></div>
        }
      </div>
      {/* Modal */}
      <Modal
        open={openTagModal?.articleData?._id}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="vrufyerv">
          <Box sx={style2}>
            <div className="modal-head-heading-crossicon">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="enetr-name-heading-modal"
              >
                Add New Tag
              </Typography>
              <ClearIcon
                style={{ cursor: "pointer" }}
                onClick={() => setOpenTagModal()}
              />
            </div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <label htmlFor="" className="search-name-label-modal">
                Tag name
              </label>
              <LabelInput
                value={tagName}
                onChange={(e) => setTagName(e?.target?.value)}
              />
            </Typography>
            <Stack>
              <div className="button-cancel-save-modal">
                <Button
                  variant="type"
                  className="cancel-button-modal"
                  onClick={() => { setOpenTagModal(); setTagName("") }}
                >
                  Cancel
                </Button>
                <Button
                  variant="type"
                  className="save-button-modal"
                  onClick={() => handleSaveTags()}
                >
                  Save
                </Button>
              </div>
            </Stack>
          </Box>
        </div>
      </Modal>
    </>
  );
};
export default ComingSoon; 
