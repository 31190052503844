import React, { useState } from 'react'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
const ProspectEducation = ({eduDetails }) => {
  const [collapse, setCollapse] = useState(false)
  
  return (
    <div className='row' style={{ width: "100%", display: "flex" }}>
      <div className={!collapse ? 'exp-main-container' : 'exp-main-container-fit'}>
        <div className='exp-heading-profile' onClick={() => setCollapse(!collapse)}>
          <p>Education {collapse ? <KeyboardArrowDownIcon /> : <KeyboardArrowUp />}</p>
        </div>
        {
          !collapse && (
            <div>
              {eduDetails && eduDetails.length > 0 ? (
                eduDetails?.sort((a, b) =>
                  new Date(b.education_start_date) - new Date(a.education_start_date)
               ).map((item, index) => (
                  <div key={index} className='dates-company-cnt'>
                    <p className='school-management'>{item.school_name}</p>
                    <p className='buss-management'>{item?.education_degree_type} - {item.education_degree_name}</p>
                    <p className='buss-management'>
                      <span>{new Date(item.education_start_date).getFullYear()}</span> -
                      <span>{new Date(item.education_end_date).getFullYear()}</span>
                    </p>
                  </div>
                ))
              ) : (
                <p>No education details available</p>
              )}
            </div>
          )
        }
      </div>
    </div>
  )
}
export default ProspectEducation;