// import React from "react";
// import { FormControl, Select, MenuItem } from "@mui/material";

// const FormControlProfile = ({
//   value,
//   handleChange,
//   dataList,
//   placeholder,
//   disabled,
//   selectedValues = []  // Default value as an empty array
// }) => {
//   return (
//     <FormControl sx={{ m: 1, minWidth: 120 }} className="Limit-people-per-company">
//       <Select
//         displayEmpty
//         inputProps={{ "aria-label": "Without label" }}
//         className="select-dropdown-leads"
//         value={value}
//         onChange={handleChange}
//         placeholder={placeholder}
//         disabled={disabled}
//       >
//         <MenuItem value="" disabled className="heading-placeholder">
//           <em className="placeholder-heading">Select</em>
//         </MenuItem>
//         {dataList &&
//           dataList.map((item, index) => {
//             const isSelected = selectedValues.includes(item); // Check if item is selected
//             return (
//               <MenuItem
//                 key={index}
//                 value={item}
//                 disabled={isSelected} // Disable if selected
//                 className="listing-select-data-leads"
//               >
//                 {item} {isSelected}
//               </MenuItem>
//             );
//           })}
//       </Select>
//     </FormControl>
//   );
// };

// export default FormControlProfile;
import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";

const FormControlProfile = ({
  value,
  handleChange,
  dataList,
  placeholder="Select",
  disabled,
  selectedValues = [],
  isMultiple = false,
}) => {
  // Handle single or multiple value selection
  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    
    if (isMultiple) {
      handleChange(
        // Toggle the selected value
        newValue.includes("")
          ? []
          : typeof newValue === "string"
          ? newValue.split(",")
          : newValue
      );
    } else {
      handleChange(newValue);
    }
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} className="Limit-people-per-company">
      <Select
        displayEmpty
        multiple={isMultiple}
        inputProps={{ "aria-label": "Without label" }}
        className="select-dropdown-leads"
        value={value}
        onChange={handleSelectChange}
        placeholder={placeholder}
        disabled={disabled}
        renderValue={(selected) =>
          isMultiple && selected.length === 0
            ? placeholder
            : isMultiple
            ? selected.join(", ")
            : selected || "Select"
        }
      >
        {/* Placeholder item */}
        <MenuItem value="" disabled className="heading-placeholder">
          <em className="placeholder-heading">Select</em>
        </MenuItem>

        {/* Render options */}
        {dataList?.map((item, index) => {
          const isSelected = selectedValues.includes(item);

          return (
            <MenuItem
              key={index}
              value={item}
              className="listing-select-data-leads"
              disabled={isSelected && !isMultiple}
            >
              {/* Render checkbox if isMultiple is true */}
              {isMultiple && (
                <Checkbox checked={value.includes(item)} 
                className={value.includes(item) ? "checkbox-green location_filter_checkbox_22":"checkbox-green location_filter_checkbox222"}
/>
              )}
              <ListItemText primary={item} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default FormControlProfile;

    
