import * as React from "react";
import "../Filter/LeadsFilter.css";
import Filter from "../../../Assets/FilterOval.svg";
import ReplayIcon from "@mui/icons-material/Replay";
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import { APIUrlOne, APIUrlThree, APIUrlToken, GetUserId } from "../../../Utils/Utils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Filtericon from "../../../Assets/Filtericon.svg";
import FormControlSelect from "../../FormControl/Index";
import axios from "axios";
import DoneIcon from '@mui/icons-material/Done';
import { Checkbox, FormControl, FormControlLabel, ListItemText, MenuItem, Select } from "@mui/material";
import { AI_PROFILE_FORM_TABLE } from "../../../Utils/Constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
export default function LeadsFilter({ setTableCommingData, setIstableDataFilter, applyFilterProfile,
  handleApply,
  setSelectedData,
  selectedData,
  setShowData,
  setJscore, jScoredata, showlast, setShowlast,
  setStatsCount,
  setPage,
  leadsProfileData, selectedCountries, setSelectedCountries, setSelectedProfile,
  setProfileRecords
}) {
  const [state, setState] = React.useState({ data: null, disableFields: false });
  const loggedInUserId = GetUserId();
  const [isLoadFilters, setIsLoadFilters] = React.useState(false);
  const [selectedIndustry, setSelectedIndustry] = React.useState("");
  const [selectedRegion, setSelectedRegion] = React.useState("");
  const [selectedRevenue, setSelectedRevenue] = React.useState("");
  const [selectedJscore, setSelectedJscore] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [aiLeadsFilters, setAiLeadsFilters] = React.useState({
    industry: "",
    ravenue: "",
    jScore: "",
    region: ""
  });
  const [filterRegion, setFilterRegion] = React.useState("");
  const [AiProfileData, setAiProfileData] = React.useState();
  const [AiProfileCheck, SetAiProfileCheck] = React.useState("");
  const [filterLeadData, setFilterLeadData] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openLocation, setOpenLocation] = React.useState(false)
  const [selectedRegions, setSelectedRegions] = React.useState([]);
  // const [selectedCountries, setSelectedCountries] = React.useState([]);
  const [expandedRegions, setExpandedRegions] = React.useState([]);
  const navigate = useNavigate();
  const handelAiProfileData = (event, name) => {
    let updatedChecked = [...AiProfileCheck];
    if (event.target.checked) {
      updatedChecked = [name];
    } else {
      updatedChecked = updatedChecked.filter(item => item !== name);
    }
    SetAiProfileCheck(updatedChecked);
  };
  const handleAiProFIleChange = (event) => {
    const value = event.target.value;
    setAiProfileData(value);
    handleMenuClose()
  };
  const LeadsFilters = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/org_filters`,
    };
    axios(option)
      .then((e) => {
        const data = JSON.parse(e?.request?.response);
        setAiLeadsFilters(data);
        const location = data;
        setFilterRegion(location?.data?.region);
        setExpandedRegions(Object.keys(location?.data?.region).map(region => (region)))
      })
      .catch(() => { });
  };
  React.useEffect(() => {
    if (isLoadFilters) {
      LeadsFilters();
    } else {
      setIsLoadFilters(true);
    }
  }, [isLoadFilters]);
  const handleIndustryChange = (event) => {
    const value = event.target.value;
    setSelectedIndustry(value);
  };
  const handleAccordionToggle = (region) => {
    if (expandedRegions.includes(region)) {
      setExpandedRegions(expandedRegions.filter(r => r !== region));
    } else {
      setExpandedRegions([...expandedRegions, region]);
    }
  };
  React.useEffect(() => {
    // Update selected regions based on selected countries
    const regionsWithAllCountriesSelected = Object.keys(filterRegion).filter(region =>
      filterRegion[region].every(country => selectedCountries.includes(country))
    );
    setSelectedRegions(regionsWithAllCountriesSelected);
  }, [selectedCountries, filterRegion]);
  const handleRegionChange = (region) => {
    if (selectedRegions.includes(region)) {
      // Deselect region and all its countries
      setSelectedRegions(selectedRegions.filter(r => r !== region));
      setSelectedCountries(selectedCountries.filter(country => !filterRegion[region].includes(country)));
    } else {
      // Select region and all its countries, ensuring no duplicates
      setSelectedRegions([...selectedRegions, region]);
      setSelectedCountries(prevCountries => [
        ...new Set([...prevCountries, ...filterRegion[region]])
      ]);
    }
  };
  const handleCountryChange = (country) => {
    if (selectedCountries.includes(country)) {
      // Deselect country
      setSelectedCountries(selectedCountries.filter(c => c !== country));
    } else {
      // Select country
      setSelectedCountries([...selectedCountries, country]);
    }
  };
  const isRegionSelected = (region) => selectedRegions.includes(region);
  const isCountrySelected = (country) => selectedCountries.includes(country);
  const handleRevenueChange = (event) => {
    const value = event.target.value;
    setSelectedRevenue(value);
  };
  const handleJscoreChange = (event) => {
    const value = event.target.value;
    setSelectedJscore(value);
  };
  const handeldata = (event, item) => {
    if (event.target.checked) {
      setSelectedData([...selectedData, item]);
    } else {
      setSelectedData(selectedData?.filter((uncheck) => uncheck !== item));
    }
  };
  const handeldatajScore = (event, item) => {
    if (event.target.checked) {
      setJscore([...jScoredata, item]);
    } else {
      setJscore(jScoredata.filter((uncheck) => uncheck !== item));
    }
  };
  const handeldatalast = (event, item) => {
    if (event.target.checked) {
      setShowlast([...showlast, item]);
    } else {
      setShowlast(showlast.filter((uncheck) => uncheck !== item));
    }
  };
  const ResetFilterData = () => {
    setSelectedData([]);
    setShowData([]);
    setShowlast([]);
    setJscore([]);
    setSelectedCountries([])
    setPage(1);
    setTableCommingData([]);
    setIstableDataFilter(true);
    setStatsCount('');
    setSelectedProfile([])
    setProfileRecords(false)
    handleApply("reset")
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const isDisabled = (name) =>
    AiProfileCheck?.length > 0 && !AiProfileCheck.includes(name);
  React.useEffect(() => {
    if (leadsProfileData) {
      const dataFilter = leadsProfileData?.filter((item) => item?.default === true);
      setFilterLeadData(dataFilter);
    }
  }, [leadsProfileData])
  const combinedData = [
    ...filterLeadData,
    ...leadsProfileData?.filter(item => !filterLeadData?.includes(item))
  ];
  return (
    <React.Fragment>
      <div className="main-container-ofAi-leads-filters">
        <div className="outer-main-for-filtersand-buttons">
          <section className="Leads-Filter-main-container">
            <div className="AILeads-Filter1st">
              <div>
                <div className="AiLeads-filter-icons">
                  <img src={Filter} alt="" />
                </div>
                <div>
                  <img src={Filtericon} alt="" />
                </div>
              </div>
            </div>
            <div className="AILeads-Filter2nddrop-down">
              <FormControl
                // onMouseLeave={() => setOpenLocation(false)}
                sx={{ m: 1, minWidth: 120 }}
              >
                <Select
                  open={openLocation}
                  onClose={() => setOpenLocation(false)}
                  onOpen={() => setOpenLocation(true)}
                  value={selectedRegion}
                  onChange={handleRegionChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  IconComponent={KeyboardArrowDownIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                >
                  <MenuItem className="hidedata" value="">
                    <em>Location</em>
                    {selectedCountries?.length === 0 ? (
                      <p>Select</p>
                    ) : (
                      <p>{selectedCountries?.length} Selected</p>
                    )}
                  </MenuItem>
                  <div className="AI-leader-checkbox-and-data-list-outter">
                    {Object.keys(filterRegion).map(region => (
                      <div key={region} className="leads_loc_filter_inner">
                        <div className={isRegionSelected(region) ? "AI-leader-checkbox-and-data-list location_filter_leads_cont_2" : "AI-leader-checkbox-and-data-list location_filter_leads_cont"}>
                          <div className="checkbox-select-data">
                            {/* <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isRegionSelected(region)}
                                  onChange={() => handleRegionChange(region)}
                                  className="checkbox-green location_filter_checkbox"
                                  color="success"
                                />
                              }
                              label={<MenuItem className="menuitems">{region}</MenuItem>}
                            /> */}
                            <MenuItem className="menuitems">{region}</MenuItem>
                            <span onClick={() => handleAccordionToggle(region)} ><KeyboardArrowDownIcon /></span>
                          </div>
                        </div>
                        {expandedRegions.includes(region) && (
                          <div className="location_filter_leads">
                            {filterRegion[region].map(country => (
                              <div className="AI-leader-checkbox-and-data-list" key={country}>
                                <div className="checkbox-select-data">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={isCountrySelected(country)}
                                        onChange={() => handleCountryChange(country)}
                                        className={isCountrySelected(country) ? "checkbox-green location_filter_checkbox_2" : "checkbox-green location_filter_checkbox"}
                                        color="success"
                                      />
                                    }
                                    label={<MenuItem className="menuitems">{country}</MenuItem>}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </Select>
              </FormControl>
              {/* <FormControlSelect
                formControlData={{
                  value: selectedRegion,
                  handleChange: handleRegionChange,
                  selectedData: selectedData,
                  handleCheckboxChange: handeldata,
                  // dataList: filterRegion?.data?.region,
                  checked: selectedData,
                }}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
                placeholder="Location"
              /> */}
            </div>
            <div className="AILeads-Filter2nddrop-down">
              <FormControlSelect
                formControlData={{
                  value: selectedIndustry,
                  handleChange: handleIndustryChange,
                  selectedData: selectedData,
                  handleCheckboxChange: handeldata,
                  dataList: aiLeadsFilters?.data?.categories,
                  checked: selectedData,
                }}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
                placeholder="Industry"
              />
            </div>
            <div className="Leads-Filter-Laststdrop-down">
              <FormControlSelect
                formControlData={{
                  value: selectedRevenue,
                  handleChange: handleRevenueChange,
                  selectedData: showlast,
                  handleCheckboxChange: handeldatalast,
                  isRevenue: true,
                  dataList: aiLeadsFilters?.data?.revenue_range,
                  checked: showlast,
                }}
                placeholder="Revenue"
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
              />
            </div>
            <div className="JScoreContainer">
              <FormControlSelect
                formControlData={{
                  value: selectedJscore,
                  handleChange: handleJscoreChange,
                  selectedData: jScoredata,
                  handleCheckboxChange: handeldatajScore,
                  dataList: aiLeadsFilters?.data?.j_score?.slice(1),
                  checked: jScoredata,
                }}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
                placeholder="JOI Score"
              />
            </div>
          </section>
          <div className="apply-hit-button">
            <div className="inner-apply-button-container" onClick={handleApply}>
              <DoneIcon className="apply-tick-icon" />
              <button className="AileadsFilter-Apply-button" variant="contained">
                Apply
              </button>
            </div>
          </div>
          <div className="reset-hit-button" onClick={ResetFilterData}>
            <div className="inner-rest-button-container">
              <ReplayIcon className="reset-tick-icon" />
              <button className="AileadsFilter-reset-button" variant="contained">
                Reset
              </button>
            </div>
          </div>
          {/* <div onClick={ResetFilterData} className="Reset-filter-container">
            <div className="inner-reset-filter">
              <div>
                <ReplayIcon className="reset-filter-icon" />
              </div>
              <div>
                <p>Reset</p>
              </div>
            </div>
          </div> */}
        </div>
        <div className="MyAiProfile-data-list-container">
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={AiProfileData}
              onChange={handleAiProFIleChange}
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
              inputProps={{ "aria-label": "Without label" }}
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <div>
                      <em className="setSelectProfile-ui" >AI Profile</em>
                      <p className="SelectedValue">
                        {/* {AiProfileCheck?.length === 0 ? "Select" : `Selected: ${AiProfileCheck?.length}`} */}
                      </p>              </div>
                  );
                }
                return selected;
              }}
              onOpen={handleMenuOpen}
              onClose={handleMenuClose}
            // MenuProps={{
            //   anchorEl: anchorEl,
            //   open: Boolean(anchorEl),
            //   onClose: handleMenuClose,
            // }}
            >
              <div className="AI-leade-checkbox-and-data-list-last-filter">
                <div className="checkbox-select-data">
                  {
                    combinedData?.length ? <>
                      {combinedData.map((item) => (
                        <MenuItem
                          className={`menuitems-last-filter ${isDisabled(item.name) ? 'disabled-menuitem' : ''}`}
                          key={item.id}
                          value={item.name}
                          // onClick={(event) => event.stopPropagation()}
                          sx={{
                            color: isDisabled(item.name) ? 'lightgray' : 'black',
                          }}
                          onClick={() => {
                            handleMenuClose()
                            applyFilterProfile(item);
                          }}
                        >
                          {/* <Checkbox className="Newcheckbox"
                        checked={AiProfileCheck.includes(item.name)}
                        onChange={(event) =>
                          handelAiProfileData(event, item?.name)
                        } color="success"
                        disabled={
                          AiProfileCheck.length > 0 &&
                          !AiProfileCheck.includes(item.name)
                        }
                      /> */}
                          <ListItemText primary={item.name}
                         
                          />
                        </MenuItem>
                      ))}
                    </> : <MenuItem
                      className={'menuitems-last-filter'}
                      sx={{
                        color: 'black',
                      }}
                    >
                      <ListItemText primary={"Not available"} />
                    </MenuItem>
                  }
                </div>
              </div>
            </Select>
          </FormControl>
        </div>
      </div>
    </React.Fragment>
  );
}