import React, { useState } from "react";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import { geoCentroid } from "d3-geo";
import Layout from "../../Components/Layout/Layout";
import { Box, Button, Modal, Stack } from "@mui/material";
import "../LegislativeMap/LegislativeMap.css";
import saveimg from "../../Assets/downloadcv.png"
import print from "../../Assets/print.svg"
import lmap from "../../Assets/lmap.svg"
import { useNavigate } from "react-router-dom";
const FormControlSelect = ({ value, handleChange, dataList, placeholder }) => {
    return (
        <select value={value} onChange={handleChange} className="model-select-lmap">
            <option value="" disabled>{placeholder}</option>
            {dataList.map((option) => (
                <option key={option.id} value={option.label}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};
const LegislativeMap = () => {
    const navigate=useNavigate()
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "8px",
        width: "60%"
    };
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedOption, setSelectedOption] = useState(""); 
    const optionsList = [
        { id: 1, label: "Option 1" },
        { id: 2, label: "Option 2" },
        { id: 3, label: "Option 3" }
    ];
    const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";
    const handleStateClick = (stateName) => {
        setSelectedState(stateName);
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedState(null);
    };
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const stateNumbers = {
        "Alabama": 10,
        "Alaska": 20,
        "Arizona": 5,
        "Arkansas": 18,
        "California": 32,
    };
    const colorNumbers = {
        Washington:"#336699",
        Oregon:"#3399CC",
        Idaho:"#AAE1FA",
        Montana:"#3399CC",
        Wyoming:"#336699",
        California:"#6699CC",
        Nevada:"#336699",
        Utah:"#3399CC",
        Arizona:"#94BEE5",
        Colorado:"#6699CC",
        "New Mexico":"#336699",
        Texas:"#6699CC",
        Oklahoma:"#3399CC",
        Kansas:"#005996",
        Nebraska:"#3399CC",
        "South Dakota":"#6699CC",
        "North Dakota":"#336699",
        Minnesota:"#AAE1FA",
        Iowa:"#336699",
        Missouri:"#6699CC",
        Arkansas:"#336699",
        Louisiana:"#00BDF2",
        Wisconsin:"#3399CC",
        Illinois:"#7AAFDE",
        Michigan :"#336699",
        Indiana:"#6699CC",
        Kentucky:"#336699",
        Tennessee:"#00BDF2",
        Mississippi:"#3399CC",
        Alabama:"#336699",
        Georgia:"#6699CC",
        Florida:"#3399CC",
        "South Carolina":"#AAE1FA",
        "North Carolina":"#336699",
        Virginia:"#6699CC",
        Ohio:"#94BEE5",
        "West Virginia":"#3399CC",
        "Maryland":"#00BDF2",
        Pennsylvania:"#336699",
        "New York":"#3399CC",
        Delaware:"#00BDF2",
        "New Jersey":"#6699CC",
        Vermont:"#00BDF2",
        "New Hampshire":"#C7EAFB",
        Maine:"#336699",
        Connecticut:"#6DCFF6",
        "Rhode Island":"#6699CC",
        Massachusetts:'#336699',
        Hawaii:"#336699",
        Alaska:"#6699CC"
    };
    return (
        <Layout>
        <div>
        <div className="ComingSoon-outerr-container">
          <div className="ComingSoon-inner">
            <div className="ComingSoon-inner-heading">
              <h3>Coming soon</h3>
              <p className="ComingSoon-scheduled">In JOI version scheduled for April 2025</p>
              {/* <p className="ComingSoonleftside"> (Between July 2024 and June 2025)</p> */}
{/*            
                  <Stack className="BacktoDashbaordbuttonstack" spacing={2} direction="row">
                    <Button className="BacktoDashbaordbutton" variant="outlined" onClick={() => navigate('/dashboard')}>Back to Dashboard</Button>
                  </Stack> */}
              
            </div>
          </div>
        </div>
      </div>
      </Layout>
        // <Layout>
        //     <div>
        //         <div className="lg-map-main-container">
        //             <p className="legislative-map-head">US Legislative Map</p>
        //             <p className="legislative-map-content">
        //                 Use our interactive map to monitor legislation across each US State.
        //                 Each State has the number of Year-to-Date legislative articles. Simply
        //                 click on the State to view legislative details.
        //             </p>
        //             <ComposableMap projection="geoAlbersUsa" width={1000} height={600}>
        //                 <Geographies geography={geoUrl}>
        //                     {({ geographies }) =>
        //                         geographies.map((geo) => {
        //                             const centroid = geoCentroid(geo);
        //                             const stateName = geo.properties.name;
        //                             const stateNumber = stateNumbers[stateName] || "";
        //                             const color=colorNumbers[stateName];
        //                             return (
        //                                 <g key={geo.rsmKey}>
        //                                     <Geography
        //                                         geography={geo}
        //                                         onClick={() => handleStateClick(stateName)}
        //                                         style={{
        //                                             default: { fill: `${color ? color : "#fff"}`,stroke:"#fff", strokeWidth:2},
        //                                             hover: { fill: "#3399CC", outline: "none" },
        //                                             pressed: { fill: "#3399CC", outline: "none" },
        //                                         }}
        //                                     />
        //                                     {stateNumber && centroid[0] && centroid[1] && (
        //                                         <Marker coordinates={centroid}>
        //                                             <text
        //                                                 textAnchor="middle"
        //                                                 style={{
        //                                                     fontFamily: "Arial",
        //                                                     fontSize: 22,
        //                                                     fill: "#fff",
        //                                                     stroke:"#000",
        //                                                     strokeWidth:1,
        //                                                     fontWeight:900
        //                                                 }}
        //                                             >
        //                                                 {stateNumber}
        //                                             </text>
        //                                         </Marker>
        //                                     )}
        //                                 </g>
        //                             );
        //                         })
        //                     }
        //                 </Geographies>
        //             </ComposableMap>
        //         </div>
        //         <Modal
        //             open={modalIsOpen}
        //             onClose={closeModal}
        //             aria-labelledby="modal-modal-title"
        //             aria-describedby="modal-modal-description"
        //         >
        //             <Box sx={style}>
        //                 <div className="model-main-lmap">
        //                     <div className="model-head-lmap">
        //                         <img src={lmap} alt="" />
        //                         <p>{selectedState} Legislative News</p>
        //                     </div>
        //                     <div className="model-head-lmap">
        //                         <div>
        //                             <FormControlSelect
        //                                 value={selectedOption}
        //                                 handleChange={handleSelectChange}
        //                                 dataList={optionsList}
        //                                 placeholder="Data Filter"
        //                             />
        //                         </div>
        //                         <div className="download-icon"><img src={print} alt="" /></div>
        //                         <div className="download-icon"><img src={saveimg} alt="" /></div>
        //                     </div>
        //                 </div>
        //                 <hr />
        //                 <div className="inner-model-lmap-content">
        //                     <p className="inner-title-lmap">Lorem Ipsum is simply dummy text of the printing and typesetting industry.0</p>
        //                     <div style={{ display: "flex", gap: "12px" }}><p style={{ opacity: "0.5", fontFamily: "Inter", fontSize: "13px", fontWeight: "500" }}>News</p> <p style={{ fontFamily: "Inter", fontSize: "13px", fontWeight: "500", color: "#101820" }}>Today : 10:09 AM</p></div>
        //                     <p style={{
        //                         fontFamily: "Inter", fontSize: "15px", fontWeight: "400", linHeight: "24px"
        //                     }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ..</p>
        //                     <p style={{ fontFamily: "Inter", fontSize: "15px", fontWeight: "400", lineHeight: "24px", color: "#DA291C", textDecoration: "underline" }}>Read More</p>
        //                 </div>
        //                 <div className="inner-model-lmap-content">
        //                     <p className="inner-title-lmap">Lorem Ipsum is simply dummy text of the printing and typesetting industry.0</p>
        //                     <div style={{ display: "flex", gap: "12px" }}><p style={{ opacity: "0.5", fontFamily: "Inter", fontSize: "13px", fontWeight: "500" }}>News</p> <p style={{ fontFamily: "Inter", fontSize: "13px", fontWeight: "500", color: "#101820" }}>Today : 10:09 AM</p></div>
        //                     <p style={{
        //                         fontFamily: "Inter", fontSize: "15px", fontWeight: "400", linHeight: "24px"
        //                     }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ..</p>
        //                     <p style={{ fontFamily: "Inter", fontSize: "15px", fontWeight: "400", lineHeight: "24px", color: "#DA291C", textDecoration: "underline" }}>Read More</p>
        //                 </div>
        //                 <div className="inner-model-lmap-content">
        //                     <p className="inner-title-lmap">Lorem Ipsum is simply dummy text of the printing and typesetting industry.0</p>
        //                     <div style={{ display: "flex", gap: "12px" }}><p style={{ opacity: "0.5", fontFamily: "Inter", fontSize: "13px", fontWeight: "500" }}>News</p> <p style={{ fontFamily: "Inter", fontSize: "13px", fontWeight: "500", color: "#101820" }}>Today : 10:09 AM</p></div>
        //                     <p style={{
        //                         fontFamily: "Inter", fontSize: "15px", fontWeight: "400", linHeight: "24px"
        //                     }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ..</p>
        //                     <p style={{ fontFamily: "Inter", fontSize: "15px", fontWeight: "400", lineHeight: "24px", color: "#DA291C", textDecoration: "underline" }}>Read More</p>
        //                 </div>
        //             </Box>
        //         </Modal>
        //     </div>
        // </Layout >
    );
};
export default LegislativeMap;