import React from 'react'
import Layout from '../../Components/Layout/Layout'
import maintenance from '../../Assets/maintenance.svg'
import "../ErrorAndMaintenance/ErrorAndMaintenance.css"
import logo from "../../Assets/jobsblack.svg"
const Maintenance = () => {
  return (
    
      <div className='error-outer'>
        <div className='mainte-banner'>
          <img src={logo} alt="img1" />
        </div>
      <div className='maintenance-main'>
        <img src={maintenance} alt="" />
        <p className='we-back'>We'll be right back!</p>
        <p className='our-website'>Our website is currently undergoing scheduled maintenance to improve your experience.
        </p>
        <p className='thankyou-maintenance'>Thank you for your patience!</p>
      </div>
      </div>
    
  )
}

export default Maintenance