import React, { useEffect, useRef, useState } from 'react'
import "./Crie.css"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, FormControl, FormControlLabel, Modal, Radio, RadioGroup, Stack, Tooltip } from '@mui/material';
import CrieMenu from './CrieMenu';
import phone from "../../../../Assets/phone.svg";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import linkedin from "../../../../Assets/linkedin.svg";
import LinkedINImage from "../../../../Assets/linkedin.svg";
import massageicon from "../../../../Assets/FrameDiff.svg";
import company from "../../../../Assets/people-logo.png";
import education from "../../../../Assets/cap-logo.png";
import experience from "../../../../Assets/jobcompany.png";
import ReplayIcon from "@mui/icons-material/Replay";
import visitLinkImage from "../../../../Assets/visit-link.svg";
import { APIUrlThree, APIUrlToken } from '../../../../Utils/Utils';
import axios from 'axios';
import { CloseOutlined } from '@mui/icons-material';
import CrieMenuIndirect from './CrieMenuIndirect';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const CrieIndirectPath = ({
    dataForInformation,
    crieData, setCrieData, crieMiddleList,
    crieDstList, crieSrcList,
    joAssociateList, setJoAssociateList,
    organizationAssociateList, setOrganizationAssociateList,
    setLoading,
    handleRadioChange, selectedValue, getCrieIndirectdata,
    middleAssociateList, setMiddleAssociateList, disabledIndirect, inDirectSummary, strengthList, setStrengthList, crieStrengthList, setIndirectCount, setModalOpenRefresh }) => {
    const [visibleItems, setVisibleItems] = useState(10);
    const itemsPerLoad = 10;
    const handleLoadMore = () => {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerLoad);
    };
    const location = useLocation();
    const isComponyScreen = location?.state?.isComponyScreen;
    const newda = {
        "source_id": "312117",
        "target_id": dataForInformation?.org_id,
        "message": "have path",
        "detail": {
            "nodes": [
                {
                    "id": 377457,
                    "joi_id": "1969447",
                    "name": "kym parks",
                    "node_type": "person",
                    "linkedin": "http://linkedin.com/in/kym-parks-89b8a4a",
                    "website": null,
                    "email": "kym.parks@atsginc.com",
                    "primary_organization": "air transport services group",
                    "in_path": [
                        "2",
                        "4",
                        "6"
                    ]
                },
                {
                    "id": 464847,
                    "joi_id": "2069192",
                    "name": "stephanie ison",
                    "node_type": "person",
                    "linkedin": "https://www.linkedin.com/in/stephanieison",
                    "website": null,
                    "email": "stephanie.ison@krush.com,stephanie@fullpointconsulting.com,ison@jobsohio.com",
                    "primary_organization": "jobsohio",
                    "in_path": [
                        "1"
                    ]
                },
                {
                    "id": 685942,
                    "joi_id": "2384112",
                    "name": "mathew willis",
                    "node_type": "person",
                    "linkedin": "https://www.linkedin.com/in/mathew-willis-6b482758",
                    "website": null,
                    "email": null,
                    "primary_organization": "r+l carriers",
                    "in_path": [
                        "0"
                    ]
                },
                {
                    "id": 912851,
                    "joi_id": "2891606",
                    "name": "sam pilger",
                    "node_type": "person",
                    "linkedin": "https://www.linkedin.com/in/sam-pilger-1143392",
                    "website": null,
                    "email": null,
                    "primary_organization": "holland",
                    "in_path": [
                        "0"
                    ]
                },
                {
                    "id": 929099,
                    "joi_id": "2907136",
                    "name": "jerrod swanton",
                    "node_type": "person",
                    "linkedin": "https://www.linkedin.com/in/jerrod-swanton-5614b480",
                    "website": null,
                    "email": null,
                    "primary_organization": "r+l carriers",
                    "in_path": [
                        "1"
                    ]
                },
                {
                    "id": 1240174,
                    "joi_id": "o_1064731",
                    "name": "holland",
                    "node_type": "organization",
                    "linkedin": "https://www.linkedin.com/company/holland/",
                    "website": "https://hollandregional.com",
                    "email": null,
                    "primary_organization": null,
                    "in_path": [
                        "0"
                    ]
                },
                {
                    "id": 396297,
                    "joi_id": "1990784",
                    "name": "matt englehart",
                    "node_type": "person",
                    "linkedin": "https://www.linkedin.com/in/matt-englehart-3b01865",
                    "website": null,
                    "email": "englehart@jobsohio.com,englehart@jobs-ohio.com",
                    "primary_organization": "jobsohio",
                    "in_path": [
                        "2"
                    ]
                },
                {
                    "id": 714861,
                    "joi_id": "2449011",
                    "name": "kymberlee parks",
                    "node_type": "person",
                    "linkedin": "https://www.linkedin.com/in/kymberlee-parks-89b8a4a",
                    "website": null,
                    "email": null,
                    "primary_organization": "air transport services group, inc.",
                    "in_path": [
                        "2",
                        "3",
                        "4",
                        "5",
                        "6"
                    ]
                },
                {
                    "id": 926641,
                    "joi_id": "2904781",
                    "name": "sue reynolds",
                    "node_type": "person",
                    "linkedin": "https://www.linkedin.com/in/suesreynolds",
                    "website": null,
                    "email": null,
                    "primary_organization": "r+l carriers",
                    "in_path": [
                        "8"
                    ]
                },
                {
                    "id": 1417142,
                    "joi_id": "o_256778",
                    "name": "air transport services group",
                    "node_type": "organization",
                    "linkedin": "http://www.linkedin.com/company/air-transport-services-group-inc.",
                    "website": "https://www.atsginc.com/index.html",
                    "email": null,
                    "primary_organization": null,
                    "in_path": [
                        "2",
                        "3",
                        "4",
                        "5",
                        "6"
                    ]
                },
                {
                    "id": 175519,
                    "joi_id": "1449732",
                    "name": "todd france",
                    "node_type": "person",
                    "linkedin": "https://www.linkedin.com/in/todd-france-71a5a78",
                    "website": null,
                    "email": "todd.france@airbornemx.com",
                    "primary_organization": "air transport services group",
                    "in_path": [
                        "5"
                    ]
                },
                {
                    "id": 920110,
                    "joi_id": "2898526",
                    "name": "stephen wilson",
                    "node_type": "person",
                    "linkedin": "https://www.linkedin.com/in/stephen-wilson-87572114",
                    "website": null,
                    "email": null,
                    "primary_organization": "r+l carriers",
                    "in_path": [
                        "7"
                    ]
                },
                {
                    "id": 1436373,
                    "joi_id": "o_312012",
                    "name": "jobsohio",
                    "node_type": "organization",
                    "linkedin": "https://www.linkedin.com/company/jobsohio/",
                    "website": "https://www.jobsohio.com",
                    "email": null,
                    "primary_organization": null,
                    "in_path": [
                        "0",
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8"
                    ]
                },
                {
                    "id": 393938,
                    "joi_id": "1988065",
                    "name": "kevin chambers",
                    "node_type": "person",
                    "linkedin": "https://www.linkedin.com/in/kevin-chambers-128a5b29",
                    "website": null,
                    "email": "kchambers@deloitte.com,chambers@jobsohio.com",
                    "primary_organization": "jobsohio",
                    "in_path": [
                        "3",
                        "4",
                        "5"
                    ]
                },
                {
                    "id": 443042,
                    "joi_id": "2044083",
                    "name": "glenn richardson",
                    "node_type": "person",
                    "linkedin": "https://www.linkedin.com/in/glenn-richardson-9b14666",
                    "website": null,
                    "email": "richardson@jobs-ohio.com,glenn.richardson@rolls-royce.com,richardson@jobsohio.com",
                    "primary_organization": "jobsohio",
                    "in_path": [
                        "6"
                    ]
                },
                {
                    "id": 454417,
                    "joi_id": "2057119",
                    "name": "charlie rowell",
                    "node_type": "person",
                    "linkedin": "https://www.linkedin.com/in/charlesrowell",
                    "website": null,
                    "email": "rowell@jobsohio.com",
                    "primary_organization": "jobsohio",
                    "in_path": [
                        "0"
                    ]
                },
                {
                    "id": 1175868,
                    "joi_id": "893623",
                    "name": "edward koharik",
                    "node_type": "person",
                    "linkedin": "https://www.linkedin.com/in/edkoharik/",
                    "website": null,
                    "email": "ed.koharik@atsginc.com",
                    "primary_organization": "air transport services group",
                    "in_path": [
                        "3"
                    ]
                },
                {
                    "id": 1502190,
                    "joi_id": "o_499678",
                    "name": "r+l carriers",
                    "node_type": "organization",
                    "linkedin": "https://www.linkedin.com/company/r-l-carriers/",
                    "website": "https://www2.rlcarriers.com",
                    "email": null,
                    "primary_organization": null,
                    "in_path": [
                        "0",
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8"
                    ]
                },
                {
                    "id": 431333,
                    "joi_id": "2030673",
                    "name": "ryan squire",
                    "node_type": "person",
                    "linkedin": "https://www.linkedin.com/in/ryansquire",
                    "website": null,
                    "email": "squire@jobsohio.com,squire@jobs-ohio.com,ryan@getmefurtherfaster.com",
                    "primary_organization": "jobsohio",
                    "in_path": [
                        "7",
                        "8"
                    ]
                }
            ],
            "path_order": { "6": ["o_312012", 2044083, 1969447, "o_256778", 2449011, "o_499678"] },
            "paths": {
                "6": [
                    {
                        "src_id": "1969447",
                        "dst_id": "o_256778",
                        "relation": "Company",
                        "weight": 29
                    },
                    {
                        "src_id": "2449011",
                        "dst_id": "o_499678",
                        "relation": "Experience",
                        "weight": 18
                    },
                    {
                        "src_id": "1969447",
                        "dst_id": "2044083",
                        "relation": "Email",
                        "weight": 21
                    },
                    {
                        "src_id": "2044083",
                        "dst_id": "o_312012",
                        "relation": "Company",
                        "weight": 100
                    },
                    {
                        "src_id": "2449011",
                        "dst_id": "o_256778",
                        "relation": "Company",
                        "weight": 29
                    }
                ]
            }
        }
    }


    const combineNodesAndEdges = (nodes, edges) => {
        const combinedArray = [];

        edges?.forEach((edge) => {
            const sourceNode = nodes?.find((node) => node.joi_id === edge.src_id);
            const targetNode = nodes?.find((node) => node.joi_id === edge.dst_id);
            if (sourceNode && targetNode) {
                combinedArray.push({
                    ...sourceNode,
                    ...edge,
                    target: targetNode.uid,
                });
            }
        });
        const lastEdgeDstId = edges[edges.length - 1]?.dst_id;
        const lastNode = nodes?.find((node) => node.joi_id === lastEdgeDstId);
        // const lastNode = nodes?.find((node) => !edges.some((edge) => edge.src_id === node.joi_id));

        if (lastNode) {
            combinedArray.push(lastNode);
        }

        return combinedArray;
    };

    const arraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        return arr1.slice().sort().toString() === arr2.slice().sort().toString();
    };

    const matchJoiIds = (newda, item) => {
        const pathOrder = newda?.detail?.path_order[item]?.map((item, index, arr) => ({
            src: item,
            dst: arr[index + 1] || null,
            joi_id: [String(item), String(arr[index + 1]) || null]
        })) || [];

        const pathsData = newda?.detail?.paths[item]?.map(i => ({
            joi_id: [String(i?.src_id), String(i?.dst_id)],
            relation: i?.relation,
            weight: i?.weight
        })) || [];

        const matchedData = pathOrder.map(orderItem => {
            const matchedPath = pathsData.find(pathItem =>
                arraysEqual(pathItem.joi_id, orderItem.joi_id)
            );

            if (matchedPath) {
                return {
                    src_id: String(orderItem.src),
                    dst_id: String(orderItem.dst),
                    relation: matchedPath.relation,
                    weight: matchedPath.weight
                };
            }
            return null;
        }).filter(item => item !== null);

        return matchedData;
    };

    // Jo Associate 
    const [isMenuJoassociate, setIsMenuJoassociate] = React.useState(false);
    const [joassociate, setJoassociate] = React.useState();

    const handleChangeJoAssociate = (item) => {
        setJoassociate(item);
        setIsMenuJoassociate(false)
    }
    const handleSearchJoAssociate = (e) => {
        if (e?.length) {
            const data = crieSrcList?.filter(item => item?.name?.toLowerCase()?.includes(e?.toLowerCase()))
            setJoAssociateList(data)
        } else {
            setJoAssociateList(crieSrcList)
        }
    }
    // Middle Associate 

    const [isMenuMiddleassociate, setIsMenuMiddleassociate] = React.useState(false);
    const [middleassociate, setMiddleassociate] = React.useState();

    const handleChangeMiddleAssociate = (item) => {
        setMiddleassociate(item);
        setIsMenuMiddleassociate(false)
    }
    const handleSearchMiddleAssociate = (e) => {
        if (e?.length) {
            const data = crieMiddleList?.filter(item => item?.name?.toLowerCase()?.includes(e?.toLowerCase()))
            setMiddleAssociateList(data)
        } else {
            setMiddleAssociateList(crieMiddleList)
        }
    }
    // Path Weight
    const [isMenuPathWeight, setIsMenuPathWeight] = React.useState(false);

    const [pathWeight, setPathWeight] = React.useState();
    const handleChangePathWeight = (item) => {
        setPathWeight(item);
        setIsMenuPathWeight(false)
    }
    //organization Associate
    const [isMenuOrganizationassociate, setIsMenuOrganizationassociate] = React.useState(false);
    const [organizationassociate, setOrganizationassociate] = React.useState();
    const handleChangeOrganizationAssociate = (item) => {
        setOrganizationassociate(item);
        setIsMenuOrganizationassociate(false)
    }
    const handleSearchOrganizationAssociate = (e) => {
        if (e?.length) {
            const data = crieDstList?.filter(item => item?.name?.toLowerCase()?.includes(e?.toLowerCase()))
            setOrganizationAssociateList(data)
        } else {
            setOrganizationAssociateList(crieDstList)
        }
    }
    useEffect(()=>{
        setJoassociate()
        setMiddleassociate()
        setOrganizationassociate()
        setPathWeight()
},[dataForInformation,isComponyScreen])


    const handleSearchCrie = () => {
        if (!joassociate?.joi_id && !organizationassociate?.joi_id && !middleassociate?.joi_id && !pathWeight?.value) {
            toast.error("Please select filters");
            return
        } else if (!joassociate?.joi_id && !organizationassociate?.joi_id) {
            toast.error("Please select either source or target employee");
            return
        }
        const data = {}
        data.source_org_id = "312117"
        data.target_org_id = String(dataForInformation?.org_id)
        if (joassociate?.joi_id) {
            data.person_source_id = [joassociate.joi_id];
        }

        if (organizationassociate?.joi_id) {
            data.person_target_id = [organizationassociate.joi_id];
        }

        if (middleassociate?.joi_id) {
            data.person_mid_id = [middleassociate.joi_id];
        }

        if (pathWeight) {
            data.condition_strength = [pathWeight?.value];
        }
        setLoading(true);
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "X-API-Key": APIUrlToken()
            },
            data: data,
            url: `${APIUrlThree()}/v1/get_indirect_path`,
        };
        axios(option)
            .then((e) => {
                setLoading(false);
                if (e?.status === 200) {
                    let data = e?.data
                    let addingAlternate = [];
                    setIndirectCount(Object.keys(data?.detail?.path_order)?.length)
                    Object.keys(data?.detail?.path_order)?.forEach((item) => {
                        const match = matchJoiIds(data, item);
                        const upcomingAlternate = combineNodesAndEdges(
                            data?.detail?.nodes,
                            match
                        );

                        let updatedAlternate = [];
                        let newAlternate = [];
                        upcomingAlternate?.forEach((item, index) => {
                            updatedAlternate.push(item);
                            if ((index + 1) % 3 === 0 || index === upcomingAlternate.length - 1) {
                                newAlternate.push(updatedAlternate);
                                updatedAlternate = [];
                            }
                        });

                        addingAlternate.push(newAlternate);
                    });
                    setCrieData(addingAlternate)
                }
            })
            .catch(() => {
                setLoading(false);
            });
    }
    const handleResetFilter = () => {
        setCrieData([])
        setJoassociate()
        setMiddleassociate()
        setOrganizationassociate()
        setPathWeight()
        setJoAssociateList(crieSrcList)
        setOrganizationAssociateList(crieDstList)
        setMiddleAssociateList(crieMiddleList)
        setStrengthList(crieStrengthList)
    }

    const handleDropdownFilters = () => {
        const data = {};
        data.summary = inDirectSummary;

        if (joassociate?.joi_id) {
            data.source_id = [joassociate.joi_id];
        }

        if (organizationassociate?.joi_id) {
            data.target_id = [organizationassociate.joi_id];
        }

        if (middleassociate?.joi_id) {
            data.mid_id = [middleassociate.joi_id];
        }

        if (pathWeight) {
            data.strength = [pathWeight?.value];
        }
        setLoading(true);
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "X-API-Key": APIUrlToken()
            },
            data: data,
            url: `${APIUrlThree()}/v1/dropdown_filter_indirect_path`,
        };
        axios(option)
            .then((e) => {
                setLoading(false);
                if (e?.status === 200) {
                    let data = e?.data
                    setMiddleAssociateList(data?.mid_nodes)
                    setOrganizationAssociateList(data?.target_nodes)
                    setJoAssociateList(data?.source_nodes)
                    setStrengthList(data.strengths?.map(item => ({
                        name: item, value: item
                    })))

                }
            })
            .catch(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (joassociate?.joi_id || middleassociate?.joi_id || organizationassociate?.joi_id || pathWeight?.value) {
            handleDropdownFilters()
        } else {
            getCrieIndirectdata()
            setCrieData([])
        }
    }, [joassociate, middleassociate, organizationassociate, pathWeight])

    const joRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (joRef.current && !joRef.current.contains(event.target)) {
                setIsMenuJoassociate(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [joRef]);
    const orgRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (orgRef.current && !orgRef.current.contains(event.target)) {
                setIsMenuOrganizationassociate(false)
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [orgRef]);
    const pathRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (pathRef.current && !pathRef.current.contains(event.target)) {
                setIsMenuPathWeight(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [pathRef]);
    const inDirectRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inDirectRef.current && !inDirectRef.current.contains(event.target)) {
                setIsMenuMiddleassociate(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [inDirectRef]);

    return (
        <div>
            <div className="crie-filter-inner-container crie-cont-2 do_not_print">
                <div className='crie-inner-drop-boxes'>
                    {/* Jo Associate */}
                    <div className='crie_outer_drop'>
                        <div
                            className='crie-drop-down'
                            style={{ textTransform: "none" }}
                            onClick={() => {
                                setIsMenuJoassociate(!isMenuJoassociate);
                                setIsMenuPathWeight(false);
                                setIsMenuOrganizationassociate(false);
                            }}
                        >
                            JO Employee<KeyboardArrowDownIcon />
                        </div>
                        <div ref={joRef}>
                            <CrieMenuIndirect
                                isMenuOpen={isMenuJoassociate}
                                alldata={joAssociateList}
                                handleCheckboxChange={handleChangeJoAssociate}
                                handleSearch={handleSearchJoAssociate}
                                isSearch={true}
                            />
                        </div>
                    </div>

                    <div className='hyphen-conn-main'>
                        <p className='line-hyphen-conn'></p>
                        <p className='hyphen-conn'>-</p>
                    </div>
                    {/* Middle Associate */}
                    <div className='crie_outer_drop'>
                        <div
                            className='crie-drop-down'
                            style={{ textTransform: "none" }}
                            onClick={() => {
                                setIsMenuMiddleassociate(!isMenuMiddleassociate);
                                setIsMenuPathWeight(false);
                                setIsMenuOrganizationassociate(false);
                            }}
                        >
                            In-Direct Connection<KeyboardArrowDownIcon />
                        </div>
                        <div ref={inDirectRef}>
                            <CrieMenuIndirect
                                isMenuOpen={isMenuMiddleassociate}
                                alldata={middleAssociateList}
                                handleCheckboxChange={handleChangeMiddleAssociate}
                                handleSearch={handleSearchMiddleAssociate}
                                isSearch={true}
                            />
                        </div>
                    </div>

                    <div className='hyphen-conn-main'>
                        <p className='line-hyphen-conn'></p>
                        <p className='hyphen-conn'>-</p>
                    </div>
                    {/* organization associate */}
                    <div className='crie_outer_drop'>
                        <div
                            className='crie-drop-down'
                            onClick={() => {
                                setIsMenuOrganizationassociate(!isMenuOrganizationassociate);
                                setIsMenuPathWeight(false);
                                setIsMenuJoassociate(false);
                            }}
                        >
                            {dataForInformation?.name} Employee<KeyboardArrowDownIcon />
                        </div>
                        <div ref={orgRef}>
                            <CrieMenuIndirect
                                isMenuOpen={isMenuOrganizationassociate}
                                alldata={organizationAssociateList}
                                handleCheckboxChange={handleChangeOrganizationAssociate}
                                handleSearch={handleSearchOrganizationAssociate}
                                isSearch={true}
                            />
                        </div>
                    </div>
                    {/* Path weight */}
                    <div className='crie_outer_drop'>
                        <div
                            className='crie-drop-down'
                            onClick={() => {
                                setIsMenuPathWeight(!isMenuPathWeight);
                                setIsMenuJoassociate(false);
                                setIsMenuOrganizationassociate(false);
                            }}
                        >
                            Path Strength<KeyboardArrowDownIcon />
                        </div>
                        <div ref={pathRef}>
                            <CrieMenuIndirect
                                isMenuOpen={isMenuPathWeight}
                                alldata={strengthList}
                                handleCheckboxChange={handleChangePathWeight}
                                isSearch={false}
                            />
                        </div>
                    </div>
                    <Button onClick={handleSearchCrie} className='crie-filter-inner-container-search'>
                        Search
                    </Button>
                    {/* Reset Filter */}
                    <div className='crie_outer_drop' >
                        <div className="inner-rest-button-crie" onClick={handleResetFilter}>
                            <ReplayIcon className="reset-tick-icon" />
                            Reset Filter
                        </div>
                    </div>
                </div>
                {
                    disabledIndirect === "processing" ?
                        <Button className='crie-filter-inner-container-crie' onClick={() => setModalOpenRefresh(true)}>
                            Refresh Indirect Paths
                        </Button> : null
                }
                {
                    disabledIndirect === "refresh" ? <div className='crie_indirect_process'>
                        <p>Indirect Paths In Process</p>
                        <Button className='crie-filter-inner-container-crie-disabled'>
                            Refresh Indirect Paths
                        </Button>
                    </div> : null
                }
            </div>
            <div className="crie-filter-inner-container cre-list-cont">
                <div className='crie-inner-drop-boxes-2'>
                    {
                        joassociate ?

                            <div className='crie_selected_names'>{joassociate?.name} <CloseOutlined onClick={() => { setJoassociate() }} /></div>

                            : null
                    }
                </div>
                <div className='crie-inner-drop-boxes-2'>
                    {
                        middleassociate ?

                            <div className='crie_selected_names'>{middleassociate?.name} <CloseOutlined onClick={() => { setMiddleassociate(); }} /></div>

                            : null
                    }
                </div>
                <div className='crie-inner-drop-boxes-2'>
                    {
                        organizationassociate ?

                            <div className='crie_selected_names'>{organizationassociate?.name} <CloseOutlined onClick={() => { setOrganizationassociate(); }} /></div>

                            : null
                    }
                </div>
                <div className='crie-inner-drop-boxes-2'>
                    {
                        pathWeight ?

                            <div className='crie_selected_names'>{pathWeight?.name} <CloseOutlined onClick={() => { setPathWeight(); }} /></div>

                            : null
                    }
                </div>
            </div>
            {/* <div className='crie-cont crie_direct'>Direct Connection Paths</div> */}
            <div style={{ padding: "20px" }}>
                <div className='path-graph-crie'>
                    {/* <div className='radio-input-crie'> <div className='direct-path-crie'><input type="radio" /><p>Direct Paths</p></div> <div className='in-direct-path-crie'><input type="radio" /><p>In-Direct Paths</p></div></div> */}
                    <div className='radio-input-crie'>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                className='rg-direct-paths'
                                onChange={(e) => handleRadioChange(e)}
                            >
                                <FormControlLabel className='direct-path-crie' checked={selectedValue === 'direct'} value="direct" control={<Radio />} label="Direct Paths" />
                                <FormControlLabel
                                    className={disabledIndirect == "pending" || disabledIndirect === "submitted" ? 'in-direct-path-crie' : 'direct-path-crie'}
                                    checked={selectedValue === 'indirect'}
                                    value="indirect"
                                    disabled={disabledIndirect == "pending" || disabledIndirect === "submitted"}
                                    control={<Radio />}
                                    label="Indirect Paths"
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    {
                        disabledIndirect === "submitted" ? <><div className='crie_path_cont'><i>Indirect Path request for this organization has already been submitted and will be available soon.</i></div></> :
                            crieData?.length ? <div className="print-div-full" >
                                {crieData.slice(0, visibleItems).map((myItem, index) => {
                                    return myItem.length ? (
                                        <>

                                            <div className="AllAIOuttercontainer-sidebar_crie" key={index} style={{ pageBreakInside: "avoid" }} >
                                                <p className='path_head'>Path {index + 1}</p>
                                                <div className="AllAi-paths-list">
                                                    {myItem?.map((item, index) => (
                                                        <>
                                                            <div className="siderbar-row">
                                                                {item?.map((i, idx) => (
                                                                    <div
                                                                        style={{ display: "flex" }}
                                                                        className="innerData_ai_cont_crie"
                                                                    >
                                                                        <div className='first_crie_box_2'>
                                                                            <div className='crie_info'>
                                                                                <div className='crie_name'>{i?.name}</div>
                                                                                <div>{i?.primary_organization?.length > 50 ? i?.primary_organization.substr(0, 50) + "..." : i?.primary_organization}</div>
                                                                                <a className='company-profile-mention'> <EmailOutlinedIcon style={{ color: "#003057", width: "15px", height: "15px" }} /> {i?.email ? i?.email.split(",")[0] : "--"}</a>
                                                                                <a className='company-profile-mention'> <img src={phone} alt="" style={{ width: "13px", height: "13px" }} />{i?.phone ? i?.phone : "--"} </a>
                                                                                <p className='company-profile-mention-2'><span>
                                                                                    <img src={linkedin} alt="" style={{ width: "14px", height: "14px" }} />{i?.linkedin ? "Linkedin Profile" : "--"}</span>
                                                                                    {i?.linkedin ? <a href={i?.linkedin} className='crie-a-end' target='_blank'> <img src={visitLinkImage} alt="" style={{ width: "14px", height: "14px" }} /></a> : <a></a>} </p>
                                                                            </div>
                                                                        </div>
                                                                        {i?.relation && (
                                                                            <div className="relation_ai_path_cont_2">
                                                                                <hr className="horizontal-line-in-aipath" />
                                                                                <div className="siderbar-image-container">
                                                                                    <div className="Siderbar-lits-images">
                                                                                        <Tooltip
                                                                                            title={
                                                                                                "Weighted Connection Strength"
                                                                                            }
                                                                                        >
                                                                                            <div className="weight-between-nodes-crie">
                                                                                                {i?.weight}
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                        <Tooltip title={"Email Exchanged"}>
                                                                                            {i?.relation?.includes(
                                                                                                "Email"
                                                                                            ) && (
                                                                                                    <img
                                                                                                        src={massageicon}
                                                                                                        alt=""
                                                                                                        className="siderbar-modal-img-crie"
                                                                                                    />
                                                                                                )}
                                                                                        </Tooltip>
                                                                                        <Tooltip
                                                                                            title={"LinkedIn Connection"}
                                                                                        >
                                                                                            {i?.relation?.includes(
                                                                                                "LinkedIn"
                                                                                            ) && (
                                                                                                    <img
                                                                                                        src={LinkedINImage}
                                                                                                        alt=""
                                                                                                        className="siderbar-modal-img-crie"
                                                                                                    />
                                                                                                )}
                                                                                        </Tooltip>
                                                                                        <Tooltip
                                                                                            title={"Working in Same Company"}
                                                                                        >
                                                                                            {i?.relation?.includes(
                                                                                                "Organization"
                                                                                            ) && (
                                                                                                    <img
                                                                                                        src={company}
                                                                                                        alt=""
                                                                                                        className="siderbar-modal-img-crie"
                                                                                                    />
                                                                                                )}
                                                                                        </Tooltip>
                                                                                        <Tooltip
                                                                                            title={"Attended Same College"}
                                                                                        >
                                                                                            {i?.relation?.includes(
                                                                                                "Education"
                                                                                            ) && (
                                                                                                    <img
                                                                                                        src={education}
                                                                                                        alt=""
                                                                                                        className="siderbar-modal-img-crie"
                                                                                                    />
                                                                                                )}
                                                                                        </Tooltip>
                                                                                        <Tooltip
                                                                                            title={
                                                                                                "Previously Worked in Same Company"
                                                                                            }
                                                                                        >
                                                                                            {i?.relation?.includes(
                                                                                                "Experience"
                                                                                            ) && (
                                                                                                    <img
                                                                                                        src={experience}
                                                                                                        alt=""
                                                                                                        className="siderbar-modal-img-crie"
                                                                                                    />
                                                                                                )}
                                                                                        </Tooltip>
                                                                                        <Tooltip
                                                                                            title={
                                                                                                "Meeting"
                                                                                            }
                                                                                        >
                                                                                            {i?.relation?.includes(
                                                                                                "Meeting"
                                                                                            ) && (
                                                                                                    <CalendarMonthIcon className="siderbar-modal-img-crie" />
                                                                                                )}
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="connecting-line"></div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {index < myItem?.length - 1 && (
                                                                <div className="container-of-joint-line-of-node2-sidebar_crie">
                                                                    <p className="top-line-of-nodes2_crie"></p>
                                                                    <div className="main-for-nodes-line2_crie"></div>
                                                                    <p className="bottom-line-of-nodes2"></p>
                                                                </div>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        </>
                                    ) : null;
                                })}

                            </div> : !joassociate?.joi_id && !organizationassociate?.joi_id && !middleassociate?.joi_id && !pathWeight?.value ? <div className='crie_path_cont'><i>Please use the filter option above to define what In-Direct paths you would like to view.</i></div> : <div className='crie_path_cont'>No Records Found !</div>
                    }
                    <div className="loadmore-pagination-section">
                        {visibleItems < crieData?.length ? <button className="btn-load-more button-loadmore-pagination crie-load-more" onClick={handleLoadMore} >Load More</button> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CrieIndirectPath
