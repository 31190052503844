import React, { useEffect, useState } from "react";
import "./Style.css";
import PropTypes, { element } from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import reloardform from "../../../../Assets/reloardform.svg";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FormControlProfile from "../../../FormControlProfile/Index";
import moment from "moment";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { Autocomplete, Chip } from "@mui/material";

export default function DetailsForm({
  prefilledData,
  setTableShowResult,
  tableShowResult,
  detailsFormData,
  setDetailsFormData,
  toggleResult,
  isFilterRetrieve,
  setResultRetrieve,
  setSkip,
}) {
  // const [inputValue, setInputValue] = useState(prefilledData?.revenue_range || "");
  // const [inputValueEmployees, setInputValueEmployees] = useState(prefilledData?.num_employees || "");
  useEffect(() => {
    if (prefilledData?.id) {
      setDetailsFormData({
        industry: prefilledData?.categories,
        ravenue: prefilledData?.revenue_range,
        noOfEmployee: prefilledData?.num_employees,
        country: prefilledData?.country,
        state: prefilledData?.state,
        ipoStatus: prefilledData?.ipo_status,
        lastFundingType: prefilledData?.last_funding_type,
        minFundingRounds: prefilledData?.num_funding_rounds_min,
        maxFundingRounds: prefilledData?.num_funding_rounds_max,
        fundingTotal: prefilledData?.last_funding_total,
        Jscore: prefilledData?.j_score,
      });
    }
  }, [prefilledData]);
  const dateFromHandler = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    setDetailsFormData({
      ...detailsFormData,
      fundingDate: formattedDate,
    });
  };
  const dateToHandler = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    setDetailsFormData({
      ...detailsFormData,
      fundingDateAt: formattedDate,
    });
  };
  const EmptyInputs = () => {
    setResultRetrieve([]);
    setTableShowResult(true);
    setSkip(0);
    setDetailsFormData({
      industry: "",
      ravenue: "",
      state: "",
      noOfEmployee: "",
      country: "",
      ipoStatus: "",
      lastFundingType: "",
      fundingTotal: "",
      fundingRounds: "",
      fundingDate: "",
      fundingDateAt: "",
      minFundingRounds: "",
      maxFundingRounds: "",
      Jscore: "",
      addedName: "",
    });
    // setInputValue("");
    // setInputValueEmployees("")
  };
  const formatDate = moment(prefilledData?.last_funding_at_from).format(
    "MM-DD-YYYY"
  );
  const formatDateTwo = moment(prefilledData?.last_funding_at_to).format(
    "MM-DD-YYYY"
  );
  const unixTimestamp = moment(formatDate, "MM-DD-YYYY").unix() * 1000;
  const unixTimeStampTwo = moment(formatDateTwo, "MM-DD-YYYY").unix() * 1000;
  const location = useLocation();

  // useEffect(() => {
  //   const data = inputValue //? inputValue?.split("-")?.map(i=>i.includes("$")? i :`$${i}`)?.join("-") :""
  //   if (location?.state?.disableFields === true) {
  //     setDetailsFormData({
  //       ...detailsFormData,
  //       ravenue: prefilledData?.revenue_range,
  //     })
  //   } else {
  //     setDetailsFormData({
  //       ...detailsFormData,
  //       ravenue: data,
  //     })
  //   }
  // }, [inputValue, prefilledData?.revenue_range]);
  // const handleAddOption = (event, newValue) => {
  //   if (newValue?.length) {
  //     setInputValue(newValue[newValue?.length - 1]);
  //   } else {
  //     setInputValue(""); // Reset if no value is selected
  //   }
  // };
  // useEffect(() => {
  //   const data = inputValueEmployees?.length ? inputValueEmployees : ""
  //   if (location?.state?.disableFields === true) {
  //     setDetailsFormData({
  //       ...detailsFormData,
  //       noOfEmployee: prefilledData?.num_employees,
  //     })
  //   } else {
  //     setDetailsFormData({
  //       ...detailsFormData,
  //       noOfEmployee: data,
  //     })
  //   }
  // }, [inputValueEmployees, prefilledData?.num_employees]);
  // const handleAddOptionEmployees = (event, newValue) => {
  //   if (newValue?.length) {
  //     setInputValueEmployees(newValue[newValue?.length - 1]);
  //   } else {
  //     setInputValueEmployees("");
  //   }
  // };

  return (
    <div className="ai-profile-form">
      <FormControl
        fullWidth
        sx={{ m: 1 }}
        variant="standard"
        className="ai-profile-form-main"
      >
        <div className="form-data-typeable">
          <div className="inner-form-data">
            <div>
              <div className="ai-prospect-details-form">
                <div className="heading-of-prospect-details">
                  <div className="prospect-text-and-questionmark">
                    <div className="details-perposal-text">
                      <p>AI Leads Details</p>
                    </div>
                    <div className="question-markimage"></div>
                  </div>
                  <div className="EmptyInputsImage">
                    {prefilledData ? (
                      ""
                    ) : (
                      <img onClick={EmptyInputs} src={reloardform} alt="" />
                    )}
                  </div>
                </div>
                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">
                    Industry
                    {prefilledData ? null : (
                      <span className="mandatoryainputs">*</span>
                    )}
                  </label>
                  <FormControlProfile
                    value={detailsFormData?.industry}
                    disabled={location?.state?.disableFields === true}
                    // disabled={prefilledData?.categories || prefilledData}
                    handleChange={(e) =>
                      setDetailsFormData({
                        ...detailsFormData,
                        industry: e,
                      })
                    }
                    dataList={isFilterRetrieve?.categories}
                  />
                </div>
                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">
                    Revenue Range
                  </label>
                  <FormControlProfile
                    value={detailsFormData?.ravenue || []} 
                    disabled={location?.state?.disableFields === true}
                    handleChange={(newValues) =>
                      setDetailsFormData({
                        ...detailsFormData,
                        ravenue: newValues, // Store the selected array
                      })
                    }
                    dataList={isFilterRetrieve?.revenue_range || []}
                    isMultiple={true}
                  />

                  {/* <Autocomplete
                    freeSolo
                    options={isFilterRetrieve?.revenue_range}
                    onChange={(event, newInputValue) => handleAddOption(event, newInputValue)}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                    disabled={location?.state?.disableFields === true}
                    className="select-dropdown-leads"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select"
                        variant="outlined"
                        className="text_ai_auto"
                      />
                    )}
                    sx={{
                      "& .MuiAutocomplete-listbox": {
                        fontSize: "14px",
                      },
                    }}
                  /> */}
                </div>
                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">
                    Number of Employees
                  </label>
                  <FormControlProfile
                    value={detailsFormData?.noOfEmployee || []} 
                    disabled={location?.state?.disableFields === true}
                    handleChange={(newValues) =>
                      setDetailsFormData({
                        ...detailsFormData,
                        noOfEmployee: newValues, // Store the selected array
                      })
                    }
                    dataList={isFilterRetrieve?.num_employees || []}
                    isMultiple={true}
                  />
                  {/* <Autocomplete
                    freeSolo
                    options={isFilterRetrieve?.num_employees}
                    onChange={(event, newInputValue) => handleAddOptionEmployees(event, newInputValue)}
                    inputValue={inputValueEmployees}
                    onInputChange={(event, newInputValue) => setInputValueEmployees(newInputValue)}
                    disabled={location?.state?.disableFields === true}
                    className="select-dropdown-leads"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select"
                        variant="outlined"
                        className="text_ai_auto"
                      />
                    )}
                    sx={{
                      "& .MuiAutocomplete-listbox": {
                        fontSize: "14px",
                      },
                    }}
                  /> */}
                </div>
                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">
                    Country
                  </label>
                  <FormControlProfile
                    value={detailsFormData?.country}
                    disabled={location?.state?.disableFields === true}
                    // disabled={prefilledData?.country || prefilledData}
                    handleChange={(e) =>
                      setDetailsFormData({
                        ...detailsFormData,
                        country: e,
                      })
                    }
                    dataList={isFilterRetrieve?.country}
                  />
                </div>
                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">

                    State
                  </label>
                  {/* <FormControlProfile
                    value={detailsFormData?.state}
                    disabled={location?.state?.disableFields===true}
                    handleChange={(e) => setDetailsFormData({ ...detailsFormData, state: e?.target?.value })}
                    dataList={detailsFormData?.country === "United States" ? isFilterRetrieve?.state : isFilterRetrieve?.ca_states}
                  /> */}
                  <FormControlProfile
                    value={detailsFormData?.state}
                    disabled={location?.state?.disableFields === true}
                    handleChange={(e) =>
                      setDetailsFormData({
                        ...detailsFormData,
                        state: e,
                      })
                    }
                    dataList={
                      detailsFormData?.country === "United States"
                        ? isFilterRetrieve?.state
                        : detailsFormData?.country === "Canada"
                          ? isFilterRetrieve?.ca_states
                          : detailsFormData?.country === "Mexico"
                            ? isFilterRetrieve?.mexico_states
                            : []
                    }
                  />
                </div>
                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">
                    IPO Status
                  </label>
                  <FormControlProfile
                    value={detailsFormData?.ipoStatus}
                    disabled={location?.state?.disableFields === true}
                    // disabled={prefilledData?.ipo_status || prefilledData}
                    handleChange={(e) =>
                      setDetailsFormData({
                        ...detailsFormData,
                        ipoStatus: e,
                      })
                    }
                    dataList={isFilterRetrieve?.ipo_status}
                  />
                </div>
                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">
                    Last Funding Type
                  </label>
                  <FormControlProfile
                    value={detailsFormData?.lastFundingType}
                    disabled={location?.state?.disableFields === true}
                    // disabled={prefilledData?.last_funding_type || prefilledData}
                    handleChange={(e) =>
                      setDetailsFormData({
                        ...detailsFormData,
                        lastFundingType: e,
                      })
                    }
                    dataList={isFilterRetrieve?.last_equity_funding_type?.sort()}
                  />
                </div>
                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">
                    Last funding date from
                  </label>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        className="form-date-picker"
                        value={dayjs(unixTimestamp)}
                        disabled={location?.state?.disableFields === true}
                        // disabled={prefilledData?.last_funding_at_from || prefilledData}
                        onChange={dateFromHandler}
                        renderInput={(params) => (
                          <TextField placeholder="" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">
                    Last funding date to
                  </label>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        className="form-date-picker"
                        value={dayjs(unixTimeStampTwo)}
                        disabled={location?.state?.disableFields === true}
                        // disabled={prefilledData?.last_funding_at_to || prefilledData}
                        onChange={dateToHandler}
                        renderInput={(params) => (
                          <TextField placeholder="" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">
                    Min No. Of Funding Rounds
                  </label>
                  <FormControlProfile
                    value={detailsFormData?.minFundingRounds}
                    disabled={location?.state?.disableFields === true}
                    // disabled={prefilledData?.num_funding_rounds_min || prefilledData}
                    handleChange={(e) =>
                      setDetailsFormData({
                        ...detailsFormData,
                        minFundingRounds: e,
                      })
                    }
                    dataList={[
                      "0",
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "10",
                      "11",
                      "12",
                      "13",
                      "14",
                      "15",
                      "16",
                      "17",
                      "18",
                      "19",
                      "20",
                    ]}
                  />
                </div>
                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">
                    Max No. Of Funding Rounds
                  </label>
                  <FormControlProfile
                    value={detailsFormData?.maxFundingRounds}
                    disabled={location?.state?.disableFields === true}
                    // disabled={prefilledData?.num_funding_rounds_max || prefilledData}
                    handleChange={(e) =>
                      setDetailsFormData({
                        ...detailsFormData,
                        maxFundingRounds: e,
                      })
                    }
                    dataList={[
                      "0",
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "10",
                      "11",
                      "12",
                      "13",
                      "14",
                      "15",
                      "16",
                      "17",
                      "18",
                      "19",
                      "20",
                    ]}
                  />
                </div>
                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">
                    Last Funding Total
                  </label>
                  <FormControlProfile
                    value={detailsFormData?.fundingTotal}
                    disabled={location?.state?.disableFields === true}
                    // disabled={prefilledData?.last_funding_total || prefilledData}
                    handleChange={(e) =>
                      setDetailsFormData({
                        ...detailsFormData,
                        fundingTotal: e,
                      })
                    }
                    dataList={isFilterRetrieve?.last_funding_total}
                  />
                </div>
                <div className="inner-main-object-select">
                  <label htmlFor="" className="label-floating">
                    JOI Score
                  </label>
                  <FormControlProfile
                    value={detailsFormData?.Jscore}
                    disabled={location?.state?.disableFields === true}
                    // disabled={prefilledData?.j_score || prefilledData}
                    handleChange={(e) =>
                      setDetailsFormData({
                        ...detailsFormData,
                        Jscore: e,
                      })
                    }
                    dataList={isFilterRetrieve?.j_score}
                    isRevenue={false}
                  />
                </div>
              </div>
            </div>
            <div>
              {
                location?.state?.disableFields === true ? null :
                  <Stack className="stack-form-button">
                    <Button
                      variant="contained"
                      className="Show-result-button-form"
                      style={{ textTransform: "none" }}
                      onClick={() => toggleResult("show")}
                    >
                      {"Show result"}
                    </Button>
                  </Stack>
              }
              {/* {prefilledData?.id && location?.state?.disableFields===true ? null :
                tableShowResult ? <Stack className="stack-form-button">
                  <Button
                    variant="contained"
                    className="Show-result-button-form"
                    style={{ textTransform: "none" }}
                    onClick={hideResult}
                  >
                    {"Hide result"}
                  </Button>
                </Stack> :
                  <Stack className="stack-form-button">
                    <Button
                      variant="contained"
                      className="Show-result-button-form"
                      style={{ textTransform: "none" }}
                      onClick={toggleResult}
                    >
                      {"Show result"}
                    </Button>
                  </Stack>
              } */}
            </div>
          </div>
        </div>
      </FormControl>
    </div>
  );
}
