import React from 'react'
import Layout from '../../Components/Layout/Layout'
import "../ErrorAndMaintenance/ErrorAndMaintenance.css"
import { Button } from '@mui/material'
import errorman from "../../Assets/errorman.svg"
import errorrod from "../../Assets/errorRod.svg"
import error404 from "../../Assets/error404.svg"
import errorline from "../../Assets/errorline.svg"
import logo from "../../Assets/jobsblack.svg"
import { useNavigate } from 'react-router-dom'

const ErrorScreen = () => {
    const navigate=useNavigate()
    return (
        <div className='error-outer'>
            <div className='mainte-banner'>
                <img src={logo} alt="img1" />
            </div>
            <div className='error-screen-main'>
                <div className='error-images'>
                    <img src={errorman} alt="" className='errorman-img' />
                    <img src={errorrod} alt="" className='errorrod-img' /> 
                    <img src={error404} alt="" className='error404-img' />
                    <img src={errorline} alt="" className='errorline-img' />
                    <div className='extra_piece'>
                        
                    </div>
                </div>
                <div className='context-error-page'>
                    <p className='page-not-found'>PAGE NOT FOUND</p>
                    <p className='link-errorpage'>The link you followed may be outdated or the page may have moved.</p>
                    <div>
                        <button className='error-return-home' onClick={()=>navigate("/")}>
                            Return Home
                        </button></div>
                </div>
            </div>
        </div>

    )
}
export default ErrorScreen