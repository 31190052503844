import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Checkbox, FormControlLabel } from "@mui/material";

const CrieMenuIndirect = ({ isMenuOpen, alldata, handleCheckboxChange, checked, handleSearch, isSearch }) => {
    return (
        <>
            {
                isMenuOpen &&
                <div className="crie-filter-inner-container-drop">
                    <div>
                        {
                            isSearch &&
                            <div className="crie_input_cont">
                                <input type="text" placeholder="Type to search" className="crie_input" onChange={(e) => handleSearch(e?.target?.value)} />
                            </div>
                        }
                        {
                            alldata?.map((item, index) => (
                                <div className="location_filter_leads_crie" onClick={()=>handleCheckboxChange(item)}>
                                    <div className="AI-leader-checkbox-and-data-list">
                                        <div className="checkbox-select-data">
                                            <MenuItem className="menuitems">{item?.name}</MenuItem>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            }
        </>
    )
}

export default CrieMenuIndirect
