import React, { useState } from 'react';
import "../../Pages/ProspectProfile/Profile.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
const ProspectExperience = ({ expDetails }) => {  
  const [collapse, setCollapse] = useState(false);
  const calculateExperience = (startDate, endDate) => {
    if (!startDate) return "";
    const start = new Date(startDate);
    const end = endDate=="9999-12-31" ? new Date(): new Date(endDate) ;
    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();
    if (months < 0) {
      years--;
      months += 12;
    }
    if (years === 0 && months === 0) return "";
    if (years === 0) return `${months} mos`;
    if (months === 0) return `${years} yrs`;
    return `${years} yrs ${months} mos`;
  };
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", { year: "numeric", month: "short" });
  };
  return (
    <div className="row" style={{ width: "100%", display: "flex" }}>
      <div className={!collapse ? "exp-main-container" : "exp-main-container-fit"}>
        <div
          className="exp-heading-profile"
          onClick={() => setCollapse(!collapse)}
        >
          <p>
            Experience {collapse ? <KeyboardArrowDownIcon /> : <KeyboardArrowUp />}
          </p>
        </div>
        {!collapse && (
          <div>
            {expDetails && expDetails.length > 0 ? (
              expDetails?.sort((a, b) =>
                new Date(b.position_start_date) - new Date(a.position_start_date)
             )?.map((item, index) => (
                <div key={index} className="dates-company-cnt">
                  <p className="ford-comp">{item?.organization_name || "Organization not available"}</p>
                  <p className="yearly-mentioned">
                    {calculateExperience(item?.position_start_date, item?.position_end_date)}
                  </p>
                  <div className="exp-position-container">
                    <div className="exp-table-dot-line">
                      <p className="exp-table-dot"></p>
                      <div className="exp-line"></div>
                    </div>
                    <div className="exp-position-content">
                      <p className="position-analyst">{item?.job_title || "-"}</p>
                      <div className="yearly-mentioned">
                        <p>{formatDate(item?.position_start_date)}</p> {item?.position_start_date ? "-" : ""}
                        <p>
                          {item?.position_end_date=="9999-12-31"
                            ? "Present"
                            : formatDate(item?.position_end_date)}
                        </p>
                        {item?.position_end_date ? <p className="dot-prospectexp"> .</p> : ""}
                        <p>
                          {calculateExperience(item?.position_start_date, item?.position_end_date)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No experience details available.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default ProspectExperience;
// import React, { useState } from 'react';
// import "../../Pages/ProspectProfile/Profile.css";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
// const ProspectExperience = ({ expDetails }) => {
//   const [collapse, setCollapse] = useState(false);
//   const calculateExperience = (startDate, endDate) => {
//     const start = new Date(startDate);
//     const end = endDate ? new Date(endDate) : new Date();
//     let years = end.getFullYear() - start.getFullYear();
//     let months = end.getMonth() - start.getMonth();
  
//     if (months < 0) {
//       years--;
//       months += 12;
//     }
  
//     if (years === 0 && months === 0) return "";
//     if (years === 0) return `${months} mos`;
//     if (months === 0) return `${years} yrs`;
  
//     return `${years} yrs ${months} mos`; 
//   };
  
//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
//   };
//   return (
//     <>
//       <div className='row' style={{ width: "100%", display: "flex" }}>
//         <div className={!collapse ? 'exp-main-container' : 'exp-main-container-fit'}>
//           <div className='exp-heading-profile' onClick={() => setCollapse(!collapse)}>
//             <p>
//               Experience {collapse ? <KeyboardArrowDownIcon /> : <KeyboardArrowUp />}
//             </p>
//           </div>
//           {!collapse && (
//             <div>
//               {expDetails && expDetails.length > 0 ? (
//                 expDetails.map((item, index) => (
//                   <div key={index} className='dates-company-cnt'>
//                     <p className='ford-comp'>{item.organization_name}</p>
//                     <p className='yearly-mentioned'>
//                       {calculateExperience(item?.position_start_date, item?.position_end_date)}
//                     </p>
//                     <div className='exp-position-container'>
//                       <div className='exp-table-dot-line'>
//                         <p className='exp-table-dot'></p>
//                         <div className='exp-line'></div>
//                       </div>
//                       <div className='exp-position-content'>
//                         <p className='position-analyst'>{item.job_title}</p>
//                         <div className='yearly-mentioned'>
//                           <p>{formatDate(item?.position_start_date)}</p> - <p>{item?.position_end_date ? formatDate(item?.position_end_date) : "Present"}</p> <p className='dot-prospectexp'>.</p> <p>{calculateExperience(item?.position_start_date, item?.position_end_date)}</p></div>
//                       </div>
//                     </div>
//                   </div>
//                 ))
//               ) : (
//                 <p>No experience details available.</p>
//               )}
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };
// export default ProspectExperience;
// import React, { useState } from 'react';
// import "../../Pages/ProspectProfile/Profile.css";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
// const ProspectExperience = ({profileData}) => {
//   const [collapse, setCollapse] = useState(false)
//   return (
//     <>
//       <div className='row' style={{ width: "100%", display: "flex" }}>
//         <div className={!collapse ? 'exp-main-container' :'exp-main-container-fit'}>
//           <div className='exp-heading-profile' onClick={()=>setCollapse(!collapse)}>
//             <p>Experience {collapse ? <KeyboardArrowDownIcon/> :<KeyboardArrowUp/>}</p>
//           </div>
//           {
//             !collapse && <div>
//             <div>
//               <p className='ford-comp'>Ford Motor Company</p>
//               <p className='yearly-mentioned'>16 yrs 10 mos</p>
//             </div>
//             <div>
//               {/* Position 1 */}
//               <div className='exp-position-container'>
//                 <div className='exp-table-dot-line'>
//                   <p className='exp-table-dot'></p>
//                   <div className='exp-line'></div>
//                 </div>
//                 <div className='exp-position-content'>
//                   <p className='position-analyst'>Chief Operating Officer</p>
//                   <p className='yearly-mentioned'>Mar 2020 - Sep 2020 • 7 mos</p>
//                 </div>
//               </div>
//               {/* Position 2 */}
//               <div className='exp-position-container'>
//                 <div className='exp-table-dot-line'>
//                   <p className='exp-table-dot'></p>
//                   <div className='exp-line'></div>
//                 </div>
//                 <div className='exp-position-content'>
//                   <p className='position-analyst'>President, New Businesses, Technology & Strategy</p>
//                   <p className='yearly-mentioned'>May 2019 - Mar 2020 • 11 mos</p>
//                 </div>
//               </div>
//               {/* Position 3 */}
//               <div className='exp-position-container'>
//                 <div className='exp-table-dot-line'>
//                   <p className='exp-table-dot'></p>
//                 </div>
//                 <div className='exp-position-content'>
//                   <p className='position-analyst'>Chief Executive Officer</p>
//                   <p className='yearly-mentioned'>Oct 2020 - Present • 3 yrs 10 mos Dearborn, Michigan</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//           }
         
//         </div>
//       </div>
//     </>
//   )
// }
// export default ProspectExperience;