import * as React from "react";
import "./AiLeadsAction.css";
import Button from "@mui/material/Button";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StyledMenuInput from "../../StyledMenu/Index";
import { GetLeadsFilterStatsData, GetStatsData } from "../../../Utils/Utils";
import SearchIcon from '@mui/icons-material/Search';
import { InputBase, Paper } from "@mui/material";
import { AI_DECISION_MAKER, AI_PATH_VIDEO, AI_PROFILE_VIDEO, ARCHIVE_VIDEO, CRIE_VIDEO, DATA_ENRICH_VIDEO, FAVORITES_VIDEO, HELP_CENTER_VIDEO, PROSPECT_DETAILS_VIDEO, PUSH_TO_JOSF_VIDEO, UNIVERSAL_SEARCH_VIDEO } from "../../../Utils/Constants";
import TrainingModal from "../../Training/TrainingModal";
import video from "../../../Assets/video.svg"
import videodash from "../../../Assets/play-button.png"
import { COMPETITIVE_INTEL_VIDEO, PERSONALIZED_DASHBOARD_VIDEO } from "../../../Utils/Constants";
export default function AiLeadsAction({
  currentLeadsLength,
  setIsSalesForceTrigger, isSalesForceTrigger,
  setIsDecisionMakerExcel, statsCount, statsCountDecisionMaker,
  setArchiveRecord, setUnArchivedRecord,
  setTrackRecord, setRequestUpdateRecord, disableRequestUpdate, setShareRecord, showSalesForce }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuSalesforce = () => {
    setIsSalesForceTrigger(true);
    setAnchorEl(null);
  };
  const handleMenuDownloadExcel = () => {
    setIsDecisionMakerExcel(true);
    setAnchorEl(null);
  }
  const handleMenuArchiveRecord = () => {
    setArchiveRecord(true);
    setAnchorEl(null);
  }
  const handleMenuUnArchiveRecord = () => {
    setUnArchivedRecord(true);
    setAnchorEl(null);
  }
  const handleMenuTrackRecord = () => {
    setTrackRecord(true);
    setAnchorEl(null);
  }
  const handleMenuShareRecord = () => {
    setShareRecord(true);
    setAnchorEl(null);
  }
  const handleMenuRequestUpdateRecord = () => {
    setRequestUpdateRecord(true);
    setAnchorEl(null);
  }
  const handleAnchoElement = () => {
    setAnchorEl(null);
  }
  React.useEffect(() => {
    if (isSalesForceTrigger) {
      setIsSalesForceTrigger(false)
    }
  }, [isSalesForceTrigger])
  const location = window?.location?.pathname;
  const statsData = GetStatsData();
  const filterStatsData = GetLeadsFilterStatsData();
  const [videoModal, setVideoModal] = React.useState(false);
  const [overviewVideoModal, setOverviewVideoModal] = React.useState(false);
  const [videoLink, setVideoLink] = React.useState("")
  const handleCloseOverview = () => {
    setOverviewVideoModal(false)
  }
  const handleVideoModal = (item) => {
    setOverviewVideoModal(true)
    if (item === 1) {
      setVideoLink(COMPETITIVE_INTEL_VIDEO);
    } else if (item === 2) {
      setVideoLink(PERSONALIZED_DASHBOARD_VIDEO);
    } else if (item === 3) {
      setVideoLink(ARCHIVE_VIDEO);
    } else if (item === 4) {
      setVideoLink(AI_PROFILE_VIDEO);
    } else if (item === 5) {
      setVideoLink(FAVORITES_VIDEO);
    } else if (item === 6) {
      setVideoLink(PUSH_TO_JOSF_VIDEO);
    } else if (item === 7) {
      setVideoLink(AI_PATH_VIDEO);
    } else if (item === 8) {
      setVideoLink(DATA_ENRICH_VIDEO);
    } else if (item === 9) {
      setVideoLink(PROSPECT_DETAILS_VIDEO);
    } else if (item === 10) {
      setVideoLink(HELP_CENTER_VIDEO);
    } else if (item === 11) {
      setVideoLink(CRIE_VIDEO);
    } else if (item === 12) {
      setVideoLink(UNIVERSAL_SEARCH_VIDEO);
    }
  }
  return (
    <>
      <section className=
        "Leades-filter-drop-down-button-main-container"
      >
        <div className="setProspects-Search-filter">
          <div className="showing-leads-head">
            {
              location === "/Archived" ? <h3>Archived Records</h3> : null
            }
            {
              location?.includes("Profile") ? <h3>Prospect Details</h3> : null
            }
            {
              location?.includes("companyprofilescreen") ? <h3>Organization Details</h3> : null
            }
            {
              location === "/BusinessRE" ? <h3>Business Retention</h3> : null
            }
            {
              location === "/favorites" ? <h3>Favorites</h3> : null
            }
            {
              currentLeadsLength && !statsCount && location === "/aileads" ?
                <>
                  <h3>Showing {currentLeadsLength} {location === "/aidecisionmakers" ? 'Prospects ' : 'leads'}</h3>
                  <p>Out of {location === "/aileads" || "/aiLeads" ? statsData?.generated_leads.toLocaleString() || '-' : null}</p>
                </> : null
            }
            {
              statsCount ?
                <>
                  <h3>Showing {currentLeadsLength} {location === "/aidecisionmakers" ? 'Prospects ' : 'leads'}</h3>
                  <p>Out of {location === "/aileads" || "/aiLeads" ? statsCount : null}</p>
                </> : null
            }
            {
              currentLeadsLength && !statsCount && location === "/aidecisionmakers" && !statsCountDecisionMaker ?
                <>
                  <h3>Showing {currentLeadsLength} {location === "/aidecisionmakers" ? 'Prospects ' : 'leads'}</h3>
                  <p>Out of {location === "/aidecisionmakers" ? statsData?.decisionmaker_count.toLocaleString() || '-' : null}</p>
                </> : null
            }
            {
              statsCountDecisionMaker ?
                <>
                  <h3>Showing {currentLeadsLength} {location === "/aidecisionmakers" ? 'Prospects ' : 'leads'}</h3>
                  <p>Out of {location === "/aidecisionmakers" ? statsCountDecisionMaker || '-' : null}</p>
                </> : null
            }
          </div>
          {/* {location === AI_DECISION_MAKER && (
            <Paper className='Ai-Prospect-Serach-data'
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
            >
              <SearchIcon className='searchicon-in-header' />
              <InputBase
            
                className='search-inner-text'
                sx={{ ml: 1, flex: 1 }}
                placeholder="by people name"
                inputProps={{ 'aria-label': 'Search google maps' }}
                autoComplete="off"
              />
            </Paper>
          )} */}
        </div>
        <div className="Leades-filter-inner-container video-space">
          <div className="video_container" onClick={() => setVideoModal(!videoModal)}>

            <img src={video} alt="video" className="videoIcon" />
            {videoModal && (
              <>
                {
                  location === "/aileads" ? <>
                    <div className="bell-icon-box-video">

                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(3)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Archive</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>
                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(4)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">AI Profile</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>
                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(5)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Favorites</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>
                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(6)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Push to JOSF</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>

                    </div>
                  </> : location === "/aidecisionmakers" ? <>
                    <div className="bell-icon-box-video">

                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(3)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Archive</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>
                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(4)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">AI Profile</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>
                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(5)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Favorites</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>
                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(6)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Push to JOSF</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>

                    </div>
                  </> : location === "/Archived" ? <>
                    <div className="bell-icon-box-video">

                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(3)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Archive</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>
                    </div>
                  </> : location?.includes("Profile") ? <>
                    <div className="bell-icon-box-video">

                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(3)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Archive</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>
                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(1)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Competitive Intel</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>
                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(5)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Favorites</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>
                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(9)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Prospect Details</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>
                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(6)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Push to JOSF</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>

                    </div>
                  </> : location === "/favorites" ? <>
                    <div className="bell-icon-box-video">

                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(5)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Favorites</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>
                    </div>
                  </> : location?.includes("companyprofilescreen") ? <>
                    <div className="bell-icon-box-video">

                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(3)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Archive</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>
                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(1)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Competitive Intel</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>
                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(11)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">CRIE</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>
                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(5)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Favorites</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>

                      <div className="bellicons-notification-container video_drop_hover" onClick={() => handleVideoModal(6)}>
                        <div className="bell-icon-outer-video">
                          {/* <div className="bell-icon-container-inner"> */}
                          <img src={videodash} alt="logo" className="video_img_drop" />
                          {/* </div> */}
                          <div className="bellicon-text">
                            <p className="bellicon-p-search">Push to JOSF</p>
                          </div>
                        </div>
                        <div className="bellicon-separator"></div>
                      </div>

                    </div>
                  </> : null

                }
              </>



            )}
          </div>
          <Button
            style={{ textTransform: "none" }}
            id="action-button"
            aria-controls={open ? "action-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleMenuClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Action
          </Button>
          <StyledMenuInput
            anchorEl={anchorEl}
            open={open}
            handleAnchoElement={handleAnchoElement}
            handleMenuSalesforce={handleMenuSalesforce}
            handleMenuDownloadExcel={handleMenuDownloadExcel}
            handleMenuArchiveRecord={handleMenuArchiveRecord}
            handleMenuUnArchiveRecord={handleMenuUnArchiveRecord}
            handleMenuTrackRecord={handleMenuTrackRecord}
            handleMenuRequestUpdateRecord={handleMenuRequestUpdateRecord}
            disableRequestUpdate={disableRequestUpdate}
            handleMenuShareRecord={handleMenuShareRecord}
            showSalesForce={showSalesForce} />
        </div>
      </section>
      <TrainingModal overviewVideoModal={overviewVideoModal} handleCloseOverview={handleCloseOverview} videoLink={videoLink} />

    </>
  );
}