import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useState } from 'react'
import reload from "../../Assets/reloardform.svg"
import deleteIcon from "../../Assets/Delete.svg"
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'
import { APIUrlThree, APIUrlToken } from '../../Utils/Utils'
import axios from 'axios'

const OrganizationTracking = ({ organizationData, handleRefreshTrackingdata,handleDeleteTracking,setLoading }) => {
    const navigate=useNavigate()
  const informationData = (item) => {
          navigate(`/companyprofilescreen/${item?.org_id}/0`);
  };
    return (
        <div className='table-enrich'>
            <h3 className='track_head'>Organization Tracking List</h3>
            <TableContainer component={Paper} className="DecisionMaker-table-main">
                <Box
                    sx={{ margin: 1 }}
                    id="scrollableDiv"
                    className="ai-leads-table-inner_favourites"

                >
                    <Table aria-label="collapsible table" className="ai-leads-table">
                        <TableHead>
                            <TableRow className="table-row-ai-leads">
                                <TableCell className="score-row-table">Name</TableCell>
                                <TableCell className="score-row-table">Website</TableCell>
                                <TableCell className="score-row-table">Created Date</TableCell>
                                <TableCell className="score-row-table">Expiration Date</TableCell>
                                <TableCell className="score-row-table"></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                organizationData?.map((item, index) => (
                                    <TableRow className="table-row-ai-leads">
                                        <TableCell className="checkbox-row-table-inner checkbox-row-table cursor_pointer">
                                            <div className="enrich-table-content" onClick={()=>informationData(item)}>
                                                <p>{item?.organization_name}</p>
                                            </div>
                                        </TableCell>
                                        <TableCell className="checkbox-row-table-inner checkbox-row-table">
                                            <div className="enrich-table-content">
                                                <p>{item?.organization_website}</p>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                        >
                                            <div className="enrich-table-content">
                                                <p>{moment(item?.updated_at).format("DD MMM YYYY")}</p>
                                            </div>

                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                        >
                                            <div className="enrich-table-content">
                                                <p>{moment(item?.track_until).format("DD MMM YYYY")}</p>
                                            </div>

                                        </TableCell>
                                        <TableCell align="right" className="three-dot-cell">
                                            <span className="threeDotIcon-table-profile" onClick={()=>handleDeleteTracking(item)} >
                                                <img src={deleteIcon} alt="" />
                                            </span>
                                            <span className="threeDotIcon-table-profile" onClick={() => { handleRefreshTrackingdata(item) }} >
                                                <img src={reload} alt="" />
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }

                        </TableBody>
                    </Table>
                </Box>
            </TableContainer>
        </div>
    )
}

export default OrganizationTracking
