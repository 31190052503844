import * as React from "react";
import "./Style.css";
import Filtericon from "../../../Assets/Filtericon.svg";
import Filter from "../../../Assets/FilterOval.svg";
import ReplayIcon from "@mui/icons-material/Replay";
import FormControlSelect from "../../FormControl/Index";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import axios from "axios";
import Loader from "../../Loader/Loader";
import { APIUrlOne, APIUrlThree, APIUrlToken } from "../../../Utils/Utils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DoneIcon from '@mui/icons-material/Done';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { AI_PROSPECT_PROFILE } from "../../../Utils/Constants";
import { useNavigate } from "react-router-dom";
export default function LeadsFilter({
  setTableCommingData,
  setIstableDataFilter,
  setSelectedData,
  selectedData,
  setShowData,
  showData,
  setlastdata,
  lastdata,
  setStatsCountDecisionMaker,
  duplicateHandlePass,
  AiProspectData,
  selectedSeniorityData,setSkip,
  setSelectedSeniorityData, selectedCountries, setSelectedCountries,handleApplyProfile,setProfileRecords,setSelectedProfile
}) {
  const [selectedIndustry, setSelectedIndustry] = React.useState("");
  const [selectedSeniority, setSelectedSeniority] = React.useState("");
  const [selectedCompany, setSelectedCompany] = React.useState("");

  const [aiDecisionMaker, setAiDecisionMaker] = React.useState({
    region: ""
  });
  const [aidecisiondata, setaidecisiondata] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [AiProfileData, setAiProfileData] = React.useState();
  const [AiProfileCheck, SetAiProfileCheck] = React.useState("");
  const [selectedRegion, setSelectedRegion] = React.useState("");
  const [openLocation, setOpenLocation] = React.useState(false)
  const [selectedRegions, setSelectedRegions] = React.useState([]);
  const [filterRegion, setFilterRegion] = React.useState("");
  const [expandedRegions, setExpandedRegions] = React.useState([]);
  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setShowData([item]);
    } else {
      setShowData([]);
    }
  };
  const [loading, setLoading] = React.useState(false);
  const [strength, setStrength] = React.useState([]);
  const [isLoadMakerFilters, setIsLoadMakerFilters] = React.useState(false);
  const [FilterLeadData, setFilterLeadData] = React.useState([])
  const navigate = useNavigate();
  const handleSeniorityChange = (event) => {
    const value = event.target.value;
    setSelectedSeniority(value);
  };
  const handelSenioritydata = (event, item) => {
    if (event.target.checked) {
      setSelectedSeniorityData([...selectedSeniorityData, item]);
    } else {
      setSelectedSeniorityData(selectedSeniorityData?.filter((uncheck) => uncheck !== item));
    }
  };
  const handleIndustryChange = (event) => {
    const value = event.target.value;
    setSelectedIndustry(value);
  };

  const handeldata = (event, item) => {
    if (event.target.checked) {
      setSelectedData([...selectedData, item]);
    } else {
      setSelectedData(selectedData?.filter((uncheck) => uncheck !== item));
    }
  };
  const handeldatalast = (event, item) => {
    if (event.target.checked) {
      setlastdata([...lastdata, item]);
    } else {
      setlastdata(lastdata.filter((uncheck) => uncheck !== item));
    }
  };
  const handeldatashow = (event, item) => {
    if (event.target.checked) {
      setShowData([item]);
    } else {
      setShowData(showData.filter((selectedItem) => selectedItem !== item));
    }
  };
  const ResetFilterData = () => {
    setSelectedData([]);
    setShowData([]);
    setStrength([]);
    setSelectedIndustry([]);
    setlastdata([]);
    setSelectedCountries([]);
    setSelectedSeniorityData([])
    setTableCommingData([])
    setIstableDataFilter(true);
    setStatsCountDecisionMaker('');
    setSkip(0)
    setProfileRecords(false);
    setSelectedProfile([])
  };
  const aiDecisionMakerFilters = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/org_filters`,
    };
    axios(option)
      .then((e) => {
        const data = JSON.parse(e?.request?.response);
        setAiDecisionMaker(data);
        const location = data;
        setFilterRegion(location?.data?.region);
        setExpandedRegions(Object.keys(location?.data?.region).map(region => (region)))
      })
      .catch(() => { });
  };
  React.useEffect(() => {
    if (isLoadMakerFilters) {
      aiDecisionMakerFilters();
      aiDecisionMakerdropdown();
    } else
      setIsLoadMakerFilters(true)
  }, [isLoadMakerFilters]);


  const aiDecisionMakerdropdown = () => {
    setLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key":APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/people_filters`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        const data = JSON.parse(e?.request?.response);

        setaidecisiondata(data);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    aiDecisionMakerFilters();
    aiDecisionMakerdropdown();
  }, []);
  const handleAiProFIleChange = (event) => {
    const value = event.target.value;
    setAiProfileData(value);
  };
  const handelAiProfileData = (event, name) => {
    let updatedChecked = [...AiProfileCheck];
    if (event.target.checked) {
      updatedChecked = [name];
    } else {
      updatedChecked = updatedChecked.filter((item) => item !== name);
    }
    SetAiProfileCheck(updatedChecked);
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const isDisabled = (name) =>
    AiProfileCheck.length > 0 && !AiProfileCheck.includes(name);
  React.useEffect(() => {
    if (AiProspectData) {
      const dataFilter = AiProspectData?.filter((item) => item?.default === true);
      setFilterLeadData(dataFilter);
    }
  }, [AiProspectData]);
  const combinedData = [
    ...FilterLeadData,
    ...AiProspectData?.filter(item => !FilterLeadData.includes(item))
  ];
  const handleRegionChange = (region) => {
    if (selectedRegions.includes(region)) {
      // Deselect region and all its countries
      setSelectedRegions(selectedRegions.filter(r => r !== region));
      setSelectedCountries(selectedCountries.filter(country => !filterRegion[region].includes(country)));
    } else {
      // Select region and all its countries, ensuring no duplicates
      setSelectedRegions([...selectedRegions, region]);
      setSelectedCountries(prevCountries => [
        ...new Set([...prevCountries, ...filterRegion[region]])
      ]);
    }
  };
  const isRegionSelected = (region) => selectedRegions.includes(region);
  const isCountrySelected = (country) => selectedCountries.includes(country);
  const handleAccordionToggle = (region) => {
    if (expandedRegions.includes(region)) {
      setExpandedRegions(expandedRegions.filter(r => r !== region));
    } else {
      setExpandedRegions([...expandedRegions, region]);
    }
  };
  const handleCountryChange = (country) => {
    if (selectedCountries.includes(country)) {
      // Deselect country
      setSelectedCountries(selectedCountries.filter(c => c !== country));
    } else {
      // Select country
      setSelectedCountries([...selectedCountries, country]);
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <div className="DecisionMaker-filter-main-container">
        <div className="outer-main-prospect-filtersand-buttons">
          <section className="prospect-Filter-main-container">
            <div className="AILeads-Filter1st">
              <div>
                <div className="AiLeads-filter-icons">
                  <img src={Filter} alt="" />
                </div>
                <div>
                  <img src={Filtericon} alt="" />
                </div>
              </div>
            </div>
            <div className="Leads-Filter-1stdrop-down">
              <FormControl
                // onMouseLeave={() => setOpenLocation(false)}
                sx={{ m: 1, minWidth: 120 }}
              >
                <Select
                  open={openLocation}
                  onClose={() => setOpenLocation(false)}
                  onOpen={() => setOpenLocation(true)}
                  value={selectedRegion}
                  onChange={handleRegionChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  IconComponent={KeyboardArrowDownIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                >
                  <MenuItem className="hidedata" value="">
                    <em>Location</em>
                    {selectedCountries?.length === 0 ? (
                      <p>Select</p>
                    ) : (
                      <p>{selectedCountries?.length} Selected</p>
                    )}
                  </MenuItem>
                  <div className="AI-leader-checkbox-and-data-list-outter">
                    {Object.keys(filterRegion).map(region => (
                      <div key={region} className="leads_loc_filter_inner">
                        <div className={isRegionSelected(region) ? "AI-leader-checkbox-and-data-list location_filter_leads_cont_2" :"AI-leader-checkbox-and-data-list location_filter_leads_cont"}>
                          <div className="checkbox-select-data">
                            {/* <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isRegionSelected(region)}
                                  onChange={() => handleRegionChange(region)}
                                  className={isRegionSelected(region) ? "checkbox-green location_filter_checkbox_2" : "checkbox-green location_filter_checkbox"}
                                  color="success"

                                />
                              }
                              label={<MenuItem className="menuitems">{region}</MenuItem>}

                            /> */}
                            <MenuItem className="menuitems">{region}</MenuItem>
                            <span onClick={() => handleAccordionToggle(region)} ><KeyboardArrowDownIcon /></span>
                          </div>
                        </div>
                        {expandedRegions.includes(region) && (
                          <div className="location_filter_leads">
                            {filterRegion[region].map(country => (
                              <div className="AI-leader-checkbox-and-data-list" key={country}>
                                <div className="checkbox-select-data">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={isCountrySelected(country)}
                                        onChange={() => handleCountryChange(country)}
                                        className={isCountrySelected(country) ? "checkbox-green location_filter_checkbox_2":"checkbox-green location_filter_checkbox"}
                                        color="success"
                                      />
                                    }
                                    label={<MenuItem className="menuitems">{country}</MenuItem>}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </Select>
              </FormControl></div>
            <div className="Leads-Filter-1stdrop-down">

              <FormControlSelect
                formControlData={{
                  value: selectedIndustry,
                  handleChange: handleIndustryChange,
                  selectedData: selectedData,
                  handleCheckboxChange: handeldata,
                  dataList: aidecisiondata?.data?.categories,
                  checked: selectedData,
                }}
                placeholder="Industry"
              />
            </div>
            <div className="AILeads-Filter2nddrop-down">
              <FormControlSelect
                formControlData={{
                  value: selectedCompany,
                  handleChange: handleIndustryChange,
                  selectedData: showData,
                  handleCheckboxChange: handeldatashow,
                  dataList: aidecisiondata?.data?.decision_maker,
                  selectedData: showData,
                  checked: showData,
                  type: "radio",
                  dataListType: "decision_maker",
                }}
                placeholder="Decision-maker"
              />
            </div>

            <div className="AILeads-sinority-down">
              <FormControlSelect
                formControlData={{
                  value: selectedSeniority,
                  handleChange: handleSeniorityChange,
                  selectedData: selectedSeniorityData,
                  handleCheckboxChange: handelSenioritydata,
                  dataList: aiDecisionMaker?.data?.seniority,
                  checked: selectedSeniorityData,
                  type: "radio",
                  dataListType: "Seniority",
                }}
                placeholder="Seniority"
              />
            </div>


            <div className="Leads-Filter-Laststdrop-down">
              <FormControlSelect
                formControlData={{
                  value: selectedIndustry,
                  handleChange: handleIndustryChange,
                  selectedData: lastdata,
                  handleCheckboxChange: handeldatalast,
                  dataList: aidecisiondata?.data?.josf_status,
                  checked: lastdata,
                  dataListType: "josf_status",
                  type: "radio",
                }}
                placeholder="JOSF Status"
              />
            </div>
          </section>
          <div className="apply-hit-button">
            <div
              className="inner-apply-button-container"
              onClick={duplicateHandlePass}
            >
              <DoneIcon className="apply-tick-icon" />
              <button className="AileadsFilter-Apply-button" variant="contained">
                Apply
              </button>
            </div>
          </div>
          <div className="reset-hit-button" onClick={ResetFilterData}>
          <div className="inner-rest-button-container">
              <ReplayIcon className="reset-tick-icon" />
              <button className="AileadsFilter-reset-button" variant="contained">
                Reset
              </button>
            </div>
          </div>
        </div>
        <div className="MyAiProfile-data-list-container">
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={AiProfileData}
              onChange={handleAiProFIleChange}
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
              inputProps={{ "aria-label": "Without label" }}
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <div>
                      <em className="setSelectProfile-ui">AI Profile</em>
                      <p className="SelectedValue">
                        {/* {AiProfileCheck?.length === 0 ? "Select" : `Selected: ${AiProfileCheck?.length}`} */}
                      </p>              </div>
                  );
                }
                return selected;
              }}
              onOpen={handleMenuOpen}
              onClose={handleMenuClose}
            // MenuProps={{
            //   anchorEl: anchorEl,
            //   open: Boolean(anchorEl),
            //   onClose: handleMenuClose,
            // }}
            >
              <div className="AI-leade-checkbox-and-data-list-last-filter">
                <div className="checkbox-select-data">
                  {
                    combinedData?.length ? <>
                      {combinedData.map((item) => (
                        <MenuItem
                          className={`menuitems-last-filter ${isDisabled(item.name) ? 'disabled-menuitem' : ''}`}
                          key={item.id}
                          value={item.name}
                          // onClick={(event) => event.stopPropagation()}
                          onClick={() => handleApplyProfile(item)}
                          sx={{
                            color: isDisabled(item.name) ? 'lightgray' : 'black',
                          }}
                        >
                          <ListItemText primary={item.name}  />
                        </MenuItem>
                      ))}
                    </> : <MenuItem
                      className={'menuitems-last-filter'}
                      sx={{
                        color: 'black',
                      }}
                    >
                      <ListItemText primary={"Not available"} />
                    </MenuItem>
                  }

                </div>
              </div>
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  );
}