import { Box, Modal } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';

const TrainingModal = ({overviewVideoModal,handleCloseOverview,videoLink}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #ffffff',
        boxShadow: 24,
        p: 4,
      };
  return (
    <div>
    <Modal
    open={overviewVideoModal}
    onClose={handleCloseOverview}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className="modal-joiTraining-videos"
  >
    <Box sx={style} className="box-video-modals">
      <div className="box-section-relative">
        <div className="cross-icon-section" onClick={handleCloseOverview}>
          <CloseIcon />
        </div>
        <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
          <iframe
            src={videoLink}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
            title="JOI PILOT Overview"
          ></iframe>
        </div>
      </div>
    </Box>
  </Modal>
  </div>
  )
}

export default TrainingModal
