import React, { useEffect, useState } from "react";
import "../../Pages/ProspectProfile/Profile.css";
import star from "../../Assets/Stargrey.png";
import phone from "../../Assets/phone.svg";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import linkedin from "../../Assets/linkedin.svg";
import visitLinkImage from "../../Assets/visit-link.svg";
import decisionMakerImage from "../../Assets/Cloudfigma.svg";
import { APIUrlOne, APIUrlThree, APIUrlToken, APIUrlTwo, GetUserId } from "../../Utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import starRed from "../../Assets/starRed.svg";
import startgray from "../../Assets/Stargrey.png";
import MessageModal from "../MessageModal/MessageModal";
const ProspectDetails = ({
  strengthData,
  setIsSalesForceTrigger,
  isSalesForceTrigger,
  profileData,
  archiveRecord,
  setArchiveRecord,
  peopleId,
  trackRecord,
  setTrackRecord,
  setLoading,
  shareRecord,
  setShareRecord,
  organizationData,
}) => {  
  const [favoritesList, setFavoritesList] = React.useState([]);
  const [messageModal, setMessageModal] = useState(false);
  const [messageModalTrack, setMessageModalTrack] = React.useState(false);
  const [messageModalSalesforce, setMessageModalSalesforce] =
    React.useState(false);
  const handleFavouriteLead = (row) => {
    const data = {
      user_id: Number(GetUserId()),
      people_id: Number(peopleId),
    };
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken(),
      },
      data: data,
      url: `${APIUrlThree()}/v1/save_favourite_prospect`,
    };
    axios(option)
      .then(async (response) => {
        listFavoriteRecords();
        toast.success("Prospect added to favorites");
      })
      .catch(() => { });
  };
  const handleRemoveFavouriteLead = () => {
    const data = favoritesList?.find(
      (item) => item?.person_id == Number(peopleId)
    );
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken(),
      },
      url: `${APIUrlThree()}/v1/remove_favourite_prospect?id=${data?.favourites_id
        }`,
    };
    axios(option)
      .then(async (response) => {
        listFavoriteRecords();
        toast.success("Prospect removed from favorites");
      })
      .catch(() => { });
  };
  const listFavoriteRecords = async () => {
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken(),
      },
      url: `${APIUrlThree()}/v1/list_favourite_prospects?userid=${Number(
        GetUserId()
      )}&limit=200&offset=0`,
    };
    try {
      const response = await axios(options);
      setFavoritesList(response?.data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
    }
  };
  useEffect(() => {
    listFavoriteRecords();
  }, []);
  React.useEffect(() => {
    if (archiveRecord == true) {
      setMessageModal(true);
      setArchiveRecord(false);
      // handleArchiveRecords()
    }
  }, [archiveRecord]);
  const handleArchiveRecords = async () => {
    setLoading(true);
    const data = [
      {
        user_id: Number(GetUserId()),
        people_id: Number(peopleId),
      },
    ];
    const options = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken(),
      },
      data: data,
      url: `${APIUrlThree()}/v1/send_archive`,
    };
    try {
      const response = await axios(options);
      setArchiveRecord(false);
      setMessageModal(false);
      setLoading(false);
      if(response?.data?.message==="Already archived"){
        toast.success("Prospects already Archived.")
        
      }else{
        
        toast.success("Prospects Archived Successfully")
      }    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
    }
  };
  const handleTrackRecords = () => {
    setLoading(true);
    const data = [peopleId];
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "X-API-Key": APIUrlToken(),
      },
      data: data,
      url: `${APIUrlThree()}/v1/save_tracking_prospect?user_id=${Number(
        GetUserId()
      )}`,
    };
    axios(option)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setTrackRecord(false);
          setMessageModalTrack(false);
          toast.success("Tracking Added");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };
  React.useEffect(() => {
    if (trackRecord == true) {
      setMessageModalTrack(true);
      setTrackRecord(false);
    }
  }, [trackRecord]);
  React.useEffect(() => {
    if (shareRecord === true) {
      const subject = encodeURIComponent(
        `${profileData?.first_name ? profileData?.first_name : "-"} ${profileData?.last_name ? profileData?.last_name : "-"
        } profile`
      );
      const body =
        encodeURIComponent(`Please find the attached link.`) +
        "%0D%0A" +
        encodeURIComponent(`${window.location.href}`);
      const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${subject}&body=${body}`;
      setShareRecord(false);
      window.open(gmailLink, "_blank");
    }
  }, [shareRecord]);
  const pushToSalesForce = () => {
    setLoading(true);
    const ms = {
      updateData: {
        data: [
          {
            items: [profileData?.person_id, "Suspect"],
          },
        ],
      },
    };
    const allData = [profileData];
    const transformedData = allData
      ?.map((i) => {
        const organizationInfo =
          allData.find((org) => org?.org_id === i?.org_id) || {};
        return { ...i, organizationInfo };
      })
      ?.map((item) => ({
        attributes: {
          type: "Lead",
          referenceId: "rec" + item?.person_id,
        },
        LastName: item?.last_name,
        FirstName: item?.first_name,
        Salutation: null,
        Title: item.primary_job_title,
        Company: item.primary_organization,
        City: item?.organizationInfo?.location_identifiers?.split(",")[0],
        State: item?.organizationInfo?.location_identifiers?.split(",")[1],
        Country: item?.organizationInfo?.country,
        LeadSource: "JOI",
        Status: "Suspect",
      }));
    const finalJson = { records: transformedData, ...ms };
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken(),
      },
      data: finalJson,
      url: `${APIUrlOne()}/v1/add_suspects`,
    };
    axios(option)
      .then(async (response) => {
        setLoading(false);
        if (response?.status === 200) {
          toast.success("Record has been created");
          setMessageModalSalesforce(false)
          setIsSalesForceTrigger(false)
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message || "An Error occurred");
      });
  };
  React.useEffect(() => {
    if (isSalesForceTrigger) {
      setMessageModalSalesforce(true);
      // pushToSalesForce();
    }
  }, [isSalesForceTrigger]);
  return (
    <div className="p-details-main" style={{ width: "100%" }}>
      <div className="salesforce-plus-names col-md-3" style={{ width: "25%" }}>
        <div
          className={
            profileData?.decision_maker === true
              ? "Decision-maker-user-name-profile"
              : "Decision-maker-user-noborder create-name-img border-change-profile"
          }
        >
          <img
            className={
              profileData?.suspect_status === null
                ? "hide-image"
                : "decisionMakerImageAi-Decisionmaker-pd-profile"
            }
            src={decisionMakerImage}
            alt=""
          />
          <p className="letter-heading">
            {profileData?.first_name
              ? profileData?.first_name
                ?.split(" ")
                ?.find((item) => !item.includes("("))
                ?.charAt(0)
              : ""}
            {profileData?.last_name
              ? profileData?.last_name
                ?.split(" ")
                ?.find((item) => !item.includes("("))
                ?.charAt(0)
              : ""}
          </p>
        </div>
        <div className="profiles-contents">
          <p className="name-profile-mention-prospects">
            {profileData?.first_name ? profileData?.first_name : "-"}&nbsp;
            {profileData?.last_name ? profileData?.last_name : "-"}
          </p>
          <p className="company-profile-mention-b1">
            {profileData?.primary_job_title ? profileData?.primary_job_title : "-"}
          </p>
          <p className="company-profile-mention-b1">
            {profileData?.primary_organization ? profileData?.primary_organization : "-"}
          </p>
        </div>
      </div>
      <div className="" style={{ width: "25%" }}>
        <div className="profiles-contents">
          <p className="company-profile-mention">
            <EmailOutlinedIcon
              style={{ color: "#003057", width: "16px", height: "16px" }}
            />
            {profileData?.email ? profileData?.email : "-"}
          </p>
          <p className="company-profile-mention">

            <img src={phone} alt="" style={{ width: "16px", height: "16px" }} />
            {profileData?.phone_no ? profileData?.phone_no : "-"}
          </p>
          <div className="company-profile-mention-main">
            {/* <div className='company-profile-mention-b1'><img src={linkedin} alt="" style={{ width: "17px", height: "17 px" }} />{profileData?.linkedin ? profileData?.linkedin : "-"} </div> */}
            <div className="company-profile-mention-b1">
              <img
                src={linkedin}
                alt=""
                style={{ width: "17px", height: "17px" }}
              />
              {profileData?.linkedin ? profileData.linkedin.split(".com/")?.[1] : "-"}
            </div>
            <div>
              <a href={profileData?.linkedin} target="_blank">
                <img
                  src={visitLinkImage}
                  alt=""
                  style={{ width: "17px", height: "15px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="" style={{ width: "25%" }}>
        <div className="profiles-contents">
          <p className="com-details-profile">
            JOI Strength: <p>{strengthData ? strengthData : "-"}</p> 
          </p>
          <p className="com-details-profile">
            Industry:
            <p> {profileData?.categories ? profileData?.categories?.split("/(?<=,)|\s+/").map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ") : "-"}</p>
          </p>
          <p className="com-details-profile">
            JOSF Status:
            {profileData?.suspect_status ? profileData?.suspect_status : "-"}
          </p>
        </div>
      </div>
      <div className="" style={{ width: "10%" }}>
        <div className="profiles-contents-last">
          {/* <p className='com-details-profile-b3'>Select: <span className='pro-pro-span'> <input
            type="checkbox"
            onChange={(e) => setRowsSelect(e?.target?.checked)}
            checked={rowsSelect}
          /></span> </p> */}
          <p className="com-details-profile-b3">
            Add to Favorites:
            {favoritesList?.some(
              (item) => item?.person_id == Number(peopleId)
            ) ? (
              <span
                className="pro-pro-span"
                onClick={() => handleRemoveFavouriteLead(profileData)}
              >
                <img src={starRed} alt="" />
              </span>
            ) : (
              <span
                className="pro-pro-span"
                onClick={() => handleFavouriteLead(profileData)}
              >
                <img src={startgray} alt="" />
              </span>
            )}
          </p>
        </div>
      </div>
      <MessageModal
        open={messageModalSalesforce}
        setOpen={setMessageModalSalesforce}
        message="Are you sure that you want to push these records to Salesforce?"
        handleSubmit={pushToSalesForce}
      />
      <MessageModal
        open={messageModal}
        setOpen={setMessageModal}
        message="Are you sure that you want to archive this record?"
        handleSubmit={handleArchiveRecords}
      />
      <MessageModal
        open={messageModalTrack}
        setOpen={setMessageModalTrack}
        message="Are you sure that you want to add this records to tracking?"
        handleSubmit={handleTrackRecords}
      />
    </div>
  );
};
export default ProspectDetails;