import React, { useEffect, useRef, useState } from "react";
import "./Style.css";
import Layout from "../../../Components/Layout/Layout";
import CompanyProfile from "../../../Components/AiLeads/CompanyProfile/Index";
import CompanyProfileTabs from "../../../Components/AiLeads/CompanyProfileTabs/Index";
import { useLocation, useParams } from "react-router-dom/dist";
import axios from "axios";
import Loader from "../../../Components/Loader/Loader";
import { APIUrlOne, APIUrlThree, APIUrlToken } from "../../../Utils/Utils";
import PDFdatafile from "../PDFDATA/PDFdatafile";
import PDFTabs from "../PDFDATA/PDFTabs";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import html2pdf from 'html2pdf.js';
import CombinedPDFComponent from "../PDFDATA/CombinedData";
import LeadsProspectModal from "../../../Components/RequestUpdateModals/LeadsProspectModal";
import { useReactToPrint } from "react-to-print";
const AILeads = () => {
  const [isSalesForceTrigger, setIsSalesForceTrigger] = React.useState(false);
  const [isDecisionMakerExcel, setIsDecisionMakerExcel] = React.useState(false);
  const [archiveRecord, setArchiveRecord] = useState(false)
  const [trackRecord, setTrackRecord] = useState(false)
  const [requestUpdateRecord, setRequestUpdateRecord] = useState(false)
  const [disableRequestUpdate, setDisableRequestUpdate] = useState(false);
  const [leadsRequestModal, setLeadsRequestModal] = useState(false)
  const [shareRecord, setShareRecord] = useState(false)
  const [showSalesForce, setShowSalesForce] = useState(false)
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isLoadInfoData, setIsLoadInfoData] = useState(false);
  const { id,tab } = useParams();
  const newdata = id;
  const [value, setValue] = React.useState(Number(tab));

  const [dataForInformation, setDataForInformation] = useState("");
  const informationData = () => {
    setLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "X-API-Key": APIUrlToken()
      },
      url: `${APIUrlThree()}/v1/get_org_description?org_id=${newdata}`,
    };
    axios(option)
      .then((e) => {
        setLoading(false);
        if (e?.status === 200) {
          setDataForInformation(e?.data?.data[0]);
        }
      })
      .catch(() => {
        // setLoading(false);
      });
  };
  useEffect(() => {
    if (newdata && isLoadInfoData) {
      informationData();
    } else {
      setIsLoadInfoData(true);
    }
  }, [newdata, isLoadInfoData]);

  const ref = useRef(null);
  const [generatePdf, setGeneratePdf] = useState(false);
  const contentRef = useRef(null);
  const handleToPDF = () => {
    setGeneratePdf(true)
    setTimeout(() => {

      setTimeout(() => {
        setGeneratePdf(false);
      }, 1000);
    }, 2000);
    const content = contentRef.current;
    const options = {
      filename: 'my-document.pdf',
      margin: 1,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        margin: Margin.MEDIUM,
        format: 'letter',
        orientation: 'landscape',
      },
    };
    html2pdf().set(options).from(content).save();
  };
  const crieRef=useRef()

  const handleToPDFCrie = () => {
    const content = crieRef.current;
    const options = {
      filename: 'my-document.pdf',
      margin: 1,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        margin: Margin.MEDIUM,
        format: 'letter',
        orientation: 'landscape',
      },
    };
    html2pdf().set(options).from(content).save();
  };
  const handlePrint = useReactToPrint({
    content: () => crieRef.current,
    pageStyle: `
      @page {
        size: landscape;
      }
      body {
        -webkit-print-color-adjust: exact; /* Ensure colors print correctly */
      }
    `,
  });
  
  React.useEffect(() => {
    if (shareRecord === true) {
      const subject = encodeURIComponent(`${dataForInformation?.name ? dataForInformation?.name : "-"} profile`);
      const body = encodeURIComponent(`Please find the attached link.`) + '%0D%0A' + encodeURIComponent(`${window.location.href}`);
      const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${subject}&body=${body}`;
      setShareRecord(false)
      window.open(gmailLink, '_blank');
    }
  }, [shareRecord])
  return (
    <>
      {loading ? <Loader /> : null}
      <Layout>
        <div style={{ pageBreakInside: "avoid" }} ref={crieRef}>
          <div className="child-section-of-everypage-CompanyProfileScreen" >
            <CompanyProfile
            value={value}
              setShareRecord={setShareRecord}
              dataForInformation={dataForInformation}
              handleToPDF={handleToPDF}
              setIsDecisionMakerExcel={setIsDecisionMakerExcel}
              isSalesForceTrigger={isSalesForceTrigger}
              isDecisionMakerExcel={isDecisionMakerExcel}
              rowData={newdata}
              setIsSalesForceTrigger={setIsSalesForceTrigger}
              setArchiveRecord={setArchiveRecord}
              setTrackRecord={setTrackRecord}
              setRequestUpdateRecord={setRequestUpdateRecord}
              showSalesForce={showSalesForce}
              disableRequestUpdate={disableRequestUpdate} />
          </div>
          <div className="child-section-CompanyProfileTabs">
            <CompanyProfileTabs
            value={value} setValue={setValue}
              handleToPDF={handleToPDF}
              dataForInformation={dataForInformation}
              rowData={newdata}
              setIsDecisionMakerExcel={setIsDecisionMakerExcel}
              isDecisionMakerExcel={isDecisionMakerExcel}
              isSalesForceTrigger={isSalesForceTrigger}
              setIsSalesForceTrigger={setIsSalesForceTrigger}
              setArchiveRecord={setArchiveRecord}
              archiveRecord={archiveRecord}
              trackRecord={trackRecord}
              setTrackRecord={setTrackRecord}
              requestUpdateRecord={requestUpdateRecord}
              setRequestUpdateRecord={setRequestUpdateRecord}
              setDisableRequestUpdate={setDisableRequestUpdate}
              setLeadsRequestModal={setLeadsRequestModal}
              handleToPDFCrie={handleToPDFCrie}
              handlePrint={handlePrint}
              setShowSalesForce={setShowSalesForce}
            />
          </div>
        </div>
        <div style={{ display: generatePdf ? 'block' : 'none' }}>
          <div id="content-id" ref={contentRef} style={{ fontSize: '5px' }}>
            <CombinedPDFComponent style={{ width: "100%" }} dataForInformation={dataForInformation} rowData={newdata} />
          </div>
        </div>
        <LeadsProspectModal requestUpdateRecord={leadsRequestModal} setRequestUpdateRecord={setLeadsRequestModal} profileData={dataForInformation} peopleId={newdata} />
      </Layout>
    </>
  );
};
export default AILeads;