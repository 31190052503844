import { Box, Button, Modal } from '@mui/material';
import React, { useRef, useState } from 'react';
import upload from "../.././../Assets/upload.svg";
import { APIUrlThree, APIUrlToken, GetUserId } from '../../../Utils/Utils';
import axios from 'axios';
import DataEnrichProspectsModal from './DataEnrichProspectsModal';
import Loader from '../../Loader/Loader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const DataEnrichUploadModal = ({ modalOpen, handleClose, setUploadModal,getEnrichList }) => {
    const [loading, setLoading] = React.useState(false);
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "8px",
    };
    const [filepath,setFilepath]=useState();
    const [file, setFile] = React.useState({
        filename: "",
        column_names: "",
        request_id: "",
    });
    
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);
    const [isProspectsModalOpen, setIsProspectsModalOpen] = useState(false);
    
    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setFile(e.dataTransfer.files[0]);
        }
    };
    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    };
    const handleAreaClick = () => {
        inputRef.current.click();
    };
    const validateUpload = () => {
        if (!file?.name) {
            toast.error("Please select a CSV file");
            return false;
        }
        return true;
    };
    const handleUploadCsv = () => {
        if (!validateUpload()) return;
        setLoading(true)
        const formData = new FormData();
        formData.append('file', file);
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "X-API-Key": APIUrlToken()
            },
            // url: `${APIUrlThree()}/v1/data_enrich_upload?category=prospects&user_id=${Number(GetUserId())}`,
            url: `${APIUrlThree()}/enrichment-upload-csv/?category=prospects&user_id=${Number(GetUserId())}`,
            data: formData,
        };
        axios(option)
            .then((e) => {
                if (e.status === 200) {
                    const { filename, column_names, request_id } = e.data;
                    setFile({
                        filename: filename,
                        column_names: column_names,
                        request_id: request_id,
                    });
                    setFilepath(e.data.file_path)
                    setIsProspectsModalOpen(true);
                }
                setLoading(false)
                handleClose(false);
                // toast.success(e?.data?.message)
            })
            .catch((err) => {
                console.error(err);
                setLoading(false)
            });
    };
    const handleCloseProspect = () => {
        setUploadModal(false);
        setFile({});
    }
    return (
        <>
            {loading ? <Loader /> : null}
            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="vrufyerv">
                    <Box sx={style}>
                        <div className='de_overflow_cont'>
                            <div className="upload-wrapper de_wrapper">
                                <h3>Upload CSV to Enrich Your List</h3>
                                <div
                                    className={`de_upload-area ${dragActive ? "de_active" : "de_inactive"}`}
                                    onDragEnter={handleDragEnter}
                                    onDragLeave={handleDragLeave}
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                    onClick={handleAreaClick}
                                >
                                    <input
                                        ref={inputRef}
                                        type="file"
                                        accept=".csv"
                                        onChange={handleFileChange}
                                        className="de_fileInput"
                                    />
                                    {file?.name ? (
                                        <p className='de_file_name'>{file.name}</p>
                                    ) : (
                                        <p className='de_file_name'><img src={upload} alt="upload" />Drag and Drop</p>
                                    )}
                                </div>
                                <p className='de_drag_content'>Drag and drop a .CSV file here or browse for a file to upload. Only <b>one .CSV</b> file is allowed at a time.</p>
                                <div className='de_btn_cont'>
                                    <Button className='de_cancel' onClick={handleCloseProspect}>Cancel</Button>
                                    <Button className='de_next' onClick={handleUploadCsv}>Enrich My List</Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </Modal>
            <DataEnrichProspectsModal
                setUploadModal={setUploadModal}
                setFile={setFile}
                file={file}
                modalOpen={isProspectsModalOpen}
                handleClose={() => setIsProspectsModalOpen(false)}
                setIsProspectsModalOpen={setIsProspectsModalOpen}
                filepath={filepath}
                getEnrichList={getEnrichList}
                
            />
        </>
    );
};
export default DataEnrichUploadModal;